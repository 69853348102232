import React, { useEffect, useState } from 'react'
import { SlCloudUpload } from 'react-icons/sl'
import { addRebrandData, onDeleteRebrand } from '../../Redux/Actions/RebrandingAction';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import CommonAlert from '../Common/CommonAlert';
import { FaSpinner } from 'react-icons/fa';

const Rebranding = () => {

    const dispatch = useDispatch();
    const rebrandData = useSelector(state => state.rebrand);

    const [rebrand, setRebrand] = useState({
        name: "",
        img: "",
        id: false,
        isRebrand: false
    });
    const [uploadImg, setUploadImg] = useState(false)
    const [loader, setLoader] = useState(false);
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Remove",
        loader: false
    })

    const onConfirm = () => {
        let data = { id: sweet.id }
        setSweet({
            ...sweet,
            confirmButtonName: "Removing",
            loader: true
        })
        dispatch(onDeleteRebrand(data, setSweet, setRebrand))
    }

    const onInputFile = (e) => {
        if (e.target.files.length > 0) {
            let fileData = e.target.files[0]
            if (fileData.type === 'image/jpg' || fileData.type === 'image/png' || fileData.type === 'image/jpeg' || fileData === 'image/JPEG') {
                if (fileData.size < 5000000) {
                    setRebrand({
                        ...rebrand,
                        img: fileData
                    })
                    setUploadImg(URL.createObjectURL(fileData))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max uploaded size for image is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        let formData = new FormData();
        formData.append('name', rebrand.name);
        formData.append('logo', rebrand.img);
        dispatch(addRebrandData(formData, setLoader));
    }

    const deleteRebrand = () => {
        setSweet({
            ...sweet,
            enable: true,
            id: rebrand.id
        })
    }


    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Remove",
            loader: false
        })
    }

    useEffect(() => {
        if (rebrandData.data) {
            const { name, id, logo } = rebrandData.data
            setRebrand({
                ...rebrand,
                name: name,
                id: id,
                img: logo,
                isRebrand: true
            });
        }
    }, [rebrandData])


    return (
        <div className="upgrade-tab-in">
            <form className="upgrade-tab-block" onSubmit={onFormSubmit}>
                <h6>Rebranding</h6>
                <div className="simple-input">
                    <div className="upload-box" style={{ height: "11.5rem" }}>
                        <div className="upload-box-icon"><SlCloudUpload /></div>
                        <p>Upload your brand logo (JPG, PNG, JPEG upto 5MB is allowed) <br /> (210x40) Pixels recommended</p>
                        <input type="file" onChange={(e) => onInputFile(e)} />
                        {rebrand.img ?
                            <img src={uploadImg ? uploadImg : rebrand.img} alt='' style={{ height: 40, marginTop: 10, width: 210 }} /> : null}
                    </div>
                </div>
                <div className="simple-input">
                    <label className="label" htmlFor="">Brand Name</label>
                    <input
                        className="input"
                        type="text"
                        placeholder="Enter your brand name"
                        value={rebrand.name}
                        required
                        onChange={(e) => setRebrand({
                            ...rebrand,
                            name: e.target.value
                        })}
                        readOnly={rebrand.isRebrand}
                    />
                </div>
                <div className="simple-input">
                    {rebrand.isRebrand === false ?
                        <button
                            type="submit"
                            className="theme-btn me-1 mb-1"
                            disabled={loader}><span>{loader ? <> Saving <FaSpinner className='spin' />  </> : 'Save'}</span></button>
                        :
                        <button
                            type="button"
                            onClick={deleteRebrand}
                            className="theme-btn"
                        >
                            <span>
                                Remove Rebranding
                            </span>
                        </button>
                    }
                    {/* <button className=""><span>Save</span></button> */}
                </div>
            </form>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to remove this rebranding?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
                alertLoader={sweet.loader}

            />
        </div>
    )
}

export default Rebranding