import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

import './App.scss';
import { ThemeProvider } from "./Components/Theme-Context/ThemeContext";
import Login from "./Components/Auth/Login";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import MyAccount from "./Components/My-Account/MyAccount";
import Privacy from "./Components/Privacy/Privacy";
import AccountManagement from "./Components/Account-Management/AccountManagement";
import Upgrade from "./Components/Upgrade/Upgrade";
import Help from "./Components/Help/Help";
import HelpDetails from "./Components/Help/HelpDetails";
import CallHistory from "./Components/Call-History/CallHistory";
import PhoneNumber from "./Components/Phone-Number/PhoneNumber";
import ApiKeys from "./Components/Api-Keys/ApiKeys";
import Integrations from "./Components/Integrations/Integrations";
import Campaigns from "./Components/Campaigns/Campaigns";
import CampaignDetails from "./Components/Campaigns/CampaignDetails";
import Alert from "./Components/Common/Alert";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeAlert } from "./Redux/Actions/AlertActions";
import PrivateRoute from "./Components/Common/PrivateRoute";
import SupportArticle from "./Components/Help/SupportArticle";
import ResetPassword from "./Components/Auth/ResetPassword";
import MasterLogin from "./Components/Auth/MasterLogin";
import Agent from "./Components/Agent/CreateAgent/Agent";
import UpdateAgent from "./Components/Agent/UpdateAgent/UpdateAgent";
import Dashboard from "./Components/Dashboard/Dashboard";
import Webhook from "./Components/Web-Hook/Webhook";
import Subscriptions from "./Components/Subscriptions/Subscriptions";
import NumberList from "./Components/Phone-Number/NumberList";
import { loadUser } from "./Redux/Actions/AuthActions";
import { onFetchFont } from "./Redux/Actions/CommonActions";
import Settings from "./Components/Settings/Settings";


function App() {
  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)

  const fetchUser = () => {
    dispatch(loadUser())
  }


  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])

  
  const fetchFonts = () => {
    dispatch(onFetchFont(false, true))
  }

  useEffect(() => {
    fetchUser()
    fetchFonts()
  }, [])




  return (
    <ThemeProvider>
      <Alert />
      <div className="App">
        <Router>
          {/* <ScrollTop/> */}
          <Routes>

            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/master-login" element={<MasterLogin />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route exact path="/my-account" element={<PrivateRoute><MyAccount /> </PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivateRoute><Privacy /> </PrivateRoute>} />
            <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /> </PrivateRoute>} />
            <Route exact path="/upgrade" element={<PrivateRoute><Upgrade /> </PrivateRoute>} />
            <Route exact path="/settings" element={<PrivateRoute><Settings /> </PrivateRoute>} />
            <Route exact path="/help" element={<PrivateRoute><Help /> </PrivateRoute>} />
            <Route exact path="/help-details" element={<PrivateRoute><HelpDetails /> </PrivateRoute>} />
            <Route exact path="/support-article" element={<PrivateRoute><SupportArticle /> </PrivateRoute>} />

            <Route exact path="/agent" element={<PrivateRoute><Agent /> </PrivateRoute>} />
            <Route exact path="/agent/customize-agent" element={<PrivateRoute><UpdateAgent /> </PrivateRoute>} />
            <Route exact path="/agent/campaign-details" element={<PrivateRoute><CampaignDetails /> </PrivateRoute>} />


            <Route exact path="/call-history" element={<PrivateRoute><CallHistory /> </PrivateRoute>} />
            <Route exact path="/phone-number" element={<PrivateRoute><PhoneNumber /> </PrivateRoute>} />
            <Route exact path="/phone-number/phone-number-list" element={<PrivateRoute><NumberList /> </PrivateRoute>} />
            <Route exact path="/api-keys" element={<PrivateRoute><ApiKeys /> </PrivateRoute>} />
            <Route exact path="/webhook" element={<PrivateRoute><Webhook /> </PrivateRoute>} />

            <Route exact path="/integrations" element={<PrivateRoute><Integrations /> </PrivateRoute>} />

            <Route exact path="/campaigns" element={<PrivateRoute><Campaigns /> </PrivateRoute>} />
            <Route exact path="/campaigns/campaign-details" element={<PrivateRoute><CampaignDetails /> </PrivateRoute>} />

            <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /> </PrivateRoute>} />
            <Route exact path="/subscriptions" element={<PrivateRoute><Subscriptions /> </PrivateRoute>} />

          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
