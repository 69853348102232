import React from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";

import { ScrollPanel } from 'primereact/scrollpanel';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import MainMenu from "../Common/MainMenu";

function Subscriptions() {
    return (
        <>
            <MainMenu>
                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">

                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                            <div className="subscription-banner">
                                <h2>Choose Your Plan</h2>
                                <p className="pt-2">Simple pricing. No hidden fees. Advanced features for you business.</p>
                                <div className="nav-outer">
                                    <Nav variant="v" className="">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Monthly</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Yearly</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>

                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="package-wrap">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="package-single">
                                                    <h5>Standard version</h5>
                                                    <h2 className="pt-5">$9.9 <span>/month</span></h2>
                                                    <ul className="pt-4">
                                                        <li>Create 1 Voice Agent</li>
                                                        <li>360 minutes every month</li>
                                                        <li>6 Basic Voices</li>
                                                        <li>Inbound & Outbound Agents</li>
                                                        <li>Automated  scheduling</li>
                                                        <li>Automated SMS Sending</li>
                                                    </ul>
                                                    <button className="theme-btn full mt-5">Choose Plan</button>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="package-single dark">
                                                    <h5>PRO TEAM version</h5>
                                                    <h2 className="pt-5">$19.9 <span>/month</span></h2>
                                                    <ul className="pt-4">
                                                        <li>Unlimited Voice Agents</li>
                                                        <li>1600 minutes every month</li>
                                                        <li>6 Neural Voices</li>
                                                        <li>Call Transcription</li>
                                                        <li>Automated SMS Sending</li>
                                                        <li>Superior Noise Reduction</li>
                                                    </ul>
                                                    <button className="theme-btn full mt-5">Choose Plan</button>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="package-single darker">
                                                    <h5>PRO ENTERPRISE version</h5>
                                                    <h2 className="pt-5">$29.9 <span>/month</span></h2>
                                                    <ul className="pt-4">
                                                        <li>Unlimited Voice Agents</li>
                                                        <li>1600 minutes every month</li>
                                                        <li>6 Neural Voices</li>
                                                        <li>Call Transcription</li>
                                                        <li>Automated SMS Sending</li>
                                                        <li>Superior Noise Reduction</li>
                                                    </ul>
                                                    <button className="theme-btn full mt-5">Choose Plan</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="package-wrap">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="package-single">
                                                    <h5>Standard version</h5>
                                                    <h2 className="pt-5">$9.9 <span>/month</span></h2>
                                                    <ul className="pt-4">
                                                        <li>Create 1 Voice Agent</li>
                                                        <li>360 minutes every month</li>
                                                        <li>6 Basic Voices</li>
                                                        <li>Inbound & Outbound Agents</li>
                                                        <li>Automated  scheduling</li>
                                                        <li>Automated SMS Sending</li>
                                                    </ul>
                                                    <button className="theme-btn full mt-5">Choose Plan</button>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="package-single dark">
                                                    <h5>PRO TEAM version</h5>
                                                    <h2 className="pt-5">$19.9 <span>/month</span></h2>
                                                    <ul className="pt-4">
                                                        <li>Unlimited Voice Agents</li>
                                                        <li>1600 minutes every month</li>
                                                        <li>6 Neural Voices</li>
                                                        <li>Call Transcription</li>
                                                        <li>Automated SMS Sending</li>
                                                        <li>Superior Noise Reduction</li>
                                                    </ul>
                                                    <button className="theme-btn full mt-5">Choose Plan</button>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="package-single darker">
                                                    <h5>PRO ENTERPRISE version</h5>
                                                    <h2 className="pt-5">$29.9 <span>/month</span></h2>
                                                    <ul className="pt-4">
                                                        <li>Unlimited Voice Agents</li>
                                                        <li>1600 minutes every month</li>
                                                        <li>6 Neural Voices</li>
                                                        <li>Call Transcription</li>
                                                        <li>Automated SMS Sending</li>
                                                        <li>Superior Noise Reduction</li>
                                                    </ul>
                                                    <button className="theme-btn full mt-5">Choose Plan</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>

                    </div>
                </ScrollPanel>
            </MainMenu>
        </>
    )
}

export default Subscriptions;