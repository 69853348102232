import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ConnectionRows from '../ConnectionRows'
import ConnectModal from '../ConnectModal'

const ElevenLabs = () => {
    const elevenLabs = useSelector(state => state.social.elevenLabs)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    return (
        <div className="col-lg-12">
            <div className="integration-box">
                <div className="integration-box-icon"><img src={require("../../../images/icon/labs.svg").default} alt="" /></div>
                <div className="integration-box-txt">
                    <h6>ElevenLabs</h6>
                    <p>Bring your ElevenLabs to customise the voices of your assistants</p>
                </div>
                <div className="integration-btn">
                    <button className="theme-btn light" onClick={handleShow}>Connect</button>
                </div>
            </div>
            <div className='row'>
                {elevenLabs.length > 0 ?
                    elevenLabs.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ConnectionRows
                                    curElem={curElem}
                                />
                            </React.Fragment>
                        )
                    })
                    : ""}
            </div>

            <ConnectModal
                show={show}
                handleClose={handleClose}
                type="ElevenLabs"
                url="create-elevenlabs"
            />
        </div>


    )
}

export default ElevenLabs