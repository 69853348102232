import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../Common/Footer";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../../Redux/Actions/AuthActions";
import TitleBar from "../Common/TitleBar";
import { FaSpinner } from "react-icons/fa";
import { appName } from "../../Global/Global";
import logoD from "../../images/logo-dark.svg"
import logoL from "../../images/logo-light.png"
import AuthLogoHeader from "./AuthLogoHeader";
const ForgotPassword = () => {

  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [email, setEmail] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      email: email
    }
    setLoader(true)
    dispatch(forgetPassword(data, setLoader))
  }
  return (
    <>
      <TitleBar title="Forgot Password" />

      <AuthLogoHeader />

      <div className="login-wrap">
        <div className="login-left">
        <div className="login-left-in">
          <h2 className="pb-3">Welcome To {appName}</h2>
          <p>Transform your business with AI-driven callers that enhance your productivity and sales. Dive into the future of customer interactions with {appName} - where technology meets seamless communication</p>
          </div>
        </div>
        <div className="login-right">
          <form className="login-right-main" onSubmit={handleSubmit}>
            <h3>Enter Your Email Address</h3>
            <div className="login-input-wrap">
              <label htmlFor="">Email Address</label>
              <input
                className="login-input"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="login-input-wrap">
              <button className="login-btn">{loader ? <>Submitting <FaSpinner className="spin ms-1" /></> : "Submit"}</button>
            </div>
            <div className="login-input-wrap text-center">
              <p>Remember? <Link to="/">Sign in</Link></p>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default ForgotPassword;