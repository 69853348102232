import React, { useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { IoPause, IoPlay } from 'react-icons/io5'
import { Link } from 'react-router-dom'

let music = false
const OverviewRows = ({ curElem }) => {
    const [play, setPlay] = useState(false)
    const [loader, setLoader] = useState({
        playRecording: false
    })
    const handlePlay = () => {
        if (curElem.recording) {
            if (music) {
                handlePause()
            } else {
                setLoader({
                    ...loader,
                    playRecording: true
                })
                music = new Audio(curElem.recording)
                music.play()
                    .then(r => {
                        setLoader({
                            ...loader,
                            playRecording: false
                        })
                        setPlay(true)
                    }
                    )
                    .catch(err => console.log(err));

                music.onended = () => {
                    setPlay(false)
                }
            }

        }
    }

    const handlePause = () => {
        if (music) {
            music.pause()
            music = false
            setPlay(false)
        }
    }

    useEffect(() => {
        return () => {
            handlePause()
        }
    }, [])
    return (
        <tr>
            <td>
                <span className="custom-check">
                    <input type="checkbox" />
                    <span className="checkMark"></span>
                </span>
            </td>
            <td>#{curElem.id}</td>
            <td>{curElem.name}</td>
            <td>{curElem.phone}</td>
            <td>
                <span className={`tStatus ${+curElem.status === 2 ? "complete" :  +curElem.status === 4 ? "progress" : "paused"}`}>
                    {+curElem.status === 2 ? "Completed" : +curElem.status === 4 ? "In Process" : " Pending"}
                </span>
            </td>
            <td>{curElem.created}</td>
            <td>{curElem.duration}</td>
            <td>
                {play ?
                    <IoPause onClick={handlePause} className='cursor-pointer' />
                    :
                    loader.playRecording ?
                        <FaSpinner className='spin' />
                        :
                        curElem.recording ?
                            <IoPlay onClick={handlePlay} className='cursor-pointer' /> : "N/A"
                }
                {/* 0:00 / 0:42 */}

            </td>
            <td>
                {+curElem.conversationId ?
                    <Link to={`/call-history?cId=${+curElem.conversationId}`}>Read Transcript</Link> : "N/A"}

            </td>
        </tr>
    )
}

export default OverviewRows