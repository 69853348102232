import React, { useEffect, useState } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import Dropdown from 'react-bootstrap/Dropdown';
import { IoSearchOutline } from "react-icons/io5";
import CreateCampaignModal from "../Agent/UpdateAgent/LaunchCampaign/CreateCampaignModal";
import { fetchData } from "../../Redux/Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import CampaignRows from "../Agent/UpdateAgent/LaunchCampaign/CampaignRows";
import { useImmer } from "use-immer";
import { FaSpinner } from "react-icons/fa";
import Pagination from "../Common/Pagination";
import MainMenu from "../Common/MainMenu";
import TitleBar from "../Common/TitleBar";

const Campaigns = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [filterObj, setFilterObj] = useImmer({
        searchKey: "",
        agentType: [],
        agentCategory: [],
        campType: ""
    })

    const [col, setCol] = useState({
        first: true,
        second: true,
        third: true,
        forth: true,
    })


    const [checkedArr, setCheckedArr] = useState([])
    const [campShow, setCampShow] = useState([])


    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 15
    });
    const [sortHeader, setSortHeader] = useState({
        startDate: ""
    })

    const [campaigns, setCampaigns] = useState([])
    const [agents, setAgents] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = campShow.slice(indexofFirstTodo, indexofLastTodo);

    const handleSort = () => {
        const data = [...campaigns]
        if (sortHeader.startDate) {
            data.sort((a, b) => {
                if (a.startDate.toLowerCase() > b.startDate.toLowerCase()) {
                    return 1
                } else {
                    return -1
                }
            })
            setSortHeader({
                ...sortHeader,
                startDate: false,
            });
        } else {
            data.sort((a, b) => {
                if (a.startDate.toLowerCase() < b.startDate.toLowerCase()) {
                    return 1
                } else {
                    return -1
                }
            })
            setSortHeader({
                ...sortHeader,
                startDate: true,
            });
        }
        setCampaigns(data)
    }

    const handleFilterColumn = (e, checkType) => {
        let { name, value, checked } = e.target
        if (checkType) {
            value = checked
        }
        setCol({
            ...col,
            [name]: value
        })
    }



    const handleFilterCampType = (type) => {
        setFilterObj({
            ...filterObj,
            campType: type
        })
    }

    const fetchCampaigns = () => {
        let data = {}
        dispatch(fetchData("list-campaign", data, setCampaigns, loader, setLoader))
    }


    const fetchAgents = () => {
        let data = {}
        dispatch(fetchData("list-agent", data, setAgents, false, false, false, "shortDataLg"))
    }

    useEffect(() => {
        if (filterObj.searchKey === "" && filterObj.agentType.length === 0 && filterObj.agentCategory.length === 0 && filterObj.campType === "") {
            setCampShow(campaigns)
        }
        else {
            let dataArr = campaigns.filter((item1) => {
                return item1.name.toLowerCase().includes(filterObj.searchKey.toLowerCase())
            }).filter((curElem) => {
                if (filterObj.agentType.length === 0) {
                    return curElem
                } else {
                    return filterObj.agentType.includes(curElem.agentType.toLowerCase(), 0)
                }
            }).filter((curElem) => {
                if (filterObj.agentCategory.length === 0) {
                    return curElem
                } else {
                    return filterObj.agentCategory.includes(curElem.agentCategory.toLowerCase(), 0)
                }
            }).filter((curElem) => {
                return curElem.campaignType.toLowerCase().includes(filterObj.campType)
            })

            setPagination({
                ...pagination,
                currentPage: 1,
                totalItemOnPage: 15
            })
            setCampShow(dataArr)
        }

    }, [filterObj, campaigns])

    useEffect(() => {
        fetchCampaigns()
        fetchAgents()
    }, [])

    return (
        <>
            <TitleBar title="Campaigns" />
            <MainMenu>

                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">
                        <div className="campaign-head">
                            <div className="campaign-head-left">
                                <h6>Campaign List</h6>
                                {/* <div className="campaign-select">
                                    <ul>
                                        <li className={filterObj.campType === "" ? "active" : ""} onClick={() => handleFilterCampType("")}>All</li>
                                        <li className={filterObj.campType === "outbound" ? "active" : ""} onClick={() => handleFilterCampType("outbound")}>Outbound</li>
                                        <li className={filterObj.campType === "inbound" ? "active" : ""} onClick={() => handleFilterCampType("inbound")}>Inbound</li>
                                    </ul>
                                </div> */}
                            </div>
                            <div className="campaign-head-right">
                                {+auth.user.isClientAccount === 1 ? "" :
                                    <button className="theme-btn" onClick={handleShow}><span>New Campaign</span></button>}
                            </div>
                        </div>


                        <div className="table-wrap mt-3">

                            <div className="table-bar">
                                <div className="table-bar-left">
                                    <div className="table-bar-icon">
                                        <div className="table-nav">
                                            <div className="profile-menu">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="successN" className="drop-btn">
                                                        <img src={require("../../images/icon/plus.svg").default} alt="" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <li className="drop-list">
                                                            <span className="custom-check">
                                                                <input
                                                                    type="checkbox"
                                                                    name="first"
                                                                    checked={col.first}
                                                                    onChange={(e) => handleFilterColumn(e, "checked")}
                                                                />
                                                                <span className="checkMark"></span>
                                                            </span>
                                                            Campaign ID
                                                        </li>
                                                        <li className="drop-list">
                                                            <span className="custom-check">
                                                                <input
                                                                    type="checkbox"
                                                                    name="second"
                                                                    checked={col.second}
                                                                    onChange={(e) => handleFilterColumn(e, "checked")}
                                                                />
                                                                <span className="checkMark"></span>
                                                            </span>
                                                            Campaign Name
                                                        </li>
                                                        <li className="drop-list">
                                                            <span className="custom-check">
                                                                <input
                                                                    type="checkbox"
                                                                    name="third"
                                                                    checked={col.third}
                                                                    onChange={(e) => handleFilterColumn(e, "checked")}
                                                                />
                                                                <span className="checkMark"></span>
                                                            </span>
                                                            Contact List
                                                        </li>
                                                        <li className="drop-list">
                                                            <span className="custom-check">
                                                                <input
                                                                    type="checkbox"
                                                                    name="forth"
                                                                    checked={col.forth}
                                                                    onChange={(e) => handleFilterColumn(e, "checked")}
                                                                />
                                                                <span className="checkMark"></span>
                                                            </span>
                                                            AI Agent
                                                        </li>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div className="profile-menu">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="successN" className="drop-btn">
                                                        <img src={require("../../images/icon/filter.svg").default} alt="" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <li className="drop-list">
                                                            <span className="custom-check">
                                                                <input type="checkbox" />
                                                                <span className="checkMark"></span>
                                                            </span>
                                                            Agent Type
                                                        </li>
                                                        <li className="drop-list">
                                                            <span className="custom-check">
                                                                <input type="checkbox" />
                                                                <span className="checkMark"></span>
                                                            </span>
                                                            Agent Category
                                                        </li>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <ul>
                                            <li onClick={handleSort} title="Sort by start date">
                                                <img src={require("../../images/icon/sort.svg").default} alt="" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="table-bar-right">
                                    <div className="header-search">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            onChange={(e) => setFilterObj({ ...filterObj, searchKey: e.target.value })}
                                        />
                                        <span className="left-icon"><IoSearchOutline /></span>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive mt-3">
                                <table className="table theme-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span className="custom-check">
                                                    <input type="checkbox" />
                                                    <span className="checkMark"></span>
                                                </span>
                                            </th>
                                            {col.first ?
                                                <th>Campaign ID</th> : ""}
                                            {col.second ?

                                                <th>Campaign Name</th> : ""}
                                            {/* <th>Campaign Type</th> */}
                                            {col.third ?

                                                <th>Contact List</th> : ""}
                                            {col.forth ?
                                                <th>AI Agent </th> : ""}
                                            {/* <th>Duration</th> */}
                                            <th>Status</th>
                                            <th>Start Date</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentTodo.length > 0 ?
                                            currentTodo.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <CampaignRows
                                                            curElem={curElem}
                                                            campaigns={campaigns}
                                                            setCampaigns={setCampaigns}
                                                            fetchCampaigns={fetchCampaigns}
                                                            checkedArr={checkedArr}
                                                            setCheckedArr={setCheckedArr}
                                                            agents={agents}
                                                            col={col}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            campaigns.length === 0 ?
                                                <tr>
                                                    <td colSpan={8} className="text-center">
                                                        {loader.fetch ? <FaSpinner className="spin" size={25} /> :
                                                            "You have no campaign created yet."
                                                        }
                                                    </td>
                                                </tr> : ""
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <Pagination
                                listData={campShow}
                                pagination={pagination}
                                setPagination={setPagination}
                                currentTodo={currentTodo}
                                listArr={campaigns}
                                loader={loader.fetch}
                            />

                        </div>
                    </div>
                </ScrollPanel>

            </MainMenu >

            <CreateCampaignModal
                show={show}
                handleClose={handleClose}
                fetchCampaigns={fetchCampaigns}
                agents={agents}
            />
        </>
    )
}

export default Campaigns;