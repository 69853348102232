import React, { useEffect, useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";

import { ScrollPanel } from 'primereact/scrollpanel';
import { useDispatch, useSelector } from "react-redux";
import TitleBar from "../Common/TitleBar";
import { onGenerateKey } from "../../Redux/Actions/AuthActions";
import { FaSpinner } from "react-icons/fa"
import MainMenu from "../Common/MainMenu";
const ApiKeys = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const defaultKey = "*********************************************************"
    const [loader, setLoader] = useState({
        create: false
    })


    const handleCreateKey = (genType) => {
        let data = { type: genType }
        setLoader({
            ...loader,
            create: true
        })
        dispatch(onGenerateKey(data, loader, setLoader))
    }

    const handleCopy = () => {
        let copyText = document.getElementById("myInput");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    }

    return (
        <>
            {/* <TitleBar title="API Key" />
            <MainMenu> */}
                {/* <ScrollPanel className="custombar2 content-area"> */}
                    <div className="content-area-in">
                        <div className="account-block">
                            <h6>API Keys</h6>

                            <div className="input-wrap pos-relative">
                                <label className="label" htmlFor="">Credentials</label>
                                <input
                                    className="input"
                                    id="myInput"
                                    type="text"
                                    placeholder="Create your api key"
                                    value={show ? auth.user.apiKey : auth.user.apiKey ? defaultKey : ""}
                                    readOnly
                                />
                                <div className="input-wrap-right">
                                    {auth.user.apiKey ?
                                        <>
                                            <button className="theme-btn light" onClick={() => handleCreateKey("update")}>{loader.create ? <>Regenerating <FaSpinner className="spin" size={13} /></> : "Regenerate"}</button>
                                            <button className="theme-btn light" onClick={() => setShow(!show)}>{show ? "Hide" : "View"}</button>
                                            {show ?
                                                <button className="theme-btn light" onClick={handleCopy}>Copy</button> : null}
                                        </>
                                        :
                                        <button className="theme-btn light" onClick={() => handleCreateKey("create")}>{loader.create ? <>Creating <FaSpinner className="spin" size={13} /></> : "Create"}</button>
                                    }
                                </div>
                            </div>

                            <div className="alert-box dark">
                                <div className="alert-icon"><img src={require("../../images/icon/shield-check.svg").default} alt="" /></div>
                                <div className="alert-txt">
                                    <h6>Secure Your Account</h6>
                                    <p>Keep your API key confidential; avoid revealing it in browsers or any client-side code. For the safeguarding of your account, we reserve the right to deactivate any API key that is detected to have been disclosed in a public setting.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                {/* </ScrollPanel> */}
            {/* </MainMenu> */}
        </>
    )
}

export default ApiKeys;