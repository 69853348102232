import React from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'

const FbGroupModal = ({ showPopup, showClose, setShowClose, handelClose2, handleMsgClick, handelPopupClick }) => {
    const colorObj = {
        color: "#ffffff",
        position: "absolute",
        top: 30,
        left: "-141px",
        fontSize: 10,
        background: "#515151",
        width: 150,
        textAlign: "center",
        padding: 5,
        borderRadius: 5,
        cursor: "pointer"
    }

    return (
        <Modal className="theme-modal" show={showPopup} onHide={handelClose2} centered>
            {/* <Modal className="theme-modal" show={showPopup} onHide={handelClose2} centered> */}
            <Modal.Body>
                <div onClick={() => setShowClose(true)} className="modal-close" style={{ top: -24, right: 0 }}>
                    <div style={{ position: "relative" }}>
                        {/* <img alt="" src={modalCloseIcon} /> */}
                        <AiOutlineClose />
                        {showClose ?
                            <p style={colorObj} onClick={handleMsgClick}>Don't show me again</p> : ""}

                    </div>

                </div>
                <div className="modalVidFb">
                    <div className="para_ai h-100">
                        <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick} style={{ display: "block" }} className="w-100 d-flex h-100 justify-content-center align-items-center flex-column">
                            <p style={{
                                fontSize: "55px",
                                fontWeight: "bold",
                                textAlign: "center",
                                lineHeight: "1.3",
                                color: "#fff"
                            }}>Click Here To Join  Members <br /> Only Facebook Group </p>
                            <button className="btn btn-danger mt-3 ps-4 pe-4 pt-2 pb-2" style={{ display: " block", fontSize: "45px" }}> Click Here</button>
                        </a>
                    </div>
                </div>

            </Modal.Body>
        </Modal>

    )
}

export default FbGroupModal