import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { BsInfoCircle } from 'react-icons/bs'
import { FaSpinner } from 'react-icons/fa'
import { TbBuildingBroadcastTowerFilled } from 'react-icons/tb'
import { useDispatch } from 'react-redux'
import { useImmer } from "use-immer"
import { onScrapWebsites } from '../../Redux/Actions/AgentActions'
import { appName } from '../../Global/Global'

const WebpageModal = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        save: false
    })
    const [terms, setTerms] = useState(false)
    const [urls, setUrls] = useImmer([
        {
            url: ""
        }
    ])

    const handleAdd = () => {
        if (urls.length <= 5) {
            setUrls((draft) => {
                let obj = {
                    url: ""
                }
                draft.push(obj)
            })
        }
    }

    const handleRemove = (index) => {
        setUrls((draft) => {
            draft.splice(index, 1)
        })
    }

    const handleChange = (e, index) => {
        setUrls((draft) => {
            draft[index].url = e.target.value
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            save: true
        })
        let data = {
            agentId: props.id,
            url: urls
        }
        dispatch(onScrapWebsites(data, props.fetchData, props.closeMain, props.handleClose, loader, setLoader))
    }

    useEffect(() => {
        if (props.show) {
            setUrls([{
                url: ""
            }])
        }
    }, [props.show])

    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={props.handleClose}><AiOutlineClose /></span>
                <form className="modal-content" onSubmit={handleSubmit}>
                    <div className="icon-title">
                        <span className="title-icon"><TbBuildingBroadcastTowerFilled /></span> 
                        <h6>Connect Website</h6>
                    </div>
                    <div className="alert-box">
                        <div className="alert-icon"><BsInfoCircle /></div>
                        <div className="alert-txt">
                            <p>Only public available knowledgebases will be imported. If your knowledgebase is not publicly available, please import as documents instead.</p>
                        </div>
                    </div>

                    <h6 className="pt-4">Webpage URL</h6>
                    <div className="simple-input">
                        <p className="small grey pb-2">For best results, only add webpages with relevant content to this specific widget</p>
                        {urls.length > 0 ?
                            urls.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div className='webpageInp mt-2' style={{ position: "relative" }}>
                                            <input
                                                className="input"
                                                type="url"
                                                value={curElem.url}
                                                onChange={(e) => handleChange(e, index)}
                                                required
                                            />
                                            {urls.length > 1 ?
                                                <span className='theme-icon'
                                                    // style={{ position: "absolute", top: 4, right: 3, cursor: "pointer" }}
                                                    title='Remove'
                                                    onClick={() => handleRemove(index)}
                                                >
                                                    <AiOutlineClose />
                                                </span> : null}
                                        </div>
                                    </React.Fragment>
                                )
                            })
                            : null}

                    </div>

                    <div className="add-more" onClick={handleAdd}>+ Add More</div>

                    <div className="mt-5">
                        <span className="custom-check">
                            <input
                                type="checkbox"
                                checked={terms}
                                onChange={(e) => setTerms(e.target.checked)}
                                required
                            />
                            <span className="checkMark"></span>
                            <span className="custom-label">By checking this box, you authorize {appName} stuff to transfer Customer Data on your behalf from the URL provided, and you represent and warrant that you have all rights and permissions necessary for us to scrap the data from the above webpages and subpages.</span>
                        </span>
                    </div>

                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type='button' onClick={props.handleClose}><span>Cancel</span></button>
                        <button
                            className="theme-btn"
                            type='submit'
                            disabled={!(terms && urls[0].url)}
                            style={terms && urls[0].url ? {} : { background: "gray", cursor: "not-allowed" }}
                        >
                            <span>{loader.save ? <>Creating <FaSpinner className='spin' /></> : "Create"}</span>
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default WebpageModal