import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { onDuplicateAgent } from '../../../Redux/Actions/AgentActions'
import { useDispatch } from 'react-redux'

const DuplicateAgModal = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        duplicate: false
    })
    const [name, setName] = useState("")

    const handleDuplicate = (e) => {
        e.preventDefault()
        let data = {
            id: props.id,
            name
        }
        setLoader({
            ...loader,
            duplicate: true
        })
        dispatch(onDuplicateAgent(data, props.fetchAgents, props.handleClose, setName, loader, setLoader))
    }

    return (
        <Modal className="theme-modal" show={props.show} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={props.handleClose}><AiOutlineClose /></span>
                <form className="modal-content" onSubmit={handleDuplicate}>
                    <h3 className="text-center">Duplicate Your Agent</h3>
                    <div className="modal-card mt-4">
                        <div className="input-wrap">
                            <label className="label" htmlFor="">Agent Name</label>
                            <input
                                className="input"
                                type="text"
                                name="name"
                                placeholder="Enter Agent Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type='button' onClick={props.handleClose}><span>Cancel</span></button>
                        <button className="theme-btn" type='submit'><span>{loader.duplicate ? <>Creating <FaSpinner className='spin' /> </> : "Create"}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default DuplicateAgModal