import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TitleBar from '../Common/TitleBar'
import SidePanel from '../Common/SidePanel'
import DashboardHeader from '../Common/DashboardHeader'
import { ScrollPanel } from 'primereact/scrollpanel'
import DashboardFooter from '../Common/DashboardFooter'
import { Link, useLocation } from 'react-router-dom'
import { fetchData } from '../../Redux/Actions/CommonActions'
import { FaSpinner } from 'react-icons/fa'
import queryString from 'query-string'
import NumberListRows from './NumberListRows'
import { GoArrowLeft } from "react-icons/go";
import MainMenu from '../Common/MainMenu'
const NumberList = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const { id } = queryString.parse(location.search)

    const [numbers, setNumbers] = useState([])
    const [agents, setAgents] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })

    const fetchPhoneList = () => {
        let data = {
            id
        }
        dispatch(fetchData("fetch-phone-numbers", data, setNumbers, loader, setLoader))
    }

    const fetchAgents = () => {
        let data = {}
        dispatch(fetchData("list-agent", data, setAgents))
    }


    useEffect(() => {
        if (id) {
            fetchPhoneList()
        }
    }, [id])

    useEffect(() => {
        fetchAgents()
    }, [])

    return (
        <>
            <TitleBar title="Phone Number" />
            <MainMenu>
                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">

                        <div className='d-flex align-items-center justify-content-between'>
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <span><img src={require("../../images/icon/headset.svg").default} alt="" /></span>
                                    <h4 className="fw-500 Fs-1">{auth.user.name}'s Account</h4>
                                    {/* <span><img src={require("../../images/icon/note-pencil.svg").default} alt="" /></span> */}
                                </div>
                                <h5 className="fw-400 Fs-2 pt-2">Select a number for your AI agent.</h5>
                            </div>
                            <div style={{ fontSize: 18 }}>
                                <Link to={'/phone-number'} style={{ color: "#000" }} className='d-flex align-items-center'><GoArrowLeft />Back</Link>
                            </div>
                        </div>

                        <div className="account-blocknn mt-4">
                            <div className="table-wrap">
                                <table className="table theme-table">
                                    <thead>
                                        <tr>
                                            <td>Name</td>
                                            <td>Phone Number</td>
                                            <td>Status</td>
                                            <td>Agent Linked With</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {numbers.length > 0 ?
                                            numbers.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <NumberListRows
                                                            curElem={curElem}
                                                            agents={agents.map((curElem) => {
                                                                return {
                                                                    ...curElem,
                                                                    label: curElem.name
                                                                }
                                                            })}
                                                            scId={id}
                                                            fetchPhoneList={fetchPhoneList}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={4} className='text-center'>
                                                    <FaSpinner className="spin" style={{ fontSize: 20, color: "#000" }} />
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </ScrollPanel>
            </MainMenu>
        </>
    )
}

export default NumberList