import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { IoCalendarClearOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { FaRegEdit, FaRegTrashAlt, FaSpinner } from "react-icons/fa";
import { IoDuplicateOutline } from "react-icons/io5";
import CommonAlert from '../../Common/CommonAlert'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { onDuplicateAgent } from '../../../Redux/Actions/AgentActions'
import DuplicateAgModal from './DuplicateAgModal'

const AgentRows = (props) => {
    const dispatch = useDispatch()

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false

    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: props.curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-agent", data, props.agents, props.setAgents, setSweet))
    }





    const handleCheck = (e) => {
        let arr = [...props.checkedArr]
        if (e.target.checked) {
            arr.push(props.curElem.id)
        } else {
            let idIndex = props.checkedArr.findIndex((val) => val === props.curElem.id)
            arr.splice(idIndex, 1)
        }
        props.setCheckedArr(arr)

    }


    return (
        <tr>
            <td>
                <span className="custom-check">
                    <input
                        type="checkbox"
                        checked={props.checkedArr.includes(props.curElem.id)}
                        onChange={handleCheck}
                    />
                    <span className="checkMark"></span>
                </span>
            </td>
            <td>{props.curElem.code}</td>
            {props.col.first ?
                <td>
                    <span className="userImg">
                        <img src={props.curElem.image} alt="" />
                    </span>
                    {props.curElem.name}
                </td> : null}
            {props.col.second ?
                <td className='text-capitalize'>{props.curElem.agentType}</td> : null}
            {props.col.third ?
                <td>{props.curElem.category}</td> : null}
            {props.col.forth ?
                // <td><IoCalendarClearOutline /> Just now</td> 
                <td><IoCalendarClearOutline /> {props.curElem.created}</td>

                : null}
            <td className="text-end">
                {+props.auth.user.isClientAccount === 1 ? "" :
                    <span className="profile-menu">
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic"><BiDotsHorizontalRounded /></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Link to={`/agent/customize-agent?id=${props.curElem.id}`}><FaRegEdit />Edit</Link>
                                <Link onClick={onDelete}><FaRegTrashAlt />Delete</Link>
                                <Link onClick={handleShow}> <IoDuplicateOutline />Duplicate</Link>

                            </Dropdown.Menu>
                        </Dropdown>
                    </span>}
            </td>

            <DuplicateAgModal
                id={props.curElem.id}
                show={show}
                handleClose={handleClose}
                fetchAgents={props.fetchAgents}
            />

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this agent?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />

        </tr>
    )
}

export default AgentRows