const initialState = {
    google: [],
    twilio: [],
    elevenLabs: [],
    autoresponder: []
}

export const SocialReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SOCIAL_ACCOUNTS':
            if (!action.payload) {
                return {
                    ...initialState
                }
            } else {
                return {
                    ...state,
                    google: action.payload.google,
                    twilio: action.payload.twilio,
                    elevenLabs: action.payload.elevenLabs,
                    autoresponder: action.payload.autoresponder,
                }
            }

        case 'ADD_SYVID':
            return {
                ...state,
                syvid: action.payload
            }

        case 'REMOVE_SOCIAL_ACCOUNT':
            return {
                ...initialState
            }

        default:
            return state
    }
}