import React from "react";
import { useSelector } from "react-redux";

const AgentBlank = ({ handleShow }) => {
    const auth = useSelector(state => state.auth)
    return (
        <>
            <div className="blank-container">
                <div className="blank-container-in">
                    <h5 className="fw-700 pb-3">No Agents</h5>
                    <img src={require("../../../images/agent.svg").default} alt="" />
                    <hr />
                    <h5 className="fw-500 pb-3">You need to add your first Agent</h5>
                    {+auth.user.isClientAccount === 1 ? "" :
                        <button className="theme-btn" onClick={handleShow}>+ Add Agent</button>
                    }
                </div>
            </div>
        </>
    )
}

export default AgentBlank;