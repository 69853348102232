import React, { useEffect, useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dropdown } from 'primereact/dropdown';
import Modal from 'react-bootstrap/Modal';

import { MdOutlineEdit } from "react-icons/md";
import { BsTrash, BsExclamationTriangle } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { addAccData, fetchAccData } from "../../Redux/Actions/AccountManagements";
import { useDispatch } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import MemberRows from "./MemberRows";
import TitleBar from "../Common/TitleBar";
import MainMenu from "../Common/MainMenu";

const AccountManagement = () => {


    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        create: false,
        fetch: false
    })
    const [teamMember, setTeamMember] = useState([])
    const [clients, setClients] = useState([])

    const cities = [
        { name: 'Team Member', code: 'team' },
        { name: 'Client', code: 'client' }
    ];

    const [user, setUser] = useState({
        type: { name: 'Team Member', code: 'team' },
        email: '',
        name: '',
        password: ''
    })
    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        if (user.password) {
            if (pattern.test(user.password)) {
                setLoader({
                    ...loader,
                    create: true
                });
                let obj = { ...user }
                obj.type = obj.type.code
                dispatch(addAccData(obj, fetchMembers, loader, setLoader, setUser))
                setPasswordMsg({ ...passwordMsg, validation: false, msg: "" })
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    const fetchMembers = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchAccData(setTeamMember, setClients, loader, setLoader))
    }

    useEffect(() => {
        fetchMembers()
    }, [])


    return (
        <>
            <TitleBar title="Account Management" />
            {/* <div className="dashboard">
                <SidePanel />
                <div className="dashboard-in">
                    <DashboardHeader /> */}
            <MainMenu>
                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">
                        <div className="page-title">Account Management</div>
                        <div className="account-areaN">

                            <form className="account-block mt-4" onSubmit={handleSubmit}>
                                <h6>Create Account</h6>
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Account Type</label>
                                    <Dropdown
                                        value={user.type}
                                        onChange={(e) => setUser({
                                            ...user,
                                            type: e.value
                                        })}
                                        options={cities}
                                        optionLabel="name"
                                        placeholder="Select Type"
                                        className="input"
                                    />
                                </div>

                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Account Name</label>
                                    <input
                                        className="input"
                                        placeholder='Enter Name Here'
                                        type="text"
                                        name='name'
                                        value={user.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Account Email</label>
                                    <input
                                        className="input"
                                        type="email"
                                        placeholder='Enter Email Here'
                                        value={user.email}
                                        onChange={handleChange}
                                        name='email'
                                        required
                                    />
                                </div>


                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Password</label>
                                    <input
                                        className="input"
                                        type="password"
                                        name='password'
                                        placeholder='Enter Password'
                                        value={user.password}
                                        onChange={handleChange}
                                        required

                                    />
                                </div>
                                <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>
                                <div className="btn-group">
                                    <button type="submit" className="theme-btn"><span>{loader.create ? <>Creating <FaSpinner className="spin" size={14} /></> : "Create"}</span></button>
                                </div>
                            </form>

                            <div className="account-block mt-4">
                                <h6 className="pb-4">Manage Client Account</h6>
                                <div className="table-wrap">
                                    <div className="table-responsive">
                                        <table className="table theme-table">
                                            <thead>
                                                <tr>
                                                    <th style={{width:'15%'}}>Name</th>
                                                    <th style={{width:'38%'}}>Email Address</th>
                                                    <th style={{width:'38%'}}>Created</th>
                                                    <th className="text-end">Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {clients.length > 0 ?
                                                    clients.map((curElem, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <MemberRows
                                                                    curElem={curElem}
                                                                    fetchMembers={fetchMembers}
                                                                    type="Client"
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    :
                                                    <tr className='text-center'>
                                                        <td colSpan={4}>
                                                            {
                                                                loader.fetch ?
                                                                    <FaSpinner className="spin" style={{ fontSize: 20, color: "#000" }} />
                                                                    : "You have not created any client yet!"
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="account-block mt-4">
                                <h6 className="pb-4">Manage Team Members Account</h6>
                                <div className="table-wrap">
                                    <div className="table-responsive">
                                        <table className="table theme-table">
                                            <thead>
                                                <tr>
                                                    <th style={{width:'15%'}}>Name</th>
                                                    <th style={{width:'38%'}}>Email Address</th>
                                                    <th style={{width:'38%'}}>Created</th>
                                                    <th className="text-end">Actions</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {teamMember.length > 0 ?
                                                    teamMember.map((curElem, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <MemberRows
                                                                    curElem={curElem}
                                                                    fetchMembers={fetchMembers}
                                                                    type="Team"
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    :
                                                    <tr className='text-center'>
                                                        <td colSpan={4}>
                                                            {
                                                                loader.fetch ?
                                                                    <FaSpinner className="spin" style={{ fontSize: 20, color: "#000" }} />
                                                                    : "You have not created any team member yet!"
                                                            }
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </ScrollPanel>

                {/* <DashboardFooter />
                </div>
            </div> */}

            </MainMenu>



            {/* <Modal className="theme-modal small-modal" show={show2} onHide={handleClose2} backdrop="static" centered>
                <Modal.Body>
                    <span className="modal-close" onClick={handleClose2}><AiOutlineClose /></span>
                    <div className="text-center">
                        <div className="info-icon"><BsExclamationTriangle /></div>
                    </div>
                    <h5 className="pt-4 text-center">Are you sure, you want to delete this member?</h5>
                    <div className="btn-group mt-4 justify-content-center">
                        <button className="theme-btn light" onClick={handleClose2}><span>Cancel</span></button>
                        <button className="theme-btn"><span>Delete</span></button>
                    </div>
                </Modal.Body>
            </Modal> */}

        </>
    )
}

export default AccountManagement;