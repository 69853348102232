import React, { useRef, useState } from 'react'
import { FaRegEdit, FaRegUserCircle, FaSpinner } from 'react-icons/fa'
import { IoCallOutline } from 'react-icons/io5'
import { PiChatTeardropDots } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux'
import ContentEditable from 'react-contenteditable'
import { MdOutlineCancel } from "react-icons/md";
import { onChangeAgentName, onSaveAgent } from '../../../../Redux/Actions/AgentActions'
import { FiUpload } from 'react-icons/fi'
import { onUploadMedia } from '../../../../Redux/Actions/CommonActions'
import Swal from 'sweetalert2'
import { FaCirclePause, FaCirclePlay } from "react-icons/fa6";

import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { setAlert } from '../../../../Redux/Actions/AlertActions'

let music = false
const AgentHeader = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    const agent = useSelector(state => state.agent.data)
    const inputRef = useRef(null)
    const [percent, setPercent] = useState(0)
    const [play, setPlay] = useState(false)
    const [loader, setLoader] = useState({
        upload: false,
        voiceLoader: false
    })

    const [active, setActive] = useState(false)

    const onCompeleteUpload = (url) => {

    }

    const uploadFile = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 50000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }


    const handleChange = (e) => {
        if (e.target.value.length < 65) {
            dispatch(onChangeAgentName(e.target.value))
        } else {
            setActive(false)
        }
    }


    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     let isSpaneinName = true
    //     for (let i = 0; i < agent.name.length; i++) {
    //         if (agent.name[i] !== " ") {
    //             isSpaneinName = false
    //             break;
    //         }
    //     }
    //     if(isSpaneinName){
    //         dispatch(setAlert("Please provide a valid name for an agent.", "danger"))
    //     }else{
    //         setLoader({
    //             ...loader,
    //             save: true
    //         })
    //         dispatch(onSaveAgent(agent, loader, setLoader))

    //     }
    // }

    const handleMusic = () => {
        if (agent.voice) {
            if (play) {
                setPlay(false)
                music.pause()
                music = false
            } else {
                setLoader({
                    ...loader,
                    voiceLoader: true
                })
                music = new Audio(agent.voice.data.url)
                music.play().then(() => {
                    setLoader({
                        ...loader,
                        voiceLoader: false
                    })
                    setPlay(true)
                })
                music.onended = () => {
                    setPlay(false)
                }
            }
        } else {
            dispatch(setAlert("Please select a voice from settings tab to listen.", "danger"))
        }
    }

    const handleNameActive = () => {
        setActive(true)
        inputRef.current.focus()
    }

    const handleNameDeactive = () => {
        if (agent.name !== "") {
            setActive(false)
            dispatch(onSaveAgent(agent, loader, setLoader))
        } else {
            dispatch(setAlert("Agent name can not be empty. Please provide an agent name.", "danger"))
        }
    }


    return (
        <div className="account-block mb-4">
            <div className="customer-support">
                <div className="customer-support-head">
                    <div className="customer-support-left">
                        <div className="support-badge align-items-start">
                            <div className="support-badge-image">
                                <img src={agent.image} alt="" />
                            </div>
                            <div className="support-badge-txt">
                                <h6>
                                    <input
                                        ref={inputRef}
                                        style={{ border: "none", }}
                                        type='text'
                                        className='input'
                                        value={agent.name}
                                        onChange={handleChange}
                                        readOnly={active ? false : true}
                                    />
                                    {/* <ContentEditable
                                        tagName='span'
                                        html={agent.name}
                                        disabled={!active}
                                        onChange={handleChange}
                                    /> */}
                                    {active ?
                                        <MdOutlineCancel className="ms-2 cursor-pointer" onClick={handleNameDeactive} />
                                        :
                                        +auth.user.isClientAccount === 1 ? "" :
                                            <FaRegEdit className="ms-2 cursor-pointer" onClick={handleNameActive} />
                                    }
                                </h6>
                                <p className="small grey pt-1">{agent.category}</p>
                                <div className="customer-support-item mt-1">
                                    <ul>
                                        <li><FaRegUserCircle /> Agent ID: {agent.code}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-support-right">
                        <button className="theme-btn light" onClick={handleMusic}>
                            <span>
                                {play ?
                                    <FaCirclePause className='me-1' /> :
                                    loader.voiceLoader ?
                                        <FaSpinner className='spin me-1' /> :
                                        <FaCirclePlay className='me-1' />
                                }
                                Listen to the agent voice
                            </span>
                        </button>

                        <button style={{ cursor: agent.phoneNumber ? null : "not-allowed" }} className={`theme-btn ${agent.phoneNumber ? "" : "light"}`} id='test_call'><span><IoCallOutline /> Test with phone call</span></button>
                        {agent.phoneNumber ?
                            <Tooltip anchorSelect="#test_call" place="left" clickable>
                                Please contact on this number ({agent.phoneNumber}) to talk with the agent.
                            </Tooltip> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgentHeader