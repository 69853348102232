import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Conversations from "./Conversations";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import ActiveConversation from "./ActiveConversation";
import MessagesSection from "./MessagesSection";
import MessagesFooter from "./MessagesFooter";
import { onFetchConversation } from "../../../../Redux/Actions/AgentActions";

const UnansweredQuestionsTab = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { id } = queryString.parse(location.search)
    const extra = useSelector(state => state.extra.data)

    const [conv, setConv] = useState([])
    const [messages, setMessages] = useState([])
    const [activeConv, setActiveConv] = useState(false)
    const [loader, setLoader] = useState({
        fetch: true
    })


    const fetchConversation = () => {
        let data = { id }
        dispatch(onFetchConversation(data, setConv, activeConv, setActiveConv, loader, setLoader))
    }

    useEffect(() => {
        fetchConversation()
    }, [])


    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
 {extra.isKnowledgeData ? null :
                <div className="alert alert-warning" role="alert">
                    Please add some data in knowledgebase to have conversation with the agent.
                </div>
            }
                <div className="chat-wrap">
                    <Conversations
                        conv={conv}
                        loader={loader}
                        activeConv={activeConv}
                        setActiveConv={setActiveConv}
                        setMessages={setMessages}
                    />

                    <div className="chat-window">
                        <div className="chat-window-main">
                            <ActiveConversation
                                activeConv={activeConv}
                            />
                            <div className="chat-area">
                                <MessagesSection
                                    messages={messages}
                                />
                            </div>

                            <div className="chat-window-footer">
                                <MessagesFooter
                                    fetchCallAnalysis={fetchConversation}
                                />
                            </div>
                        </div>
                    </div>
                </div >

            </>
    )
}

export default UnansweredQuestionsTab;