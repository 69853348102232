import React, { useState } from "react";

const PhoneNumberBlank = ({ handleShow }) => {
  return (
    <>
      <div className="blank-container">
        <div className="blank-container-in">
          <h5 className="fw-700 pb-3">No Twilio Account Connected</h5>
          <img src={require("../../images/agent.svg").default} alt="" />
          <hr />
          <h5 className="fw-500 pb-3">You need three steps to add a twilio account</h5>

          <div className="cta mb-3">
            <div className="cta-icon"><img src={require("../../images/icon/rocket.svg").default} alt="" /></div>
            <div className="cta-txt">
              <h6>Account SID</h6>
              <p>Login to your twilio account and get sid number </p>
            </div>
          </div>

          <div className="cta mb-3">
            <div className="cta-icon"><img src={require("../../images/icon/clock2.svg").default} alt="" /></div>
            <div className="cta-txt">
              <h6>Account Token</h6>
              <p>You need to get your account token</p>
            </div>
          </div>

          <div className="cta mb-3">
            <div className="cta-icon"><img src={require("../../images/icon/add.svg").default} alt="" /></div>
            <div className="cta-txt">
              <h6>Account Name</h6>
              <p>Please give your account a name</p>
            </div>
          </div>

          <button className="theme-btn mt-2" onClick={handleShow}>+ Connect Your Account</button>
        </div>
      </div>
    </>
  )
}

export default PhoneNumberBlank;