import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const WidgetBox = ({ state, image }) => {
    const agent = useSelector(state => state.agent.data)
    const [copy, setCopy] = useState(false)
    let script = `<script src="https://backend.aicallings.com/assets/clone_bot.js?id=${agent.id}" id="calling_bot"></script>`

    const handleCopy = () => {
        let copyText = document.getElementById("myInputCalling");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
        setCopy(true)

        setTimeout(() => {
            setCopy(false)
        }, 5000)
    }

    return (
        <div className="agent-widget-right">
            <div className="agent-widget-right-in">
                <h6>Test Widget</h6>
                <p className="pt-3">You can use this widget on any website and start utilizing your AI Agent. Please see the preview below to understand how the widget will appear on the website when embedded.</p>
                <div className='d-flex align-items-center justify-content-center'>
                    <div className="widget-prev mt-4" style={{ background: state.theme === "Light Theme" ? "" : "#000", height: state.cardSize.height, width: state.cardSize.width }}>
                        <div className="widget-img">
                            {image ?
                                <img src={image} alt="" /> : null}
                        </div>
                        <h6 className="pt-3" style={{ fontFamily: state.fontName, color: state.theme === "Light Theme" ? "" : "#fff" }}>{state.name}</h6>
                        <div className="widget-stat">
                            <span className="stat-box"></span>
                            <span style={{ fontFamily: state.fontName, color: state.theme === "Light Theme" ? "" : "#fff" }}>Online Now</span>
                        </div>
                        {agent.agentType === "inbound" ?
                            <>
                                <button className="theme-btn light widget-btn mt-4" style={{ background: state.brandColor, color: "#fff" }}>{agent.phoneNumber ? agent.phoneNumber : "Phone Not Assigned"}</button>
                                <p className='mt-2' style={{ fontFamily: state.fontName, color: state.theme === "Light Theme" ? "" : "#fff" }}>Call to this no. to talk with the agent.</p>
                            </> :

                            <>
                                <div className="simple-input mb-3">
                                    {agent.phoneNumber ?
                                        null :
                                        <div class="alert alert-warning" role="alert">
                                            Phone No. not assigend
                                        </div>
                                    }
                                    <input type="text" className="input" style={{ fontFamily: state.fontName }} placeholder="Enter name" />
                                    <input type="text" className="input my-1" style={{ fontFamily: state.fontName }} placeholder="Enter email" />
                                    <input type="text" className="input" style={{ fontFamily: state.fontName }} placeholder="Enter phone no." />
                                </div>
                                {/* <p className='mt-2' style={{ fontFamily: state.fontName, color: state.theme === "Light Theme" ? "" : "#fff" }}>Enter your phone no. to get a call.</p> */}
                                <button style={{ fontFamily: state.fontName, background: state.brandColor, color: "#fff", border: "none" }} className="theme-btn widget-btn mt-2">{state.buttonName}</button>
                            </>
                        }



                    </div>
                </div>
                <h6 className="pt-4">Embedded Code</h6>
                <p className="pt-3">You can use this widget on any website and start utilizing  your AI Agent. Please copy the code below and start using your AI agent on any website you want.</p>

                <div className="code-embed mt-4" >
                    <textarea
                        value={script}
                        id="myInputCalling"
                        style={{ height: "80px", width: "100%", background: "transparent", border: "none", color: "#fff" }}
                        readOnly
                    />

                </div>
                <div className='text-end mt-1'>
                    <button onClick={handleCopy} className='theme-btn'>{copy ? "Copied" : "Copy"}</button>
                </div>
            </div>

        </div>
    )
}

export default WidgetBox