import { commonAxios } from "../../Global/CommonAxios"
import { onFetchAgent } from "./AgentActions"
import { setAlert } from "./AlertActions"


export const onFetchCampaign = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_CAMAPIGN_DATA", payload: res.data[0] })
                if (res.data[0].agentId) {
                    let obj = {
                        agentId: res.data[0].agentId
                    }
                    dispatch(onFetchAgent(obj, false, loader, setLoader))
                }
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                fetch: false
            })
        })
}

export const onAgentConfig = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("agent-configuration", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submit: false
            })
        })
}

export const onCallBackRules = (url, data, fetchRules, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fetchRules()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submit: false
            })
        })
}

export const onFetchRules = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-rules", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                fetch: false
            })

        })
}





export const onFetchOverView = (data, setState, loader, setLoader, chartData) => (dispatch, getState) => {
    commonAxios("overview-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (chartData) {
                    let data = [
                        {
                            name: "Jan",
                            call: 0,
                        },
                        {
                            name: "Feb",
                            call: 0,
                        },
                        {
                            name: "Mar",
                            call: 0,
                        },
                        {
                            name: "Apr",
                            call: 0,
                        },
                        {
                            name: "May",
                            call: 0,
                        },
                        {
                            name: "Jun",
                            call: 0,
                        },
                        {
                            name: "Jul",
                            call: 0,
                        },
                        {
                            name: "Aug",
                            call: 0,
                        },
                        {
                            name: "Sep",
                            call: 0,
                        },
                        {
                            name: "Oct",
                            call: 0,
                        },
                        {
                            name: "Nov",
                            call: 0,
                        },
                        {
                            name: "Dec",
                            call: 0,
                        },
                    ];

                    res.data.forEach((curElem) => {
                        let index = new Date(curElem.created.split(" ")[0])
                        data[index.getMonth()].call += 1
                    })
                    setState(data)
                } else {
                    setState(res.data)
                    dispatch({ type: "CAMPAIGN_STATISTICS", payload: res.data })
                }
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                fetch: false
            })

        })
}


export const onStartCampaign = (data, fetchCampaigns, loader, setLoader) => (dispatch, getState) => {
    commonAxios("start-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchCampaigns()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))

            }
            setLoader({
                ...loader,
                save: false
            })

        }).catch((err) => {

            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                save: false
            })
        })
}


export const onCheckCampaignStatus = (data, interval, setStatus) => (dispatch, getState) => {
    commonAxios("fetch-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (+res.data[0].status === 2 || +res.data[0].status === 3) {
                    clearInterval(interval)
                }
                setStatus(+res.data[0].status)
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onFetchMinutes = (data, setMinutes) => (dispatch, getState) => {
    commonAxios("minutes-usage", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setMinutes(res.data)
                // dispatch(setAlert(res.msg, "success"));

            } else {
                setMinutes([])

                // dispatch(setAlert(res.msg, "danger"));

            }
            // if (setLoader) {
            //     setLoader(false)
            // }
        })
        .catch((err) => {
            console.log(err)

            // if (setLoader) {
            //     setLoader(false)
            // }
        });
};


// No Axios Here

export const unmountCampaign = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_CAMPAIGN" })
}


