import React, { useEffect, useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";

import { ScrollPanel } from 'primereact/scrollpanel';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';

import { FiUsers, FiLock } from "react-icons/fi";
import { PiProjectorScreen } from "react-icons/pi";
import { IoShareSocialOutline, IoFilmOutline } from "react-icons/io5";
import { MdOutlinePrivacyTip, MdOndemandVideo } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticles, fetchVideos } from "../../Redux/Actions/HelpActions";
import { FaSpinner } from "react-icons/fa";
import TitleBar from "../Common/TitleBar";
import appThumb from "../../images/appThumb.png"
import MainMenu from "../Common/MainMenu";
const Help = () => {

    const dispatch = useDispatch()
    const rebrand = useSelector(state => state.rebrand.data)
    const help = useSelector(state => state.help)

    const [url, setUrl] = useState('')
    const [q, setQ] = useState('')
    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }

    const playVideo = (url) => {
        setUrl(url);
        setShowModal(true);
    }

    const closeModal = () => {
        setUrl('');
        setShowModal(false);
    }

    useEffect(() => {
        fetchHelpData()
    }, [])

    return (
        <>
            <TitleBar title="Help" />
            {/* <div className="dashboard">
                <SidePanel />
                <div className="dashboard-in">
                    <DashboardHeader /> */}
            <MainMenu>
                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">
                        <div className="page-title">Help</div>
                        <div className="containerN">
                            <div className="help-top">
                                <div className="account-block">
                                    <h6 className="text-center">What do you want to know ?</h6>
                                    <div className="simple-input">
                                        <input className="input" type="text" placeholder="Search" onChange={(e) => setQ(e.target.value)} />
                                    </div>
                                </div>
                                <div className="account-block pt-0 mt-4">
                                    <div className="help-card">
                                        <div className="row">
                                            {help.article.length > 0 ?
                                                help.article.filter((art) => {
                                                    return art.tags.toLowerCase().includes(q.toLowerCase())
                                                }).map((curElem, index) => {
                                                    return (
                                                        <div className="col-sm-6 col-lg-3" key={index}>
                                                            <Link to={`/help-details?id=${curElem.id}`}>
                                                                <div className="help-card-single">
                                                                    <div className="help-card-icon">
                                                                        <img
                                                                            src={curElem.logo}
                                                                            alt=''
                                                                            style={{ width: 100, padding: 15, borderRadius: 10, height: 100, objectFit: "contain", filter: "invert(1)" }}
                                                                        />
                                                                    </div>
                                                                    <h6>{curElem.name}</h6>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                }) :

                                                <div className='col-sm-12 mt-3 text-center'>
                                                    {
                                                        loader.article ?
                                                            <FaSpinner className="spin" style={{ fontSize: 25, color: "#000" }} />
                                                            : ""
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="account-block mt-4">
                                    <h6 className="text-center">Popular Video Tutorials</h6>
                                    <div className="row">
                                        {help.videos.length > 0 ?
                                            help.videos.map((curElem, index) => {
                                                return (
                                                    <div className="col-sm-6 col-lg-3" key={index}>
                                                        <div className="video-thumb" onClick={() => playVideo(curElem.url)}>
                                                            <div className="video-thumb-img">
                                                                <img src={appThumb} alt="" />
                                                            </div>
                                                            <div className="video-thumb-txt">{curElem.title}</div>
                                                        </div>
                                                    </div>)
                                            }) :
                                            <div className='col-sm-12 mt-3 text-center'>
                                                {
                                                    loader.video ?
                                                        <FaSpinner className="spin" style={{ fontSize: 25, color: "#000" }} />
                                                        : ""
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="account-block mt-4">
                                    <h6 className="text-center">Can't Find Your Answer? We're Here for You!</h6>
                                    <p className="pt-3 text-center">It happens to the best of us. Sometimes, you might have a question that isn't covered in our FAQs or <br /> documentation. Don't worry - we've got your back!</p>
                                    <div className="text-center mt-3">
                                        <a className="theme-btn" href="https://support.vineasx.com/" target="_blank">Contact</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </ScrollPanel>

                {/* <DashboardFooter />
                </div>
            </div> */}

            </MainMenu>
            <Modal className="theme-modal video-modal" show={showModal} onHide={closeModal} backdrop="static" centered>
                <Modal.Body>
                    <span className="modal-close" onClick={closeModal}><AiOutlineClose /></span>
                    <div className="video-wrap">
                        <iframe id="frameSrc" class="embed-responsive-item" src={url} allowfullscreen="" title="help-videos"></iframe>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Help;