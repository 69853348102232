import { Dropdown } from 'primereact/dropdown'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FiUpload } from 'react-icons/fi'
import Swal from 'sweetalert2'
import { onUploadMedia, onUploadMultipleFiles } from '../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'
import { setAlert } from '../../Redux/Actions/AlertActions'
import { onExtractFile } from '../../Redux/Actions/AgentActions'

const UploadModal = (props) => {
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        upload: false,
        submit: false
    })

    const [file, setFile] = useState([])
    const [data, setData] = useState({
        type: "",
        lang: ""
    })
    const fileTypes = ['PDF', 'DOCX', 'TEXT', 'VIDEO']

    const onCompeleteUpload = (url) => {
        setFile(url)
    }

    const uploadFile = (e) => {
        if (e.target.files.length > 0) {
            let fileData = e.target.files
            let allFileOk = false
            const formData = new FormData()
            for (let i = 0; i < fileData.length; i++) {
                let curElem = fileData[i]
                let checkTypeList
                if (data.type === "VIDEO") {
                    checkTypeList = ['video/mp4']
                } else if (data.type === "PDF") {
                    checkTypeList = ["application/pdf"]
                } else if (data.type === "TEXT") {
                    checkTypeList = ["text/plain"]
                } else {
                    checkTypeList = ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"]
                }
                if (checkTypeList.includes(curElem.type)) {
                    if (curElem.size < 300000000) {
                        allFileOk = true
                        formData.append("file[]", curElem)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: `The maximum allowed size is 300MB. You have provided file ${curElem.name}'s size is more than 300MB!`,
                            confirmButtonColor: "#000"
                        })
                        allFileOk = false
                        break;
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: `You have selected invalid file "${curElem.name}"!`,
                        confirmButtonColor: "#000"
                    })
                    allFileOk = false
                    break;
                }
            }
            if (allFileOk) {
                setLoader({
                    ...loader,
                    upload: true
                })
                dispatch(onUploadMultipleFiles(formData, onCompeleteUpload, loader, setLoader))
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "type") {
            setFile([])
        }
        setData({
            ...data,
            [name]: value
        })
    }
    const handleSubmit = () => {
        if (file.length > 0) {
            let obj = {
                agentId: props.id,
                language: data.lang?.code ? data.lang?.code : "",
                sourceType: data.type,
                url: file
            }
            if (obj.sourceType === "VIDEO") {
                if (obj.language) {
                    setLoader({
                        ...loader,
                        submit: true
                    })
                    dispatch(onExtractFile(obj, props.fetchData, props.closeMain, props.handleClose, loader, setLoader))
                } else {
                    dispatch(setAlert("Please select a language to continue!", "danger"))
                }
            } else {
                setLoader({
                    ...loader,
                    submit: true
                })
                dispatch(onExtractFile(obj, props.fetchData, props.closeMain, props.handleClose, loader, setLoader))
            }

        } else {
            dispatch(setAlert("Please upload a file to extract content of it!", "danger"))
        }
    }

    useEffect(() => {
        if (props.show) {
            setData({
                type: "",
                lang: ""
            })
            setFile([])
        }
    }, [props.show])


    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={props.handleClose}><AiOutlineClose /></span>
                <div className="modal-content">
                    <h3 className="text-center">How do you want to add your info?</h3>
                    <p className="text-center pt-2">Don't worry, you can always change the info later</p>

                    <div className="modal-card mt-4">
                        <h6>Upload Files</h6>
                        <div className="row">
                            <div className={`col-lg-${data.type === "VIDEO" ? 6 : 12}`}>
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">File Type</label>
                                    <Dropdown
                                        value={data.type}
                                        options={fileTypes}
                                        placeholder="Select Type"
                                        className="input"
                                        onChange={handleChange}
                                        name='type'
                                    />
                                </div>
                            </div>
                            {data.type === "VIDEO" ?
                                <div className="col-lg-6">
                                    <div className="input-wrap">
                                        <label className="label" htmlFor="">Language</label>
                                        <Dropdown
                                            value={data.lang}
                                            options={props.transLang}
                                            optionLabel="name"
                                            name='lang'
                                            placeholder="Select Type"
                                            className="input"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> : null}
                        </div>
                        {data.type ?
                            <div className="uploader">
                                <div className="uploader-icon">
                                    {loader.upload ?
                                        <FaSpinner className='spin' /> :
                                        <FiUpload />}
                                </div>
                                <div className="uploader-txt">
                                    {file.length > 0 ?
                                        <p>{file.length} files uploaded.</p>
                                        :
                                        <>

                                            <h6>Upload File</h6>
                                            <p>Click here to upload a file from where we'll extract the information automatically.</p>
                                        </>}
                                </div>
                                {loader.upload ? null :
                                    <input
                                        type="file"
                                        onChange={uploadFile}
                                        multiple
                                        accept={data.type === "VIDEO" ? '.mp4' : data.type === "PDF" ? '.pdf' : data.type === "TEXT" ? ".text, .txt" : "application/msword, .doc, .rtf, .docs, .docx"}
                                    />
                                }
                            </div> : ""}
                    </div>

                    <div className="btn-group mt-4">
                        <button className="theme-btn light" onClick={props.handleClose}><span>Cancel</span></button>
                        <button
                            className="theme-btn"
                            onClick={handleSubmit}
                        >
                            <span>{loader.submit ? <>Creating <FaSpinner className="spin" /></> : "Create"}</span>
                        </button>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    )
}

export default UploadModal