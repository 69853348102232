import React, { useEffect, useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";

import { ScrollPanel } from 'primereact/scrollpanel';

import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from "react-icons/ai";
import TitleBar from "../Common/TitleBar";
import { fetchData } from "../../Redux/Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import MainMenu from "../Common/MainMenu";
import { onFetchMinutes } from "../../Redux/Actions/CampaignActions";


const Minutes = () => {
    const dispatch = useDispatch()
    const [hooks, setHooks] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })
    const auth = useSelector((state) => state.auth)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [minutes, setMinutes] = useState([])
    const fetchMinutes = () => {
        let data = {
            id: auth.user.id,
        }
        dispatch(onFetchMinutes(data, setMinutes))
    }
    // const fetchHookData = () => {
    //     let data = {}
    //     dispatch(fetchData("list-webhook", data, setHooks, loader, setLoader))
    // }
    useEffect(() => {
        fetchMinutes()
    }, [])
    return (
        <>
            {/* <TitleBar title="Webhook" />
      <MainMenu> */}

            {/* <ScrollPanel className="custombar2 content-area"> */}
            <div className="content-area-in">
                {/* {hooks.length === 0 ?
                    loader.fetch ?
                        <div className="text-center mt-4">
                            <FaSpinner className="spin" style={{ fontSize: 20, color: "#000" }} />
                        </div>
                        :
                        <WebhookBlank
                            handleShow={handleShow}
                        />

                    :

                    <> */}

                <div className="page-title d-flex justify-content-between align-items-center">
                    <div>Minute Status</div>
                </div>

                <div className="table-wrap mt-3">
                    <div className="table-responsive">
                        <table className="table theme-table">
                            <thead>
                                <tr>
                                    <th>Total Minutes</th>
                                    <th>Consume Status</th>
                                    <th>Remaining Minutes</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {Object.entries(minutes).length > 0 ?
                                    <tr >
                                        <td>{minutes.totalDuration}</td>
                                        <td>{minutes.consumedMinutes}</td>
                                        <td>{minutes.remainingMinutes}</td>
                                    </tr> :
                                    <tr >
                                        <td colSpan={3} className="text-center">{"No minutes available"}</td>

                                    </tr>
                                }






                            </tbody>



                        </table>
                    </div>
                </div>

            </div >


        </>
    )
}

export default Minutes;