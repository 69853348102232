import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FaRegEdit, FaRegTrashAlt, FaSpinner } from 'react-icons/fa'
import { IoCalendarClearOutline, IoDuplicateOutline } from 'react-icons/io5'
import { Link, useLocation } from 'react-router-dom'
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions'
import CommonAlert from '../../../Common/CommonAlert'
import { useDispatch, useSelector } from 'react-redux'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import queryString from 'query-string'
import { onCheckCampaignStatus, onStartCampaign } from '../../../../Redux/Actions/CampaignActions'

const CampaignRows = ({ curElem, campaigns, setCampaigns, fetchCampaigns, checkedArr, setCheckedArr, agents, col }) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const auth = useSelector(state => state.auth)

    const { id } = queryString.parse(location.search)
    const [status, setStatus] = useState(curElem.status)
    const [loader, setLoader] = useState({
        save: false
    })
    const [ag, setAg] = useState({
        name: "",
        image: ""
    })
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }
    const handleStart = () => {
        setLoader({
            ...loader,
            save: true
        })
        let data = {
            campaignId: curElem.id,

        }
        dispatch(onStartCampaign(data, fetchCampaigns, loader, setLoader))
    }
    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-campaign", data, campaigns, setCampaigns, setSweet))
    }
    const handleCheck = (e) => {
        let arr = [...checkedArr]
        if (e.target.checked) {
            arr.push(curElem.id)
        } else {
            let idIndex = checkedArr.findIndex((val) => val === curElem.id)
            arr.splice(idIndex, 1)
        }
        setCheckedArr(arr)
    }


    const checkStatus = (interval) => {
        let data = { id: curElem.id }
        dispatch(onCheckCampaignStatus(data, interval, setStatus))
    }

    useEffect(() => {
        if (agents.length > 0) {
            let val = agents.find(({ id }) => +id === +curElem.agentId)
            if (val) {
                setAg({
                    ...ag,
                    name: val.name,
                    image: val.image
                })
            } else {
                setAg({
                    ...ag,
                    name: "",
                    image: ""
                })
            }
        }
    }, [agents, curElem])

    useEffect(() => {
        if (+curElem.status === 4) {
            const interval = setInterval(() => {
                checkStatus(interval)
            }, 10000)
            return () => {
                clearInterval(interval)
            }
        }
        setStatus(curElem.status)
    }, [curElem])


    return (
        <tr>
            <td>
                <span className="custom-check">
                    <input
                        type="checkbox"
                        checked={checkedArr.includes(curElem.id)}
                        onChange={handleCheck}
                    />
                    <span className="checkMark"></span>
                </span>
            </td>
            {col ? col.first ? <td>{curElem.id}</td> : "" : <td>{curElem.id}</td>}

            {col ? col.second ?
                <td>{curElem.name}</td> : "" : <td>{curElem.name}</td>}
            {/* <td>{curElem.campaignType}</td> */}
            {col ? col.third ?
                <td>
                    {curElem.url ?
                        <a target='_blank' href={curElem.url} >Uploaded File</a> : null}
                </td> : "" : <td>
                {curElem.url ?
                    <a target='_blank' href={curElem.url} >Uploaded File</a> : null}
            </td>}

            {location.pathname === "/agent/customize-agent" ? null :
                col ? col.forth ?
                    <td><span className="userImg">
                        {ag.image ?
                            <img src={ag.image} alt="" /> : null}
                    </span> {ag.name}</td> : "" :
                    <td><span className="userImg">
                        {ag.image ?
                            <img src={ag.image} alt="" /> : null}
                    </span> {ag.name}</td>
            }
            {/* <td>{curElem.duration} days</td> */}
            <td>
                <span className={`tStatus ${+status === 2 ? "complete" : +status === 3 ? "failed" : +status === 4 ? "progress" : "paused"}`}>
                    {+status === 2 ? "Complete" : +status === 3 ? "Failed" : +status === 4 ? "In Progress" : "Created"}
                </span>
            </td>
            <td><IoCalendarClearOutline /> {curElem.startDate}</td>
            <td>
                {+auth.user.isClientAccount === 1 ? "" : +status === 4 ? null :
                    <button className="theme-btn light" onClick={handleStart}>
                        {loader.save ? < FaSpinner className="spin" style={{ fontSize: 20, color: "#000" }} /> : "Start"}
                    </button>}
            </td>
            <td className="text-end">
                <span className="profile-menu">
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic"><BiDotsHorizontalRounded /></Dropdown.Toggle>
                        <Dropdown.Menu>
                            {location.pathname === "/campaigns" ?
                                <Link to={`/campaigns/campaign-details?id=${curElem.id}`}><FaRegEdit />Edit</Link> :
                                <Link to={`/agent/campaign-details?id=${curElem.id}&aId=${id}`}><FaRegEdit />Edit</Link>}
                            {+auth.user.isClientAccount === 1 ? "" :
                                <Link onClick={onDelete}><FaRegTrashAlt />Delete</Link>
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </span>
            </td>


            {/* <CreateCampaignModal
                show={show}
                handleClose={handleClose}
                fetchCampaigns={fetchCampaigns}
                curElem={curElem}
            /> */}

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this campaign?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </tr>
    )
}

export default CampaignRows