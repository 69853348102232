import React, { useEffect, useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";

import { ScrollPanel } from 'primereact/scrollpanel';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import CallAnalysisTab from "./CallAnalysisTab";
import TranscriptTab from "./TranscriptTab";
import Conversations from "../Agent/UpdateAgent/UnansweredQuestion/Conversations";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import TitleBar from "../Common/TitleBar";
import { onFetchAnalysis } from "../../Redux/Actions/AgentActions";
import MainMenu from "../Common/MainMenu";

const CallHistory = () => {
   const location = useLocation()
   const dispatch = useDispatch()
   const { cId } = queryString.parse(location.search)
   const [conv, setConv] = useState([])
   const [messages, setMessages] = useState([])
   const [loader, setLoader] = useState({
      fetch: true
   })
   const [activeConv, setActiveConv] = useState(false)


   const fetchCallAnalysis = () => {
      let data = {}
      dispatch(onFetchAnalysis(data, setConv, activeConv, setActiveConv, loader, setLoader))
   }

   useEffect(() => {
      if (cId) {
         let acConv = conv.find(({ id }) => +id === +cId)
         if (acConv) {
            setActiveConv(acConv)
         }
      }
   }, [cId, conv])

   useEffect(() => {
      fetchCallAnalysis()
   }, [])

   return (
      <>
         <TitleBar title="Call History" />
         <MainMenu>
            <ScrollPanel className="custombar2 content-area">
               <div className="content-area-in">
                  {loader.fetch ?
                     <div className="loader-sec">
                        <div className="loader" />
                     </div> :
                     <div className="chat-wrap">
                        <Conversations
                           conv={conv}
                           setConv={setConv}
                           loader={loader}
                           activeConv={activeConv}
                           setActiveConv={setActiveConv}
                           setMessages={setMessages}
                        />
                        <div className="chat-window">
                           <div className="tab-style">
                              <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                                 <Nav variant="pillsb">
                                    <Nav.Item><Nav.Link eventKey="tab-1">Call Analysis</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="tab-2">Transcript</Nav.Link></Nav.Item>
                                 </Nav>
                                 <Tab.Content>
                                    <Tab.Pane eventKey="tab-1">
                                       <CallAnalysisTab
                                          activeConv={activeConv}
                                       />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="tab-2">
                                       <TranscriptTab
                                          activeConv={activeConv}
                                          messages={messages}
                                          fetchCallAnalysis={fetchCallAnalysis}
                                       />
                                    </Tab.Pane>
                                 </Tab.Content>
                              </Tab.Container>
                           </div>
                        </div>
                     </div>}
               </div>
            </ScrollPanel>
         </MainMenu>
      </>
   )
}
export default CallHistory;