import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onRephraseData } from '../../../../Redux/Actions/AgentActions'
import { onChangeRephrase } from '../../../../Redux/Actions/ExtraActions'

const MessagesFooter = ({fetchCallAnalysis}) => {
    const dispatch = useDispatch()
    const rephraseData = useSelector(state => state.extra.data.rephraseData)

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            ...rephraseData
        }
        dispatch(onRephraseData(data, fetchCallAnalysis))
    }

    const handleChange = (e) => {
        dispatch(onChangeRephrase(e.target.value))
    }

    return (
        rephraseData ?

            <form className="chat-window-footer-in" onSubmit={handleSubmit}>
                <div className="chatIcon">
                    <span className="theme-icon"><img src={require("../../../../images/icon/mic.svg").default} alt="" /></span>
                    {/* <span className="theme-icon"><img src={require("../../../../images/icon/attach.svg").default} alt="" /></span> */}
                </div>
                <div className="chat-inp">
                    <input
                        type="text"
                        placeholder="Type message to train your agent answering similar questions...."
                        value={rephraseData.rePhrased}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <button className="theme-icon" style={{ border: "none" }} type='submit'>
                        <img src={require("../../../../images/icon/send.svg").default} alt="" />
                    </button>
                </div>
            </form> : <></>
    )
}

export default MessagesFooter