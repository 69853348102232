import { Dropdown } from 'primereact/dropdown'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { fetchData, fetchDataPrimereact } from '../../../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'

const VoiceFilterModal = (props) => {
    const dispatch = useDispatch()
    const [language, setLanguage] = useState([])
    const gender = ["All", "Male", "Female"]
    const [accent, setAccent] = useState([])
    const [tone, setTone] = useState([])
    const [selectedVal, setSelectedVal] = useState("All")

    const handleChange = (e) => {
        setSelectedVal(e.target.value)
    }

    const handleSubmit = () => {
        let name = "lang"
        if (props.type === "ge") {
            name = "gender"
        } else if (props.type === "ac") {
            name = "accent"
        } else if (props.type === "to") {
            name = "tone"
        }
        props.setFilterObj({
            ...props.filterObj,
            [name]: selectedVal
        })
        props.handleClose()
        setSelectedVal("All")
    }

    const fetchLanguage = () => {
        let data = {}
        dispatch(fetchDataPrimereact("get-languages", data, setLanguage, false, false, "name", true, "name", "addAll"))
    }

    const fetchTone = () => {
        let data = {}
        dispatch(fetchDataPrimereact("fetch-tone", data, setTone, false, false, "name", true, "name", "addAll"))
    }

    const fetchAccents = () => {
        let obj = {}
        dispatch(fetchDataPrimereact("fetch-accents", obj, setAccent, false, false, "name", true, "name", "addAll"))
    }

    useEffect(() => {
        if (props.show) {
            let val = props.filterObj.lang
            if (props.type === "ge") {
                val = props.filterObj.gender
            } else if (props.type === "ac") {
                val = props.filterObj.accent
            } else if (props.type === "to") {
                val = props.filterObj.tone
            }
            setSelectedVal(val)
        }
    }, [props.show])

    useEffect(() => {
        fetchLanguage()
        fetchTone()
        fetchAccents()
    }, [])
    
    return (
        <Modal className="theme-modal small-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
            <Modal.Body>
                <div >
                    <span className="modal-close" onClick={props.handleClose}><AiOutlineClose /></span>
                    <h5 className="pb-3">Choose voice according to the {props.type === "lg" ? "language" : props.type === "ge" ? "gender" : props.type === "ac" ? "accent" : "tone"}</h5>
                    <div className="row">
                        {props.type === "lg" ?
                            <div className="col-lg-12">
                                <div className="simple-input">
                                    <label className="label" htmlFor="">Languages</label>
                                    <Dropdown
                                        value={selectedVal}
                                        onChange={(e) => handleChange(e)}
                                        options={language}
                                        placeholder="Select Type"
                                        className="input text-capitalize"
                                        name="lang"
                                    />
                                </div>
                            </div> : null}
                        {props.type === "ge" ?
                            <div className="col-lg-12">
                                <div className="simple-input">
                                    <label className="label" htmlFor="">Gender</label>
                                    <Dropdown
                                        value={selectedVal}
                                        onChange={(e) => handleChange(e)}
                                        options={gender}
                                        placeholder="Select Type"
                                        className="input"
                                        name="gender"
                                    />
                                </div>
                            </div> : null}
                        {props.type === "ac" ?
                            <div className="col-lg-12">
                                <div className="simple-input">
                                    <label className="label" htmlFor="">Accent</label>
                                    <Dropdown
                                        value={selectedVal}
                                        onChange={(e) => handleChange(e)}
                                        options={accent}
                                        placeholder="Select Type"
                                        className="input"
                                        name="accent"
                                    />
                                </div>
                            </div> : null}
                        {props.type === "to" ?
                            <div className="col-lg-12">
                                <div className="simple-input">
                                    <label className="label" htmlFor="">Tone</label>
                                    <Dropdown
                                        value={selectedVal}
                                        onChange={(e) => handleChange(e)}
                                        options={tone}
                                        placeholder="Select Type"
                                        className="input"
                                        name="tone"
                                    />
                                </div>
                            </div> : null}
                    </div>
                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type="button" onClick={props.handleClose}><span>Cancel</span></button>
                        <button className="theme-btn" type='button' onClick={handleSubmit}><span>Submit</span></button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default VoiceFilterModal