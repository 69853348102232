import React, { useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';
import Modal from 'react-bootstrap/Modal';

import { AiOutlineClose } from "react-icons/ai";
import { IoDownloadOutline } from "react-icons/io5";
import { BsStoplights } from "react-icons/bs";
import { FaRegTrashAlt, FaSpinner } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { appName } from "../../Global/Global";
import { gdprAction, onChangeEmail } from "../../Redux/Actions/AuthActions";
import CommonAlert from "../Common/CommonAlert";
import TitleBar from "../Common/TitleBar";
import MainMenu from "../Common/MainMenu";

const Privacy = () => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand.data)
    const [email, setEmail] = useState("")
    const [loader, setLoader] = useState(false)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "",
        text: "",
        ep: "",
        loader: false
    })

    const modalData = [
        {
            message: `${rebrand ? rebrand.name : appName} stores your private information. If you wish to download these data, you can do that by clicking on Download button.`
        },
        {
            message: 'Are you sure you want to stop processing your data? Doing so will disable your account and in order to reactivate your'
        },
        {
            message: 'Are you sure you want to delete your data? This action is permanent and cannot be reversed.'
        }

    ]
    const performAction = () => {
        setSweet({
            ...sweet,
            loader: true
        })
        dispatch(gdprAction({}, sweet, setSweet))
    }

    const handleClick = (index) => {
        setSweet({
            ...sweet,
            enable: true,
            id: index,
            text: modalData[index].message,
            confirmButtonName: index === 0 ? "Download" : index === 1 ? "Stop Processing" : "Delete Data",
            ep: index === 0 ? "download-user-data" : index === 1 ? "disable-account" : "delete-account"
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            ep: ""
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        setLoader(true)
        dispatch(onChangeEmail(data, setLoader))
    }

    return (
        <>
            <TitleBar title="Privacy" />
            {/* <div className="dashboard">
                <SidePanel />
                <div className="dashboard-in">
                    <DashboardHeader /> */}
            <MainMenu>
                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">
                        <div className="page-title">Privacy Settings</div>
                        <div className="account-areaN">

                            <form className="account-block mt-4" onSubmit={handleSubmit}>
                                <h6>Personal Info</h6>
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Current Email Address</label>
                                    <input
                                        className="input"
                                        type="email"
                                        placeholder='test@gmail.com'
                                        value={auth.user.email}
                                        readOnly
                                    />
                                </div>
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">New Email Address</label>
                                    <input
                                        className="input"
                                        type="email"
                                        placeholder={auth.user.email}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>

                                <div class="alert alert-primary mt-3" role="alert">Updating your email will log you out for re-login.</div>

                                <div className="btn-group">
                                    <button className="theme-btn"><span>{loader ? <>Updating <FaSpinner className="spin" size={14} /></> : "Update"}</span></button>
                                </div>
                            </form>

                            <div className="account-block mt-4">
                                <div className="privacy-block">
                                    <h6>Manage your data</h6>
                                    <p className="pt-3">{rebrand ? rebrand.name : appName} stores your private information like your name, email. It also stores your uploaded graphics such as images, videos and your music files along with TTS ( Text to speech). If you wish to download these data, you can do that "by clicking" download your data.</p>
                                    <button className="theme-btn mt-3" onClick={() => handleClick(0)}><span>Download Your Data</span></button>
                                </div>
                                <div className="privacy-block mt-3">
                                    <h6>Manage your data</h6>
                                    <p className="pt-3">If you wish to stop your private data from getting processed on {rebrand ? rebrand.name : appName} you can click the button to do so. Please note that doing this will disable your account and you will not be able to use {rebrand ? rebrand.name : appName} further. In order to reactivate your account, you would have to contact our support team at support@vineasx.com. Please Proceed With Caution.</p>
                                    <button className="theme-btn mt-3" onClick={() => handleClick(1)}><span>Stop Processing My Data</span></button>
                                </div>
                                <div className="privacy-block mt-3">
                                    <h6>Manage your data</h6>
                                    <p className="pt-3">If you wish to delete all your private data, you can do so by clicking this button. Please note that this will permanently delete all the data of yours from our server. This step is not reversible and you cannot use {rebrand ? rebrand.name : appName} further. You cannot undo this step from support also. So if you wish to re-use {rebrand ? rebrand.name : appName} again, then you will have to start all over again from scratch. Please Proceed With Caution.</p>
                                    <button className="theme-btn mt-3" onClick={() => handleClick(2)}><span>Delete Your Data</span></button>
                                </div>
                            </div>


                        </div>
                    </div>
                </ScrollPanel>

            </MainMenu>
            {/* 
                    <DashboardFooter />
                </div>
            </div> */}

            <CommonAlert
                show={sweet.enable}
                message={sweet.text}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performAction}
                alertLoader={sweet.loader}
            />
        </>
    )
}

export default Privacy;