import React, { useEffect, useState } from "react";
import AgentHeader from "../AgentDesign/AgentHeader";
import { FaSpinner } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import { fetchData } from "../../../../Redux/Actions/CommonActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CampaignRows from "./CampaignRows";
import CreateCampaignModal from "./CreateCampaignModal";
import Pagination from "../../../Common/Pagination";
import { useImmer } from "use-immer";
import queryString from "query-string";

const LaunchCampaignTab = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    const agentId = queryString.parse(location.search).id
    const agentData = useSelector(state => state.agent.data)
    const extra = useSelector(state => state.extra.data)

    const [loader, setLoader] = useState({
        fetch: true
    })
    const [filterObj, setFilterObj] = useImmer({
        searchKey: "",
        agentType: [],
        agentCategory: []
    })

    const [isCheckedAll, setIsCheckedAll] = useState(false)
    const [checkedArr, setCheckedArr] = useState([])
    const [campShow, setCampShow] = useState([])

    const [agents, setAgents] = useState([])

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });
    const [sortHeader, setSortHeader] = useState({
        startDate: ""
    })

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [campaigns, setCampaigns] = useState([])


    const handleCheckAll = (e) => {
        if (campaigns.length > 0) {
            let arr = []
            if (e.target.checked) {
                campaigns.forEach((curElem) => {
                    arr.push(curElem.id)
                })
                setIsCheckedAll(true)
            } else {
                setIsCheckedAll(false)
                arr = []
            }
            setCheckedArr(arr)
        }
    }

    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = campShow.slice(indexofFirstTodo, indexofLastTodo);



    const handleSort = () => {
        const data = [...campaigns]
        if (sortHeader.startDate) {
            data.sort((a, b) => {
                if (a.startDate.toLowerCase() > b.startDate.toLowerCase()) {
                    return 1
                } else {
                    return -1
                }
            })
            setSortHeader({
                ...sortHeader,
                startDate: false,
            });
        } else {
            data.sort((a, b) => {
                if (a.startDate.toLowerCase() < b.startDate.toLowerCase()) {
                    return 1
                } else {
                    return -1
                }
            })
            setSortHeader({
                ...sortHeader,
                startDate: true,
            });
        }
        setCampaigns(data)
    }



    const fetchCampaigns = () => {
        let data = {
            agentId: agentId
        }
        dispatch(fetchData("list-agent-campaign", data, setCampaigns, loader, setLoader))
    }

    const fetchAgents = () => {
        let data = {}
        dispatch(fetchData("list-agent", data, setAgents, false, false, false, "shortDataLg"))
    }

    useEffect(() => {
        if (checkedArr.length > 0) {
            if (checkedArr.length === campaigns.length) {
                setIsCheckedAll(true)
            } else {
                setIsCheckedAll(false)
            }
        } else {
            setIsCheckedAll(false)
        }
    }, [checkedArr])


    useEffect(() => {
        if (filterObj.searchKey === "" && filterObj.agentType.length === 0 && filterObj.agentCategory.length === 0) {
            setCampShow(campaigns)
        }
        else {
            let dataArr = campaigns.filter((item1) => {
                return item1.name.toLowerCase().includes(filterObj.searchKey.toLowerCase())
            }).filter((curElem) => {
                if (filterObj.agentType.length === 0) {
                    return curElem
                } else {
                    return filterObj.agentType.includes(curElem.agentType.toLowerCase(), 0)
                }
            }).filter((curElem) => {
                if (filterObj.agentCategory.length === 0) {
                    return curElem
                } else {
                    return filterObj.agentCategory.includes(curElem.agentCategory.toLowerCase(), 0)
                }
            })
            setPagination({
                ...pagination,
                currentPage: 1,
                totalItemOnPage: 10
            })
            setCampShow(dataArr)
        }
    }, [filterObj, campaigns, agentId])


    useEffect(() => {
        fetchCampaigns()
        fetchAgents()
    }, [])


    return (
        <>

            <AgentHeader />
            {extra.isKnowledgeData ? null :
                    <div className="alert alert-warning mt-2" role="alert">
                        Please add some data in knowledgebase to have conversation with the agent.
                    </div>
                }
            {campaigns.length === 0 ?
                loader.fetch ?
                    <div className="text-center mt-4">
                        <FaSpinner className="spin" style={{ fontSize: 20, color: "#000" }} />
                    </div>
                    :
                    agentData.agentType === "inbound" ?
                        <div className="table-wrap">
                            <div className="alert alert-info p-4" role="alert">
                                This agent is inbound thus, the campaign is not available.
                            </div>
                        </div> :
                        <div className="content-area-in">
                            <div className="blank-container auto">
                                <div className="blank-container-in">
                                    <h5 className="fw-700 pb-3">No Campaigns with this Agent</h5>
                                    <img src={require("../../../../images/agent.svg").default} alt="" />
                                    <hr />
                                    <h5 className="fw-500 pb-3">You need to create a campaign to <br /> start using your first Agent</h5>
                                    <button className="theme-btn" onClick={handleShow}>+ Create Campaign</button>
                                </div>
                            </div>
                        </div>
                :
                <>
                    {agentData.agentType === "inbound" ?
                        <div className="table-wrap">
                            <div className="alert alert-info p-4" role="alert">
                                This agent is inbound thus, the campaign is not available.
                            </div>
                        </div> :
                        <div className="table-wrap">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6>Campaign List</h6>
                                <div className="campaign-head-right">
                                    <button className="theme-btn" onClick={handleShow}><span>New Campaign</span></button>
                                </div>
                            </div>
                            <div className="table-bar">
                                <div className="table-bar-left">
                                    <div className="table-bar-icon">
                                        <ul>
                                            <li>
                                                <img src={require("../../../../images/icon/plus.svg").default} alt="" />
                                            </li>
                                            <li>
                                                <img src={require("../../../../images/icon/filter.svg").default} alt="" />
                                            </li>
                                            <li onClick={handleSort} title="Sort by start date">
                                                <img src={require("../../../../images/icon/sort.svg").default} alt="" />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="table-bar-right">
                                    <div className="header-search">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            onChange={(e) => setFilterObj({ ...filterObj, searchKey: e.target.value })}
                                        />
                                        <span className="left-icon"><IoSearchOutline /></span>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive mt-3">
                                <table className="table theme-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span className="custom-check">
                                                    <input
                                                        type="checkbox"
                                                        checked={isCheckedAll}
                                                        onChange={handleCheckAll}
                                                    />
                                                    <span className="checkMark"></span>
                                                </span>
                                            </th>
                                            <th>Campaign ID</th>
                                            <th>Campaign Name</th>

                                            {/* <th>Campaign Type</th> */}
                                            <th>Contact List</th>
                                            {location.pathname === "/agent/customize-agent" ? null :
                                                <th>AI Agent </th>}
                                            {/* <th>Duration</th> */}
                                            <th>Status</th>
                                            <th>Start Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentTodo.length > 0 ?
                                            currentTodo.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <CampaignRows
                                                            curElem={curElem}
                                                            campaigns={campaigns}
                                                            setCampaigns={setCampaigns}
                                                            fetchCampaigns={fetchCampaigns}
                                                            checkedArr={checkedArr}
                                                            setCheckedArr={setCheckedArr}
                                                            agents={agents}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={8} className="text-center">
                                                    {loader.fetch ? <FaSpinner className="spin" size={25} /> :
                                                        ""
                                                    }
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <Pagination
                                listData={campShow}
                                pagination={pagination}
                                setPagination={setPagination}
                                currentTodo={currentTodo}
                                listArr={campaigns}
                                loader={loader.fetch}
                            />
                        </div>
                    }

                </>

            }
            <CreateCampaignModal
                show={show}
                handleClose={handleClose}
                fetchCampaigns={fetchCampaigns}
                agents={agents}
                agentId={agentId}
            />
        </>
    )
}

export default LaunchCampaignTab;