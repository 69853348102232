import { produce } from "immer"
const initalState = {
    data: false
}

const AgentReducer = (state = initalState, action) => {
    switch (action.type) {
        case "ADD_AGENT_DATA":
            return produce((state), draft => {
                draft.data = action.payload
            })
        case "ADD_ELEVENLABS_VOICE":
            return produce((state), draft => {
                draft.data = action.payload
            })

        case "UNMOUNT_AGENT":
            return produce((state), draft => {
                draft.data = false
            })

        case "ON_CHANGE_AGENT":
            return produce(state, (draft) => {
                let { name, value } = action.payload

                if (name === "language") {
                    draft.data.langCode = value.code
                    draft.data.accent = value.name.split("(")[1].split(")")[0]
                    draft.data.language = value.name.split(" ")[0]
                }
                else {
                    draft.data = {
                        ...draft.data,
                        [name]: value
                    }
                }

            })

        case "ON_CHANGE_GREETING_AGENT_PLACE":
            return produce(state, (draft) => {
                draft.data = {
                    ...draft.data,
                    greetingMessage: draft.data.greetingMessage.slice(0, action.payload.index) + action.payload.value + draft.data.greetingMessage.slice(action.payload.index, draft.data.greetingMessage.length)
                }

            })


        case "FETCH_WORKFLOW_DATA":
            return produce(state, (draft) => {
                if (draft.data) {
                    draft.data.workflow = action.payload
                }
            })

        case "ON_CHANGE_AGENT_WORKFLOW":
            return produce(state, (draft) => {
                let { name, value, index } = action.payload
                if (name === "type") {
                    draft.data.workflow[index] = {
                        ...draft.data.workflow[index],
                        type: value
                    }
                } else if (name === "calendar") {
                    draft.data.workflow[index].data[0] = {
                        ...draft.data.workflow[index].data[0],
                        [name]: value,
                    }
                    if (draft.data.workflow[index].data[0].apiKey) {
                        delete draft.data.workflow[index].data[0].apiKey
                        delete draft.data.workflow[index].data[0].eventTypeId
                    }
                }
                else {
                    draft.data.workflow[index].data[0] = {
                        ...draft.data.workflow[index].data[0],
                        [name]: value
                    }
                }

                if (name === "functionName" || name === "description") {
                    draft.data.workflow[index] = {
                        ...draft.data.workflow[index],
                        [name]: value
                    }
                }
            })

        case "ON_CREATE_WORKFLOW":
            return produce(state, (draft) => {
                draft.data.workflow[action.payload.index] = {
                    ...draft.data.workflow[action.payload.index],
                    id: action.payload.createdId
                }
            })

        case "ON_ADD_WORKFLOW":
            return produce(state, (draft) => {
                let obj = {
                    type: "",
                    data: []
                }
                draft.data.workflow.unshift(obj)

            })

        case "ON_REMOVE_WORKFLOW":
            return produce(state, (draft) => {
                draft.data.workflow.splice(action.payload, 1)
                if (draft.data.workflow.length === 0) {
                    let obj = {
                        type: "",
                        data: []
                    }
                    draft.data.workflow.unshift(obj)
                }
            })

        case "ON_CHANGE_AGENT_NAME":
            return produce(state, (draft) => {
                draft.data.name = action.payload
            })

        case "UPDATE_AGENT_NUMBER":
            return produce(state, (draft) => {
                draft.data.phoneNumber = action.payload
            })
        default:
            return state
    }
}

export default AgentReducer