import React, { useEffect, useState } from 'react'

const ActiveConversation = (props) => {
    const [data, setData] = useState({
        name: "",
        date: "",
        time: "",
        image: "",
        callRecording: ""
    })

    useEffect(() => {
        if (props.activeConv) {
            setData({
                ...data,
                name: props.activeConv.name,
                image: props.activeConv?.agent[0]?.image,
                date: props.activeConv.created.slice(0, 10),
                time: props.activeConv.created.slice(11, 19),
                callRecording: props.activeConv.recordingUrl
            })
        }

    }, [props.activeConv])

    return (

        <div className="chat-window-heaad">
            <div className="agent-meta">
                {props.activeConv ?
                    <>
                        <div className="agent-meta-img"><img src={data.image} alt="" /></div>
                        <div className="agent-meta-txt">
                            <p>{data.name}</p>
                            {/* <p><small>Date : {time.date} , Time: {time.time} - {data.endTime}</small></p> */}
                            <p><small>Date : {data.date} , Time: {data.time}</small></p>
                        </div>
                    </> : null}
            </div>
            {data.callRecording ?
                <div className="agent-audio">
                    <audio
                        controls
                        src={data.callRecording}
                        type="audio/mpeg"
                    />
                </div> : null}
        </div>
    )
}

export default ActiveConversation