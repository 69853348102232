import React from 'react'

const ResellerLicense = () => {
    return (
        <div className="upgrade-tab-in">
            <div className="upgrade-tab-block">
                <h6>Reseller License</h6>
                <p className="pt-3"> Go to JVZoo Dashboard and request your affiliate link.
                    Once you've requested, submit a ticket with your Ai Sellers
                    Business transaction ID, affiliate ID and
                    email address for our team to activate your reseller
                    license. Please allow upto 2 working days.</p>
            </div>
            <div className="upgrade-tab-block">
                <div className='row text-center'>
                    <div className='col-md-6'>
                        <a
                            className="theme-btn mt-3"
                            href="https://www.jvzoo.com/affiliate/affiliateinfonew/index/410083"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <span>Request Affiliate Link</span>
                        </a>
                    </div>
                    <div className='col-md-6'>
                        <a
                            className="theme-btn mt-3"
                            rel="noreferrer"
                            href="https://support.vineasx.com/"
                            target="_blank"
                        >
                            <span>Raise Support Ticket</span>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ResellerLicense