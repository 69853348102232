import React, { useEffect, useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';
import { useDispatch, useSelector } from "react-redux";
import PhoneNumberBlank from "./PhoneNumberBlank";
import ConnectModal from "./ConnectModal";
import TitleBar from "../Common/TitleBar";
import PhoneNumberRows from "./PhoneNumberRows";
import { fetchSocialAccounts } from "../../Redux/Actions/SocialActions";
import { FaSpinner } from "react-icons/fa";
import MainMenu from "../Common/MainMenu";

const PhoneNumber = () => {
    const dispatch = useDispatch()
    const twilio = useSelector(state => state.social.twilio)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loader, setLoader] = useState(true)

    const fetchSocials = () => {
        dispatch(fetchSocialAccounts(setLoader))

    }

    useEffect(() => {
        fetchSocials()
    }, [])

    return (
        <>
            <TitleBar title="Phone Number" />
            <MainMenu>
                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">
                        {twilio.length === 0 ?
                            loader ?
                                <div className="text-center">
                                    <FaSpinner className="spin" style={{ fontSize: 20 }} />
                                </div>
                                :
                                <PhoneNumberBlank
                                    handleShow={handleShow}
                                />
                            :
                            <>
                                <div className="page-title d-flex justify-content-between align-items-center">
                                    <div>Phone Numbers</div>
                                    <button className="theme-btn" onClick={handleShow}>+ Connect Your Twilio Account </button>
                                </div>

                                <div className="table-wrap mt-3">
                                    <div className="table-responsive">
                                        <table className="table theme-table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Type</th>
                                                    <th>Created</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {twilio.length > 0 ?
                                                    twilio.map((curElem, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <PhoneNumberRows
                                                                    curElem={curElem}
                                                                    fetchSocials={fetchSocials}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    :
                                                    ""
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </ScrollPanel>
            </MainMenu>
            <ConnectModal
                show={show}
                handleClose={handleClose}
            />
        </>
    )
}

export default PhoneNumber;