import React, { useEffect, useState } from "react";

import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { AiOutlineClose, AiFillCheckCircle } from "react-icons/ai";
import { FaRegUserCircle, FaRegEdit, FaSpinner } from "react-icons/fa";
import { PiChatTeardropDots } from "react-icons/pi";

import { IoCalendarClearOutline, IoSearchOutline, IoPlay, IoCallOutline } from "react-icons/io5";
import { FiChevronRight, FiChevronLeft, FiCalendar, FiUpload } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { onCallBackRules, onFetchRules } from "../../../Redux/Actions/CampaignActions";
import { fetchData } from "../../../Redux/Actions/CommonActions";
import RulesRows from "./RulesRows";
import { Calendar } from "primereact/calendar";
import { useImmer } from "use-immer"
const AgentCallback = ({ id }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const campaign = useSelector(state => state.campaign.data)
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [action, setAction] = useState([])
    const [rules, setRules] = useImmer([
        {
            campaignId: campaign.id,
            allowCallBack: "",
            allowFollowUp: "",
            maxAttempts: 1,
            preferredTime: ""

        }
    ])



    const handleChnage = (e, index) => {
        let { name, value } = e.target
        setRules((draft) => {
            draft[index] = {
                ...draft[index],
                [name]: value
            }
        })
    }



    const fetchRules = () => {
        let data = {
            campaignId: id
        }
        dispatch(onFetchRules(data, setRules, loader, setLoader))
    }

    const fetchActionsData = () => {
        dispatch(fetchData("fetch-webhook-action", {}, setAction))
    }

    const handleAdd = () => {
        if (rules.length < 2) {
            let obj = {
                campaignId: campaign.id,
                allowCallBack: "",
                allowFollowUp: "",
                maxAttempts: 1,
                preferredTime: ""
            }
            setRules((draft) => {
                draft.unshift(obj)
            })
        }
    }

    useEffect(() => {
        if (id) {
            fetchRules()
        }
        fetchActionsData()
    }, [id])


    return (
        <>
            <div className="d-flex justify-content-between align-items-center my-2">
                <h6>Setup rules for Callbacks and Follow-Ups</h6>
                {+auth.user.isClientAccount === 1 ? "" :
                    <button className="theme-btn" onClick={handleAdd}> + Add More</button>
                }
            </div>

            {rules.length > 0 ?
                rules.map((curElem, index) => {
                    return (
                        <React.Fragment key={index}>
                            <RulesRows
                                curElem={curElem}
                                handleChnage={handleChnage}
                                fetchRules={fetchRules}
                                index={index}
                                action={action}
                                rules={rules}
                                setRules={setRules}
                                auth={auth}
                            />
                        </React.Fragment>
                    )
                }) :
                <div className="text-center mt-4">
                    {loader.fetch ?
                        <FaSpinner className="spin" style={{ fontSize: 20, color: "#000" }} /> :
                        <h6>No Data Found</h6>}
                </div>
            }


            {/* <h6 className="pt-4">Scheduled Callback and Followup calls</h6> */}

            {/* <div className="account-block mt-4">

                <div className="table-bar mt-0">
                    <div className="table-bar-left">
                        <div className="table-bar-icon">
                            <ul>
                                <li><img src={require("../../../images/icon/plus.svg").default} alt="" /></li>
                                <li><img src={require("../../../images/icon/filter.svg").default} alt="" /></li>
                                <li><img src={require("../../../images/icon/sort.svg").default} alt="" /></li>
                            </ul>
                        </div>
                    </div>
                    <div className="table-bar-right">
                        <div className="header-search">
                            <input type="text" placeholder="Search" />
                            <span className="left-icon"><IoSearchOutline /></span>
                        </div>
                    </div>
                </div>

                <div className="table-responsive mt-3">
                    <table className="table theme-table">
                        <thead>
                            <tr>
                                <th>
                                    <span className="custom-check">
                                        <input type="checkbox" />
                                        <span className="checkMark"></span>
                                    </span>
                                </th>
                                <th>Prev. Call ID</th>
                                <th>Customer Name</th>
                                <th>Contact Number</th>
                                <th>Reason</th>
                                <th>Date</th>
                                <th>Duration</th>
                                <th>Recording</th>
                                <th>Transcript</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td>
                                    <span className="custom-check">
                                        <input type="checkbox" />
                                        <span className="checkMark"></span>
                                    </span>
                                </td>
                                <td><a href="">#129801</a></td>
                                <td>Landing Page</td>
                                <td>+(111)576-900</td>
                                <td>Didn’t respond</td>
                                <td>6/2/23, 5:00PM</td>
                                <td>00:05:24</td>
                                <td><IoPlay /> 0:00 / 0:42</td>
                                <td><a href="">Read Transcript</a></td>
                            </tr>

                            <tr>
                                <td>
                                    <span className="custom-check">
                                        <input type="checkbox" />
                                        <span className="checkMark"></span>
                                    </span>
                                </td>
                                <td><a href="">#129801</a></td>
                                <td>Landing Page</td>
                                <td>+(111)576-900</td>
                                <td>Didn’t respond</td>
                                <td>6/2/23, 5:00PM</td>
                                <td>00:05:24</td>
                                <td><IoPlay /> 0:00 / 0:42</td>
                                <td><a href="">Read Transcript</a></td>
                            </tr>

                        </tbody>
                    </table>
                </div>

            </div> */}





            {/* <div className="table-paging">
                <ul>
                    <li><FiChevronLeft /></li>
                    <li className="active">1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li><FiChevronRight /></li>
                </ul>
            </div> */}

        </>
    )
}

export default AgentCallback;