import React, { useEffect, useState } from "react";
import { LiaHeadsetSolid } from "react-icons/lia";
import { FiPlusCircle, FiUpload } from "react-icons/fi";
import AgentHeader from "../AgentDesign/AgentHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../../Redux/Actions/CommonActions";
import VoiceRows from "./VoiceRows";
import { FaSpinner } from "react-icons/fa";
import Pagination from "../../../Common/Pagination";
import VoiceFilterModal from "./VoiceFilterModal";
import VoiceClone from "./VoiceClone";

const AgentSettingsTab = () => {
    const dispatch = useDispatch()
    const agent = useSelector(state => state.agent.data)
    const extra = useSelector(state => state.extra.data)

    const [voices, setVoices] = useState([])
    const [voicesShow, setVoicesShow] = useState([])
    const [selectedPlay, setSelectedPlay] = useState(false)
    const [show, setShow] = useState(false)
    const [type, setType] = useState("lg")
    const handleClose = () => setShow(false)

    const [loader, setLoader] = useState({
        fetch: true
    })

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });

    const [filterObj, setFilterObj] = useState({
        lang: "All",
        gender: "All",
        accent: "All",
        tone: "All"
    })

    const handleSelectAll = () => {
        setFilterObj({
            lang: "All",
            gender: "All",
            accent: "All",
            tone: "All"
        })
    }

    const handleShow = (val) => {
        setShow(true)
        setType(val)
    }

    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = voicesShow.slice(indexofFirstTodo, indexofLastTodo);


    useEffect(() => {
        if (agent.language) {
            setFilterObj({
                ...filterObj,
                lang: agent.language,
                gender: agent.gender,

            })
        }
    }, [agent.language])



    useEffect(() => {
        if (voices.length > 0) {
            let dataArr
            if (filterObj.lang === "All" && filterObj.gender === "All" && filterObj.tone === "All" && filterObj.accent === "All") {
                dataArr = voices
            } else {
                dataArr = voices.filter((curElem) => {

                    return (filterObj.lang.includes(curElem?.language) || filterObj.lang === "All") &&
                        (curElem.gender?.toLowerCase() === filterObj.gender.toLowerCase() || filterObj.gender === "All") &&
                        (curElem.tone?.toLowerCase() === filterObj.tone.toLowerCase() || filterObj.tone === "All") &&
                        (curElem?.accent.includes(filterObj.accent) || filterObj.accent === "All")
                })
            }
            setPagination({
                ...pagination,
                currentPage: 1,
                totalItemOnPage: 10
            })
            setVoicesShow(dataArr.filter((curElem) => {
                return curElem.url && curElem.image
            }))
        }
    }, [filterObj, voices])


    const fetchVoices = () => {
        let data = {}
        dispatch(fetchData("fetch-voices", data, setVoices, loader, setLoader))
    }

    useEffect(() => {
        fetchVoices()
    }, [])

    return (
        <>
            <AgentHeader />
            {extra.isKnowledgeData ? null :
                    <div className="alert alert-warning mt-2" role="alert">
                        Please add some data in knowledgebase to have conversation with the agent.
                    </div>
                }
            <div className="row">
                <div className="col-lg-7 col-12">
                    <div className="account-block">
                        <div className="title-flex justify-content-start gap-1">
                            <span className="theme-icon large"><LiaHeadsetSolid /></span>
                            <h6>Select a Voice</h6>
                        </div>
                        <h6 className="font-light pt-2">Select a voice for your AI agent.</h6>
                        <div className="agent-opt">
                            <ul>
                                <li onClick={handleSelectAll}>All</li>
                                <li onClick={() => handleShow("lg")}><FiPlusCircle />{filterObj.lang !== "All" ? filterObj.lang : "Language"} </li>
                                <li onClick={() => handleShow("ge")}><FiPlusCircle /> {filterObj.gender !== "All" ? filterObj.gender : "Gender"}</li>
                                <li onClick={() => handleShow("ac")}><FiPlusCircle />{filterObj.accent !== "All" ? filterObj.accent : "Accent"} </li>
                                <li onClick={() => handleShow("to")}><FiPlusCircle /> {filterObj.tone !== "All" ? filterObj.tone : "Tone"}</li>
                            </ul>
                        </div>

                        <div className="table-wrap mt-4">
                            <div className="table-responsive">
                                <table className="table theme-table">
                                    <thead>
                                        <tr>
                                            <th>Voice Name</th>
                                            <th>Tone</th>
                                            <th>Language</th>
                                            <th>Gender</th>
                                            <th>Accent</th>
                                            <th className="text-end">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentTodo.length > 0 ?
                                            currentTodo.map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <VoiceRows
                                                            curElem={curElem}
                                                            agent={agent}
                                                            setSelectedPlay={setSelectedPlay}
                                                            selectedPlay={selectedPlay}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <tr className='text-center my-4'>
                                                <td colSpan={6}>
                                                    {loader.fetch ?
                                                        <FaSpinner className='spin' style={{ fontSize: 25, color: "#000" }} />
                                                        : ""}
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>

                            </div>
                            <Pagination
                                listData={voicesShow}
                                pagination={pagination}
                                setPagination={setPagination}
                                currentTodo={currentTodo}
                                listArr={voices}
                                loader={loader.fetch}
                            />
                        </div>
                    </div>
                </div>
                <VoiceClone />
            </div>
            <VoiceFilterModal
                show={show}
                handleClose={handleClose}
                setFilterObj={setFilterObj}
                filterObj={filterObj}
                type={type}
            />
        </>
    )
}

export default AgentSettingsTab;