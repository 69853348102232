import React from 'react'
import { onConnectGoogle, saveYoutube } from '../../../Redux/Actions/SocialActions'
import GoogleLogin from 'react-google-login'
import { useDispatch, useSelector } from 'react-redux'
import ConnectionRows from '../ConnectionRows'
import { baseURL } from '../../../Global/Global'

const GoogleCalender = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const google = useSelector(state => state.social.google)
    // const responseGoogle = (response) => {
    //     if (response.code) {
    //         let data = { code: response.code, user_id: auth.user.id }
    //         dispatch(saveYoutube(data))
    //     } else {
    //         console.log(response);
    //     }
    // }

    const handleConnect = () => {
        window.open(`${baseURL}google-calender-connect/${auth.user.id}`, "_blank")
    }

    return (
        <div className="col-lg-12">
            <div className="integration-box">
                <div className="integration-box-icon"><img src={require("../../../images/icon/google-calendar.svg").default} alt="" /></div>
                <div className="integration-box-txt">
                    <h6>Google Calendar</h6>
                    <p>Automate tasks and workflows across multiple apps with Google Calendar</p>
                </div>
                <div className="integration-btn">
                    <button className="theme-btn light" onClick={handleConnect}>Connect</button>
                </div>

            </div>
            <div className='row'>
                {google.length > 0 ?
                    google.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ConnectionRows
                                    curElem={curElem}
                                />
                            </React.Fragment>
                        )
                    })
                    : ""}
            </div>
        </div>
    )
}

export default GoogleCalender