import React from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpinner } from 'react-icons/fa'
import { IoDownloadOutline } from 'react-icons/io5'

const CommonAlert = (props) => {
    return (
        <Modal className="theme-modal small-modal" show={props.show} backdrop="static" centered>
            <Modal.Body>
                {/* <span className="modal-close" onClick={handleClose}><AiOutlineClose /></span> */}
                <div className="text-center">
                    <div className="info-icon"><IoDownloadOutline /></div>
                </div>
                <p className="pt-4 text-center">{props.message}</p>
                <div className="btn-group mt-4 justify-content-center">
                    <button
                        className="theme-btn light"
                        onClick={props.handleClose}
                        style={{
                            background: props.cancelButtonColor
                        }}
                    >
                        <span>{props.cancelButtonName}</span>
                    </button>
                    <button
                        className="theme-btn"
                        onClick={props.performDelete}
                        disabled={props.alertLoader}
                        style={{
                            background: props.confirmButtonColor
                        }}
                    >
                        <span>{props.confirmButtonName} {props.alertLoader ? <FaSpinner className='spin'/> : null}</span>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CommonAlert