import React from "react";
import { Link, NavLink, } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi2";
import { useSelector } from "react-redux";
import logoD from "../../images/logo-dark.svg"
import logoL from "../../images/logo-light.png"
const SidePanel = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    const auth = useSelector(state => state.auth)
    return (
        <>
            <div className={`side-panel`}>
                <div className="logo"><Link to={'/dashboard'}><img src={rebrand ? rebrand.logo : logoD} alt="" /></Link></div>
                {/* <div className="link-single mt-4">
                  <NavLink to="/dashboard"><img src={require("../../images/icon/dashboard-icon-2.svg").default} alt="" /> Dashboards</NavLink>
                </div> */}
                <div className="dash-link pt-0">
                    <ul>
                        <li><NavLink to="/dashboard"><HiOutlineChevronRight /> <img src={require("../../images/icon/dashboard-icon.svg").default} alt="" /> Dashboard</NavLink></li>
                        <li><NavLink to="/agent"><HiOutlineChevronRight /> <img src={require("../../images/icon/agents-icon-2.svg").default} alt="" /> Agents</NavLink></li>

                        <li><NavLink to="/call-history"><HiOutlineChevronRight /> <img src={require("../../images/icon/call-history-icon.svg").default} alt="" /> Call History</NavLink></li>
                        {+auth.user.isClientAccount === 1 ? "" :
                            <li><NavLink to="/phone-number"><HiOutlineChevronRight /> <img src={require("../../images/icon/phone-icon.svg").default} alt="" /> Phone Numbers</NavLink></li>
                        }
                        {/* <li><NavLink to="/api-keys"><HiOutlineChevronRight /> <img src={require("../../images/icon/key-icon.svg").default} alt="" /> API Keys</NavLink></li> */}
                        {/* <li><NavLink to="/webhook"><HiOutlineChevronRight /> <img src={require("../../images/icon/integration-icon.svg").default} alt="" /> Webhooks</NavLink></li> */}
                        {+auth.user.isClientAccount === 1 ? "" :
                            <li><NavLink to="/integrations"><HiOutlineChevronRight /> <img src={require("../../images/icon/webhooks-icon.svg").default} alt="" /> Integrations</NavLink></li>
                        }
                        <li><NavLink to="/campaigns"><HiOutlineChevronRight /> <img src={require("../../images/icon/campaign-icon.svg").default} alt="" /> Campaigns</NavLink></li>
                        {/* <li><NavLink to="/subscriptions"><HiOutlineChevronRight /> <img src={require("../../images/icon/subscription-icon.svg").default} alt="" /> Subscriptions</NavLink></li> */}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SidePanel;