export const onToggleMenu = (val) => (dispatch) => {
    dispatch({ type: "TOOGLE_SIDE_MENU", payload: val })
}

export const onAddRephrase = (val) => (dispatch) => {
    dispatch({ type: "ADD_REPHRASE_DATA", payload: val })
}


export const onChangeRephrase = (val) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_REPHRASE", payload: val })
}

export const onUpdateKnowledgeStatus = (val) => (dispatch) => {
    dispatch({type: "UPDATE_KNOWLEDGE_STATUS", payload: val})
}