import React, { useEffect, useState } from 'react'
import { onChangeAgent, onSaveAgent } from '../../../../Redux/Actions/AgentActions'
import { useDispatch } from 'react-redux'
import { FaSpinner } from 'react-icons/fa'
import { MdOutlinePlayCircle, MdOutlineStopCircle } from 'react-icons/md'
import { themeColor } from '../../../../Global/Global'

let music = false

const VoiceRows = (props) => {
    const dispatch = useDispatch()
    const [play, setPlay] = useState(false)

    const [loader, setLoader] = useState({
        save: false
    })
    const handleSelectVoice = () => {
        setLoader({
            ...loader,
            save: true
        })
        let data = { ...props.agent, voice: { type: "default", data: props.curElem } }
        dispatch(onSaveAgent(data, loader, setLoader, "updateRedux"))
    }


    const handlePlay = () => {
        if (props.curElem.url) {
            props.setSelectedPlay(props.curElem.url)
            if (music !== false) {
                music.pause()
                setPlay(false)
            }
            setLoader({
                ...loader,
                playRecording: true
            })
            music = new Audio(props.curElem.url)
            music.play()
                .then(r => {
                    setLoader({
                        ...loader,
                        playRecording: false
                    })
                    setPlay(true)
                }
                )
                .catch(err => console.log(err));

            music.onended = () => {
                setPlay(false)
            }
        }
    }

    const handlePause = () => {
        if (music !== false) {
            music.pause()
            setPlay(false)
            music = false
            props.setSelectedPlay(false)
        }
    }

    useEffect(() => {
        return () => {
            if (music !== false) {
                music.pause();
            }
        }
    }, [])

    return (
        <tr>
            <td>
                <span className="userImg">
                    <img src={props.curElem.image} alt="" />
                </span>
                {props.curElem.voiceName}
            </td>
            <td>{props.curElem.tone}</td>
            <td>{props.curElem.language}</td>
            <td>{props.curElem.gender}</td>
            <td>{props.curElem.accent}</td>
            <td className="text-end d-flex align-items-center justify-content-end">
                <span className={`theme-btn me-1 ${props.agent.voice?.type === "default" ? +props.agent.voice?.data?.id === +props.curElem.id ? "" : "light" : "light"}`} onClick={handleSelectVoice}>
                    {loader.save ? <>Selecting <FaSpinner className='spin' size={12} /> </> :
                        props.agent.voice?.type === "default" ? +props.agent.voice?.data?.id === +props.curElem.id ?
                            "Selected" : "Select" : "Select"
                    }
                </span>
                {
                    props.curElem.url ?
                        play && (props.selectedPlay === props.curElem.url) ?
                            <MdOutlineStopCircle style={{ color: themeColor, fontSize: "23px", cursor: "pointer" }} title='Stop' onClick={handlePause} /> :
                            loader.playRecording ? <FaSpinner className='spin' style={{ color: themeColor, fontSize: "23px" }} /> :
                                <MdOutlinePlayCircle style={{ color: themeColor, fontSize: "23px", cursor: "pointer" }} title='Play' onClick={handlePlay} />
                        : null}
            </td>
        </tr>
    )
}

export default VoiceRows