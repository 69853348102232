import { produce } from "immer"
const initialState = {
    data: false,
    overviewData: {
        dials: 0,
        pickups: 0,
        hungUp: 0,
        noResponse: 0,
        talkTime: 0,
        pickupRate: 0,
        avgCallDuration: 0,
        chartData: []
    }
}

export const CampaignReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_CAMAPIGN_DATA":
            return produce(state, (draft) => {
                draft.data = action.payload
            })

        case "UNMOUNT_CAMPAIGN":
            return produce(state, (draft) => {
                draft.data = false
            })

        case "CAMPAIGN_STATISTICS":
            return produce(state, (draft) => {
                let callCompleted = 0
                let obj = {
                    dials: 0,
                    pickups: 0,
                    hungUp: 0,
                    noResponse: 0,
                    talkTime: 0,
                    pickupRate: 0,
                    avgCallDuration: 0,
                    chartData: [{
                        completeRate: 0,
                        responseRate: 0,
                        hungupRate: 0
                    }]
                }
                action.payload.forEach((curElem) => {
                    obj.dials += +curElem.isDial === 1 ? 1 : 0
                    obj.pickups += +curElem.isPickup === 1 ? 1 : 0
                    obj.hungUp += +curElem.duration <= 10 && +curElem.isPickup === 1 ? 1 : 0
                    obj.talkTime += +curElem.duration
                    if (+curElem.isDial === 1 && +curElem.isPickup === 1 && +curElem.conversationId !== 0 && curElem.recording && +curElem.duration > 10) {
                        callCompleted += 1
                    }
                })
                obj.noResponse = obj.dials - obj.pickups
                let avgTime = (obj.talkTime / action.payload.length)
                obj.avgCallDuration = `${parseInt(avgTime / 60)} minute ${parseInt(avgTime % 60)} seconds`
                obj.pickupRate = `${(obj.pickups / action.payload.length).toFixed(2) * 100}%`

                obj.chartData[0].responseRate = parseInt(((obj.noResponse) / action.payload.length) * 100)
                obj.chartData[0].hungupRate = parseInt((obj.hungUp / action.payload.length) * 100)
                obj.chartData[0].completeRate = parseInt((callCompleted / action.payload.length) * 100)
                obj.talkTime = `${parseInt(obj.talkTime / 60)} minute ${parseInt(obj.talkTime % 60)} seconds`
                draft.overviewData = obj
            })
        default:
            return state
    }
}