import React from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'

const OptionModal = (props) => {
    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={props.handleClose}><AiOutlineClose /></span>
                <div className="modal-content">
                    <h3 className="text-center">How do you want to add your info?</h3>
                    <p className="text-center pt-2">Don't worry, you can always change the info later</p>

                    <div className="row pt-4">
                        <div className="col-lg-4">
                            <div className="add-info-block cursor-pointer" onClick={props.handleShowWeb}>
                                <img src={require("../../images/icon/book.svg").default} alt="" />
                                <h6 className="pt-2">Connect Webpage</h6>
                                <p>We will automatically extract  the information from one or more urls.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="add-info-block cursor-pointer" onClick={props.handleShowUpload}>
                                <img src={require("../../images/icon/bulb.svg").default} alt="" />
                                <h6 className="pt-2">Upload File</h6>
                                <p>We will automatically extract info from a PDF, DOC,TXT or Video file.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="add-info-block cursor-pointer" onClick={props.handleShowManual}>
                                <img src={require("../../images/icon/edit.svg").default} alt="" />
                                <h6 className="pt-2">Type Manually</h6>
                                <p>You can manually type in the information.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default OptionModal