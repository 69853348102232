import React, { useEffect, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import ActiveConversation from "../Agent/UpdateAgent/UnansweredQuestion/ActiveConversation";
import MessagesSection from "../Agent/UpdateAgent/UnansweredQuestion/MessagesSection";
import { fetchData } from "../../Redux/Actions/CommonActions";
import { useDispatch } from "react-redux";
import MessagesFooter from "../Agent/UpdateAgent/UnansweredQuestion/MessagesFooter";


const TranscriptTab = ({ activeConv, messages, fetchCallAnalysis }) => {

   return (
      <>
         <div className="chat-window-main">
            <ActiveConversation
               activeConv={activeConv}
            />
            <div className="chat-area">
               <MessagesSection
                  messages={messages}
               />
            </div>
            <div className="chat-window-footer">
               <MessagesFooter
                  fetchCallAnalysis={fetchCallAnalysis}
               />
            </div>
         </div>
      </>
   )
}
export default TranscriptTab;