import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { onEditClient } from '../../Redux/Actions/AccountManagements'
import { useDispatch } from 'react-redux'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'

const EditAccount = ({ show, handleClose, user, fetchMembers, type }) => {

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [details, setDetails] = useState({
        id: "",
        name: "",
        email: "",
        password: ""
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setDetails({
            ...details,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (details.password) {
            if (pattern.test(details.password)) {
                setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                setLoader(true);
                dispatch(onEditClient(details, fetchMembers, handleClose, setLoader))
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        } else {
            setLoader(true);
            dispatch(onEditClient(details, fetchMembers, handleClose, setLoader))
        }
    }


    useEffect(() => {
        setDetails({
            ...details,
            name: user.name,
            email: user.email,
            id: user.id
        })
    }, [user])

    useEffect(() => {
        if (!show) {
            setPasswordMsg({
                ...passwordMsg,
                msg: "",
                validation: false
            })
        }
    }, [show])

    return (
        <Modal className="theme-modal small-modal" show={show} onHide={handleClose} backdrop="static" centered>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <span className="modal-close" onClick={handleClose}><AiOutlineClose /></span>
                    <h5 className="pb-3">Edit {type} {type === "Team" ? "Member" : ""}</h5>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="simple-input">
                                <label className="label" htmlFor="">Account Type</label>
                                <input
                                    className="input"
                                    type="text"
                                    value={type}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="simple-input">
                                <label className="label" htmlFor="">Account Name</label>
                                <input
                                    className="input"
                                    type="text"
                                    name='name'
                                    value={details.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="simple-input">
                                <label className="label" htmlFor="">Email</label>
                                <input
                                    className="input"
                                    type="email"
                                    name='email'
                                    value={details.email}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="simple-input">
                                <label className="label" htmlFor="">Password</label>
                                <input
                                    className="input"
                                    type="password"
                                    value={details.password}
                                    name='password'
                                    onChange={handleChange}
                                />
                            </div>
                            <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>

                        </div>
                    </div>
                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type="button" onClick={handleClose}><span>Cancel</span></button>
                        <button className="theme-btn" type='submit'><span>{loader ? <>Updating <FaSpinner className='spin' size={14} /></> : "Update"}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default EditAccount