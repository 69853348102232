import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import { IoCalendarClearOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import CommonAlert from '../Common/CommonAlert'
import { useDispatch } from 'react-redux'
import { deleteSocialAccounts } from '../../Redux/Actions/SocialActions'

const PhoneNumberRows = ({ curElem }) => {
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Disconnect",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Disconnect",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Disconnecting",
            loader: true
        })
        dispatch(deleteSocialAccounts("delete-twilio", data, setSweet))
    }


    return (
        <tr>
            <td>{curElem.name}</td>
            <td className='text-capitalize'>{curElem.type}</td>
            <td><IoCalendarClearOutline /> {curElem.created}</td>

            <td className="text-end">
                <span className="profile-menu">
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic"><BiDotsHorizontalRounded /></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Link to={`/phone-number/phone-number-list?id=${curElem.id}`}><FaRegEdit />Edit</Link>
                            <Link onClick={onDelete}><FaRegTrashAlt />Delete</Link>
                        </Dropdown.Menu>
                    </Dropdown>
                </span>
            </td>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to disconnect this connection?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />

        </tr>
    )
}

export default PhoneNumberRows