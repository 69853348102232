import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onConnectTwilio } from '../../Redux/Actions/AgentActions'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

const ConnectModal = ({ show, handleClose, fetchData }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState({
        save: false
    })
    const [data, setData] = useState({
        accountSid: "",
        authToken: "",
        twilioNumber: "",
        toNumber: "",
        type: "twilio",
        name: "Twilio"
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = { ...data }
        setLoader({
            ...loader,
            save: true
        })
        dispatch(onConnectTwilio(obj, navigate, loader, setLoader))
    }

    return (
        <Modal className="theme-modal" show={show} onHide={handleClose} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={handleClose}><AiOutlineClose /></span>
                <form className="modal-content" onSubmit={handleSubmit}>
                    <h3 className="text-center">Connect your twilio account is just one step away </h3>
                    <p className="text-center pt-2">You can access all your phone numbers in twilio account once your account is connected</p>
                    <div className="modal-card mt-4">
                        <h6>Account Information</h6>
                        <div className="input-wrap">
                            <label className="label" htmlFor=""
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Enter your Twilio Account SID here. This unique identifier links your Twilio account with the AI calling platform"
                            >Account SID</label>
                            <input
                                className="input"
                                type="text"
                                name="accountSid"
                                placeholder='77adaqe3asn a23xxxq3232342asdnsajoq23jo23ljasej213412'
                                value={data.accountSid}
                                onChange={handleChange}
                                required
                            />

                        </div>
                        <div className="input-wrap">
                            <label className="label" htmlFor=""
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Enter your Twilio Auth Token here. This token is used to authenticate and secure communication between your Twilio account and our platform"
                            >Account Token</label>
                            <input
                                className="input"
                                type="text"
                                name="authToken"
                                placeholder='awuy1232177adaqe3asn a23xxxq3232342asdnsajoq23jo23ljasej213412'
                                value={data.authToken}
                                onChange={handleChange}
                                required
                            />

                        </div>
                        <div className="input-wrap">
                            <label className="label" htmlFor=""
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Provide the Twilio phone number you want to use for calls. This number will be used for both inbound and outbound communications"
                            >Twilio Number</label>
                            <input
                                className="input"
                                type="text"
                                name="twilioNumber"
                                placeholder='Enter Twilio Number'
                                value={data.twilioNumber}
                                onChange={handleChange}
                                required
                            />

                        </div>
                        <div className="input-wrap">
                            <label className="label" htmlFor=""
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Enter a valid phone number where a test SMS will be sent. This number is required to validate the connection between your Twilio account and our platform"
                            >To Number</label>
                            <input
                                className="input"
                                type="text"
                                name="toNumber"
                                placeholder='Enter To Number'
                                value={data.toNumber}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type='button' onClick={handleClose}><span>Cancel</span></button>
                        <button className="theme-btn" type='submit'><span>{loader.save ? <>Saving <FaSpinner className='spin' /></> : "Save Changes"}</span></button>
                    </div>
                    <Tooltip id="my-tooltip" place="top-start" clickable />
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ConnectModal