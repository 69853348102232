import React, { useState } from "react";
import { onChangeAgentWorkflow, onCreateWorkflow, onUpdateWorkflow } from "../../../../Redux/Actions/AgentActions";
import { useDispatch } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import ContentEditable from "react-contenteditable";
import { setAlert } from "../../../../Redux/Actions/AlertActions";
const WorkflowAutomationCustomFunction = ({ handleChange, index, curElem, agentId }) => {

    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        create: false
    })

    const validateJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    const handleSubmit = (e) => {
        e.preventDefault()

        let isJson = false
        if (curElem.data[0]?.json) {
            isJson = validateJson(curElem.data[0]?.json)
        }
        if (isJson) {
            setLoader({
                ...loader,
                create: true
            })
            if (curElem.id) {
                let obj = { ...curElem }
                dispatch(onUpdateWorkflow(obj, loader, setLoader))
            } else {
                let obj = { ...curElem, agentId }
                dispatch(onCreateWorkflow(obj, index, loader, setLoader))
            }
        } else {
            dispatch(setAlert("Please provide a valid JSON!", "danger"))
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="input-wrap">
                <label className="label" htmlFor="">Function Name</label>
                <input
                    className="input"
                    type="text"
                    name="functionName"
                    placeholder="Enter Function Name Here"
                    value={curElem.data[0]?.functionName}
                    onChange={(e) => handleChange(e, index)}
                    required
                />
            </div>

            <div className="input-wrap">
                <label className="label" htmlFor="">Description</label>
                <input
                    className="input"
                    type="text"
                    name="description"
                    placeholder="Enter Description Here"
                    value={curElem.data[0]?.description}
                    onChange={(e) => handleChange(e, index)}
                    required
                />
            </div>

            <div className="input-wrap">
                <label className="label" htmlFor="">Your URL</label>
                <input
                    className="input"
                    type="url"
                    name="url"
                    placeholder="Enter URL Here"
                    value={curElem.data[0]?.url}
                    onChange={(e) => handleChange(e, index)}
                    required
                />
            </div>

            <div className="input-wrap">
                <label className="label" htmlFor="">Parameter ( JSON schema that defines the format in which the LLM will return. Please refer to the docs )</label>
                <textarea
                    className="code-tab mt-2"
                    style={{ width: "100%", border: "none" }}
                    value={curElem.data[0]?.json}
                    name="json"
                    onChange={(e) => handleChange(e, index)}
                />
            </div>

            {/* <div className="input-wrap">
                <div className="input-head">
                    <label className="label" htmlFor="">Speak During Execution ( If unchecked, AI will speak after completing the execution )</label>
                    <span className="custom-switch">
                        <input
                            type="checkbox"
                            name="speakDuringExecution"
                            checked={curElem.data[0]?.speakDuringExecution}
                            onChange={(e) => handleChange(e, index)}
                        />
                        <span className="checkMark"></span>
                    </span>
                </div>
                {curElem.data[0]?.speakDuringExecution ?
                    <input
                        className="input"
                        type="text"
                        name="message"
                        value={curElem.data[0]?.message}
                        onChange={(e) => handleChange(e, index)}
                        required
                        placeholder="Write The message you will say to callee when calling this tool. Make sure it fits into the conversation smoothly."

                    /> : null}
            </div> */}
            <div className="btn-group mt-4">
                <button className="theme-btn" type="submit"><span>
                    {loader.create ? <>{curElem.id ? "Updating" : "Creating"} <FaSpinner className="spin" /></> : curElem.id ? "Update" : "Create"}
                </span></button>
            </div>
        </form>
    )
}

export default WorkflowAutomationCustomFunction;