import React, { useEffect, useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import { ScrollPanel } from 'primereact/scrollpanel';
import CampaignOverview from "./Overview/CampaignOverview";
import AgentConfiguration from "./AgentConfiguration";
import AgentCallback from "./CallBacks/AgentCallback";
import CampaignAnalytics from "./CampaignAnalytics";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { onFetchCampaign, unmountCampaign } from "../../Redux/Actions/CampaignActions";
import { unmountAgent } from "../../Redux/Actions/AgentActions";
import MainMenu from "../Common/MainMenu";
import TitleBar from "../Common/TitleBar";

const CampaignDetails = () => {
   const dispatch = useDispatch()
   const location = useLocation()
   const navigate = useNavigate()
   const { id } = queryString.parse(location.search)


   const [loader, setLoader] = useState({
      fetch: true
   })
   
   const fetchCampaigns = () => {
      let data = { id }
      dispatch(onFetchCampaign(data, loader, setLoader))
   }

   useEffect(() => {
      if (id) {
         fetchCampaigns()

         return () => {
            dispatch(unmountCampaign())
            dispatch(unmountAgent())
         }
      }
   }, [id])

   return (
      loader.fetch ?
         <div className="loader-sec">
            <div className="loader" />
         </div>
         :
         <>
            <TitleBar title={location.pathname === "/agent/campaign-details" ? "Customize Agent" : "Customize Campaign"} />
            <MainMenu>

               <ScrollPanel className="custombar2 content-area">
                  <div className="content-area-in tab-style">
                     <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                        <div className="tab-bar">
                           <Nav variant="pillsb">
                              <Nav.Item><Nav.Link eventKey="tab-1">Overview</Nav.Link></Nav.Item>
                              {/* <Nav.Item><Nav.Link eventKey="tab-2">Agent Configuration</Nav.Link></Nav.Item> */}
                              <Nav.Item><Nav.Link eventKey="tab-3">Callbacks and Follow-Ups</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-4">Campaign Analytics</Nav.Link></Nav.Item>
                           </Nav>
                        </div>

                        <Tab.Content>

                           <Tab.Pane eventKey="tab-1">
                              <CampaignOverview
                                 id={id}
                              />
                           </Tab.Pane>

                           {/* <Tab.Pane eventKey="tab-2">
                              <AgentConfiguration
                              />
                           </Tab.Pane> */}

                           <Tab.Pane eventKey="tab-3">
                              <AgentCallback
                                 id={id}
                              />
                           </Tab.Pane>

                           <Tab.Pane eventKey="tab-4">
                              <CampaignAnalytics
                              />
                           </Tab.Pane>

                        </Tab.Content>

                     </Tab.Container>
                  </div>
               </ScrollPanel>

            </MainMenu>
         </>
   )
}

export default CampaignDetails;