import React, { useState } from 'react'
import TitleBar from '../Common/TitleBar'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import queryString from "query-string";
import { resetPassword } from '../../Redux/Actions/AuthActions';
import { IoEyeOffOutline } from 'react-icons/io5';
import { FaSpinner } from 'react-icons/fa';
import Footer from '../Common/Footer';
import { appName } from '../../Global/Global';
import logoD from "../../images/logo-dark.svg"
import logoL from "../../images/logo-light.png"
import AuthLogoHeader from './AuthLogoHeader';
const ResetPassword = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    let location = useLocation()
    const [view1, setView1] = useState(false)
    const [view2, setView2] = useState(false)

    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (loginInfo.password) {
            if (pattern.test(loginInfo.password)) {
                if ((loginInfo.password === loginInfo.confirm_password)) {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                    setLoader(true);
                    dispatch(resetPassword(loginInfo, setLoader, navigate));
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }


    return (
        <>
            <TitleBar title="Reset Password" />
            <AuthLogoHeader />

            <div className="login-wrap">
                <div className="login-left">
                <div className="login-left-in">
          <h2 className="pb-3">Welcome To {appName}</h2>
          <p>Transform your business with AI-driven callers that enhance your productivity and sales. Dive into the future of customer interactions with {appName} - where technology meets seamless communication</p>
          </div>
                </div>
                <form className="login-right" onSubmit={handleSubmit}>
                    <div className="login-right-main">
                        <h3>Reset Password</h3>
                        <div className="login-input-wrap">
                            <label htmlFor="">New Password</label>
                            <div className="input-logo">
                                <input
                                    className="login-input"
                                    type={view1 ? "text" : "password"}
                                    name="password"
                                    placeholder='***********'
                                    onChange={handleChange}
                                    required
                                />
                                <span
                                    onMouseUp={() => setView1(false)}
                                    onMouseLeave={() => setView1(false)}
                                    onMouseDown={() => setView1(true)}
                                    className="inp-icon"
                                >
                                    <IoEyeOffOutline />
                                </span>
                            </div>
                        </div>

                        <div className="login-input-wrap">
                            <label htmlFor="">Confirm Password</label>
                            <div className="input-logo">
                                <input
                                    className="login-input"
                                    type={view2 ? "text" : "password"}
                                    name="confirm_password"
                                    placeholder='***********'
                                    onChange={handleChange}
                                    required
                                />
                                <span
                                    onMouseUp={() => setView2(false)}
                                    onMouseLeave={() => setView2(false)}
                                    onMouseDown={() => setView2(true)}
                                    className="inp-icon"
                                >
                                    <IoEyeOffOutline />
                                </span>
                            </div>
                            <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>

                        </div>
                        <div className="login-input-wrap">
                            <button type="submit" className="login-btn ">Reset Password {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                        </div>
                        <div className="login-input-wrap text-center">
                            <p>Login? <Link to="/login">Click here</Link></p>
                        </div>
                    </div>
                </form>
            </div>

            <Footer />
        </>
    )
}

export default ResetPassword