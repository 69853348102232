import React, { useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onCreateWorkflow, onUpdateWorkflow } from '../../../../Redux/Actions/AgentActions'

const WorkflowEmail = ({ handleChange, index, curElem, agentId }) => {

    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        create: false
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        if (curElem.id) {
            let obj = { ...curElem }
            dispatch(onUpdateWorkflow(obj, loader, setLoader))
        } else {
            let obj = { ...curElem, agentId }
            dispatch(onCreateWorkflow(obj, index, loader, setLoader))
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="input-wrap">
                <label className="label" htmlFor="">Function Name</label>
                <input
                    className="input"
                    type="text"
                    name="functionName"
                    placeholder='Enter Function Name Here'
                    value={curElem.data[0]?.functionName}
                    onChange={(e) => handleChange(e, index)}
                    required

                />
            </div>
            <div className="input-wrap">
                <label className="label" htmlFor="">Description</label>
                <input
                    className="input"
                    type="text"
                    name="description"
                    placeholder="End the call if the user uses goodbye phrases such as 'bye,' 'goodbye,' or 'have a nice day"
                    value={curElem.data[0]?.description}
                    onChange={(e) => handleChange(e, index)}
                    required
                />
            </div>
            <div className="input-wrap">
                <label className="label" htmlFor="">Email</label>
                <input
                    className="input"
                    type="email"
                    name="email"
                    placeholder="Enter the email"
                    value={curElem.data[0]?.email}
                    onChange={(e) => handleChange(e, index)}
                    required
                />
            </div>
            <div className="btn-group mt-4">
                <button className="theme-btn" type="submit"><span>
                    {loader.create ? <>{curElem.id ? "Updating" : "Creating"} <FaSpinner className="spin" /></> : curElem.id ? "Update" : "Create"}
                </span></button>
            </div>
        </form>
    )
}

export default WorkflowEmail