import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AgentHeader from "../AgentDesign/AgentHeader";
import OptionModal from "../../../Common/OptionModal";
import UploadModal from "../../../Common/UploadModal";
import WebpageModal from "../../../Common/WebpageModal";
import { fetchData } from "../../../../Redux/Actions/CommonActions";
import DataRows from "./DataRows";
import { FaSpinner } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ManualModal from "../../../Common/ManualModal";
import { onUpdateKnowledgeStatus } from "../../../../Redux/Actions/ExtraActions";

const KnowledgeBase = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    let date = new Date()
    const { id } = queryString.parse(location.search)
    const agent = useSelector(state => state.agent.data)
    const extra = useSelector(state => state.extra.data)
    const [data, setData] = useState([])
    const [transLang, setTransLang] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);


    const fetchKnowlegeData = () => {
        let obj = { id: id }
        dispatch(fetchData("fetch-all-data", obj, setData, loader, setLoader))
    }

    const fetchTransLanguages = () => {
        dispatch(fetchData("transcribe-language", {}, setTransLang, false, false, false, "shortDataLg"))

        // dispatch(onFetchTransLanguage(setTransLang))
    }


    useEffect(() => {
        if (data.length > 0) {
            dispatch(onUpdateKnowledgeStatus(true))
        } else {
            if (!loader.fetch) {
                dispatch(onUpdateKnowledgeStatus(false))
            }
        }
    }, [data])

    useEffect(() => {
        fetchKnowlegeData()
        fetchTransLanguages()
    }, [])

    return (
        <>

            <AgentHeader />

            <div className="account-block">
                <h6>Give your agent context</h6>
                {extra.isKnowledgeData ? null :
                    <div className="alert alert-warning mt-2" role="alert">
                        Please add some data in knowledgebase to have conversation with the agent.
                    </div>
                }
                <div className="input-wrap">
                    <label className="label" htmlFor="">What information should your agent have when answering the phone?</label>
                    <div className="row">
                        <div className="col-lg-12 mt-2">
                            <div className="training-opt">
                                <div className="training-opt-title">
                                    <img src={require("../../../../images/icon/business.svg").default} alt="" />
                                    <h6>Business Background</h6>
                                </div>
                                <h5>Showcase your vision, background, <br /> history to the AI agent</h5>
                                <p className="text-center">{date.toDateString()}, {date.toLocaleTimeString()}</p>
                                <div className="text-center mt-4"><button className="theme-btn light" onClick={handleShow}><span>+ Add Info</span></button></div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 mt-2">
                            <div className="training-opt">
                                <div className="training-opt-title">
                                    <img src={require("../../../../images/icon/webhooks.svg").default} alt="" />
                                    <h6>Scripts</h6>
                                </div>
                                <h5>Add scripts for the AI agent to use in <br /> conversations</h5>
                                <p className="text-center">Jun 1, 2024, 01:00:36 PM</p>
                                <div className="text-center mt-4"><button className="theme-btn light" onClick={handleShow}><span>+ Add Info</span></button></div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                            <div className="training-opt">
                                <div className="training-opt-title">
                                    <img src={require("../../../../images/icon/api-keys.svg").default} alt="" />
                                    <h6>Connect Your Own LLM</h6>
                                </div>
                                <h5>Add your own custom LLM Model prompt <br /> to the AI agent.</h5>
                                <p className="text-center">Jun 1, 2024, 01:00:36 PM</p>
                                <div className="text-center mt-4"><button className="theme-btn light" onClick={handleShow}><span>+ Add Info</span></button></div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="account-block mt-4">
                <div className="table-responsive">
                    <h6>Knowlege Data</h6>
                    <div className="input-wrap">
                        <table className="table theme-table my-3">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Content Type</th>
                                    <th>Process Type</th>
                                    <th>File Type</th>
                                    <th>View</th>
                                    <th>Status</th>
                                    <th>Created</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 ?
                                    data.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <DataRows
                                                    curElem={curElem}
                                                    index={index}
                                                    data={data}
                                                    setData={setData}
                                                    fetchData={fetchKnowlegeData}
                                                />
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={7} className="text-center">
                                            {loader.fetch ?
                                                <FaSpinner className="spin" style={{ fontSize: 20, color: "#000" }} /> : "You have not data in knowledgebase yet!"}
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>



            <OptionModal
                show={show}
                handleClose={handleClose}
                handleShowUpload={handleShow3}
                handleShowWeb={handleShow2}
                handleShowManual={handleShow4}
            />

            <ManualModal
                show={show4}
                handleClose={handleClose4}
                closeMain={handleClose}
                fetchData={fetchKnowlegeData}
                id={agent.id}
            />

            <WebpageModal
                show={show2}
                handleClose={handleClose2}
                closeMain={handleClose}
                fetchData={fetchKnowlegeData}
                id={agent.id}
            />

            <UploadModal
                show={show3}
                fetchData={fetchKnowlegeData}
                closeMain={handleClose}
                handleClose={handleClose3}
                id={agent.id}
                transLang={transLang}
            />
        </>
    )
}

export default KnowledgeBase;