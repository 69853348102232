import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateAgent = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-agent", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/agent/customize-agent?id=${res.data}`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                create: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onSaveAgent = (data, loader, setLoader, type) => (dispatch, getState) => {
    commonAxios("update-agent-design", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                if (type) {
                    dispatch(onChangeAgent("voice", data.voice))
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                save: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}



export const onDuplicateAgent = (data, fetchData, handleClose, setName, loader, setLoader) => (dispatch, getState) => {
    commonAxios("duplicate-agent", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setName("")
                fetchData()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                duplicate: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                duplicate: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onFetchAgent = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-agent", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ADD_AGENT_DATA", payload: res.data })
            } else {
                // navigate('/agent')
                // dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}



export const onCheckStatus = (data, fetchData, interval) => (dispatch, getState) => {
    commonAxios("check-status", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let status = +res.data[0].status
                if (status === 2 || status === 3) {
                    fetchData()
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onSaveScript = (data, fetchData, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-knowledge-data", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
            setLoader({
                ...loader,
                save: false
            })
        })
}






export const onAskCopilot = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("ask-copilot", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(onChangeAgent("prompt", res.data))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                copilot: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                copilot: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })
}


export const onScrapWebsites = (data, fetchData, closeMain, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-knowledge", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                handleClose()
                closeMain()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }

            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                save: false
            })
        })
}

export const onExtractFile = (data, fetchData, closeMain, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("extract-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                closeMain()
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submit: false
            })
        })
}

export const onCreateManual = (data, fetchData, closeMain, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-manual", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                handleClose()
                closeMain()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                save: false
            })
        })
}


export const fetchWorkflowAll = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("list-workflow", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "FETCH_WORKFLOW_DATA", payload: res.data })
            } else {
                dispatch({
                    type: "FETCH_WORKFLOW_DATA", payload: [{
                        type: "",
                        data: []
                    }]
                })
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                fetch: false
            })
            console.log(err)
        })
}


export const onCreateVoiceClone = (data, fetchCamp, setFileData, setName, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios("create-clone-voice", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setFileData({
                    url: "",
                    type: "",
                    localPath: ""
                })
                setName("")
                fetchCamp()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}
export const onSaveVoiceClone = (data, fetchCamp, setName, handleClose, setLoader, setSelectedVoice) => (dispatch, getState) => {
    commonAxios("save-voice", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setName("")
                setSelectedVoice("")
                fetchCamp()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}


export const onCreateCampaign = (data, fetchCamp, setData, handleClose, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                setData({
                    name: "",
                    campaignType: "inbound",
                    startDate: "",
                    endDate: "",
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    dailyBudget: "",
                    url: "",
                    agentId: ""
                })
                fetchCamp()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                save: false
            })
        })
}

export const onConnectTwilio = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("connect-twilio", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/phone-number/phone-number-list?id=${res.data}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                save: false
            })
        })
}

export const onUpdateNumber = (data, navigate, setLoader, ifAgent) => (dispatch, getState) => {
    commonAxios("update-agent-number", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (ifAgent) {
                    dispatch(onUpdateAgentNumber(data.phoneNumber))
                } else {
                    navigate(`/phone-number`)
                }
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}

export const onUnlinkNumber = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("unlink-agent-number", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/phone-number`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
        })
}



export const onGenrateBearer = (data, state, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("generate-bearer-token", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState({
                    ...state,
                    bearerToken: res.data
                })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                generate: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                generate: false
            })
        })
}




export const onCreateWebHook = (url, data, fetchData, handleClose, setData, loader, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData({
                    webHookAction: "",
                    webHookUrl: "",
                    bearerToken: ""
                })
                fetchData()
                handleClose()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                save: false
            })
        })
}


export const onFetchTransLanguage = (setData) => (dispatch, getState) => {
    commonAxios("transcribe-language", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = res.data
                arr.sort((a, b) => {
                    if (a.language > b.language) {
                        return 1
                    } else {
                        return -1
                    }
                })
                setData(arr.map((curElem) => {
                    return curElem.language
                }))
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onCreateWorkflow = (obj, index, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-workflow", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_CREATE_WORKFLOW", payload: { createdId: res.data, index } })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                create: false
            })
            console.log(err)
        })
}
export const onUpdateWorkflow = (obj, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-workflow", obj, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "error"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "error"))
            setLoader({
                ...loader,
                create: false
            })
            console.log(err)
        })
}

export const onDeleteWorkflow = (sweet, setSweet) => (dispatch, getState) => {
    let data = { id: sweet.id.wfId }
    commonAxios("delete-workflow", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_REMOVE_WORKFLOW", payload: sweet.id.index })
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
        })


}


export const onFetchConversation = (data, setState, activeConv, setActiConv, loader, setLoader) => (dispatch, getState) => {
    commonAxios("unanswered-questions", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
                if (activeConv) {
                    let obj = res.data.find(({ id }) => +id === +activeConv.id)
                    if (obj) {
                        setActiConv(obj)
                    }
                } else {
                    setActiConv(res.data[0])
                }
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                fetch: false
            })
        })
}




export const onFetchAnalysis = (data, setState, activeConv, setActiConv, loader, setLoader) => (dispatch, getState) => {
    commonAxios("call-analysis", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
                if (activeConv) {
                    let obj = res.data.find(({ id }) => +id === +activeConv.id)
                    if (obj) {
                        setActiConv(obj)
                    }
                } else {
                    setActiConv(res.data[0])
                }
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                fetch: false
            })
        })
}

export const onRephraseData = (data, fetchCallAnalysis) => (dispatch, getState) => {
    commonAxios("rephrase-message", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchCallAnalysis()
                dispatch({ type: "UNMOUNT_REPHRASE" })
            }
        }).catch((err) => {
            console.log(err)
            dispatch(setAlert(err.msg, "danger"))

        })
}







// No Axios Here


export const onChangeAgent = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_AGENT", payload: { name, value } })
}

export const onChangeGreeting = (index, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_GREETING_AGENT_PLACE", payload: { index, value } })
}



export const unmountAgent = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_AGENT" })
}

export const onChangeAgentWorkflow = (name, value, index) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_AGENT_WORKFLOW", payload: { name, value, index } })
}

export const onAddWorkflow = () => (dispatch) => {
    dispatch({ type: "ON_ADD_WORKFLOW" })
}

export const onRemoveWorkflow = (index) => (dispatch) => {
    dispatch({ type: "ON_REMOVE_WORKFLOW", payload: index })
}

export const onChangeAgentName = (val) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_AGENT_NAME", payload: val })
}

export const onUpdateAgentNumber = (num) => (dispatch) => {
    dispatch({ type: "UPDATE_AGENT_NUMBER", payload: num })
}