import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { onConnectElevenLabs, onConnectInte } from '../../Redux/Actions/SocialActions'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { Tooltip } from 'react-tooltip'

const ConnectModal = ({ show, handleClose, type, url }) => {
    const FormDataJson = require('form-data-json-convert')
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        submit: false
    })
    const [state, setState] = useState({
        name: "",
        apiKey: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            submit: true
        })
        let obj = { ...state }
        if (type === "Autoresponder") {
            delete obj.apiKey
            let element = document.getElementById("auto-form")
            let form = element.getElementsByTagName("form")
            let formData = FormDataJson.toJson(document.querySelector("#auto-form"), { includeDisabled: true, includeButtonValues: true, arrayify: true })
            formData.postUrl = form[0].action
            obj = {
                ...obj,
                name: obj.name,
                code: obj,
            }
        }
        console.log(obj)
        dispatch(onConnectInte(url, obj, handleClose, loader, setLoader))
    }

    return (
        <Modal className="theme-modal" show={show} onHide={handleClose} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={handleClose}><AiOutlineClose /></span>
                <form className="modal-content" onSubmit={handleSubmit}>
                    <h3 className="text-center">Connect {type}</h3>
                    {/* <p className="text-center pt-2">Don't worry, you can obviously always later change the information given</p> */}
                    <div className="modal-card mt-4">
                        <div className="input-wrap">
                            <label className="label" htmlFor=""
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="Provide a name for this connection to identify it easily within your account. This name is for your reference and can be anything you choose."
                            >Enter Name</label>
                            <input
                                className="input"
                                type="text"
                                name="name"
                                placeholder="Enter Name"
                                value={state.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        {type === "ElevenLabs" ?
                            <div className="input-wrap">
                                <label className="label" htmlFor=""

                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Enter your ElevenLabs API Key here. This key is required to authenticate and enable access to ElevenLabs' text-to-speech services."
                                >Enter API key</label>
                                <input
                                    className="input"
                                    type="text"
                                    name="apiKey"
                                    placeholder="Enter API key"
                                    value={state.apiKey}
                                    onChange={handleChange}
                                    required
                                />
                            </div> :
                            <div className="input-wrap">
                                <label className="label" htmlFor="" data-tooltip-id="my-tooltip-optin"
                                    data-tooltip-content="Enter the unique opt-in code provided by your autoresponder service. This code allows you to automatically subscribe users who opt-in to your communication list"
                                >Opt-in Code</label>
                                <textarea
                                    className="input"
                                    type="text"
                                    name="code"
                                    placeholder="Enter Code Here"
                                    value={state.code}
                                    rows={5}
                                    onChange={handleChange}
                                    required
                                />
                                <Tooltip id="my-tooltip-optin" place="top-start" clickable />
                            </div>
                        }

                    </div>
                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type='button' onClick={handleClose}><span>Cancel</span></button>
                        <button className="theme-btn" type='submit'><span>{loader.submit ? <>Connecting <FaSpinner className='spin' /> </> : "Connect"}</span></button>
                    </div>
                    <Tooltip id="my-tooltip" place="top-start" clickable />
                </form>
                <div id='auto-form' style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: state.code }} />
            </Modal.Body>
        </Modal>
    )
}

export default ConnectModal