import React, { useEffect, useState } from "react";

import { FaRegUserCircle, FaRegEdit, FaSpinner } from "react-icons/fa";
import { PiChatTeardropDots } from "react-icons/pi";
import { IoCalendarClearOutline, IoSearchOutline } from "react-icons/io5";
import { LiaHeadsetSolid } from "react-icons/lia";
import { FiPlusCircle, FiChevronRight, FiChevronLeft, FiUpload } from "react-icons/fi";

import { SketchPicker } from 'react-color';
import rgbHex from "rgb-hex";


import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from "react-redux";
import { onFetchFont, onUploadMedia } from "../../../../Redux/Actions/CommonActions";
import Swal from "sweetalert2";
import { onSaveAgent } from "../../../../Redux/Actions/AgentActions";
import WidgetBox from "./WidgetBox";
import { setAlert } from "../../../../Redux/Actions/AlertActions";

const AgentWidget = () => {
    const dispatch = useDispatch()
    const agent = useSelector(state => state.agent.data)
    const extra = useSelector(state => state.extra.data)
    const autoresponder = useSelector(state => state.social.autoresponder)

    const [loader, setLoader] = useState({
        upload: false,
        widget: false
    })
    const [percent, setPercent] = useState(0)
    const [colorShow, setColorShow] = useState(false)
    const [image, setImage] = useState("")
    const [fonts, setFonts] = useState([])
    const [state, setState] = useState({
        name: "",
        image: "",
        theme: "Light Theme",
        brandColor: "#110000ff",
        fontName: "unset",
        buttonName: "Submit",
        cardSize: { label: "Medium Card", width: 300, height: 500 },
        isFloat: false,
        isCollapse: false
    })

    const themes = [
        "Dark Theme",
        "Light Theme"
    ];

    const sizes = [
        { label: "Small Card", width: 250, height: 450 },
        { label: "Medium Card", width: 300, height: 500 },
        { label: "Large Card", width: 400, height: 600 }
    ];

    const handleChange = (e) => {
        let { name, value, checked } = e.target
        if (name === "isFloat" || name === "isCollapse") {
            value = checked
        }
        setState({
            ...state,
            [name]: value
        })
    }

    const onCompeleteUpload = (url) => {
        setImage(url)
    }

    const uploadFile = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 50000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    const onInputColor = (e) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        setState({
            ...state,
            brandColor: hex
        })
    }

    const onSubmitWidget = () => {
        let widgets = { ...state }
        widgets = {
            ...widgets,
            image: image
        }
        let obj = { ...agent, widgets }

        if (obj.widgets.name !== "") {
            setLoader({
                ...loader,
                widget: true
            })
            dispatch(onSaveAgent(obj, loader, setLoader))
        } else {
            dispatch(setAlert("Please provide a agent display name to continue!", "danger"))
        }
    }

    useEffect(() => {
        if (agent.widgets) {
            setState({ ...state, ...agent.widgets })
            setImage(agent.widgets.image)
        } else {
            setState({
                ...state,
                name: agent.name,
                image: agent.image
            })
            setImage(agent.image)
        }
    }, [agent.widgets])

    const fetchFonts = () => {
        dispatch(onFetchFont(setFonts))
    }
    useEffect(() => {
        fetchFonts()
    }, [])
    return (
        <>

            <div className="agent-widget">

                <div className="agent-widget-left">
                    <div className="account-block">
                        <h6>Setup your agent widget</h6>
                        {extra.isKnowledgeData ? null :
                            <div className="alert alert-warning mt-2" role="alert">
                                Please add some data in knowledgebase to have conversation with the agent.
                            </div>
                        }
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Agent Display Name</label>
                                    <input
                                        className="input"
                                        type="text"
                                        name="name"
                                        value={state.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="uploader left">
                                    <div className="uploader-icon">
                                        {loader.upload ?
                                            <FaSpinner className='spin' /> :
                                            image ?
                                                <img src={image} alt='' style={{ height: "100%", width: "100%", borderRadius: "50%" }} />
                                                :
                                                <FiUpload />
                                        }

                                    </div>
                                    <div className="uploader-txt">
                                        <h6>Upload File</h6>
                                        <p>Click here to upload image for the widget</p>
                                    </div>
                                    <input type="file" onChange={uploadFile} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Theme</label>
                                    <Dropdown
                                        value={state.theme}
                                        onChange={handleChange}
                                        options={themes}
                                        placeholder="Select Theme"
                                        className="input"
                                        name="theme"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-wrap" style={{ position: "relative" }}>
                                    <label className="label" htmlFor="">Brand Color</label>
                                    <div style={{ position: "relative" }}>
                                        <input className="input" type="text" value={state.brandColor} readOnly />
                                        <span style={{
                                            width: 50,
                                            height: "100%",
                                            background: state.brandColor,
                                            top: 0,
                                            right: 0,
                                            borderRadius: 5,
                                            position: "absolute",
                                            border: "1px solid #b3b0b0",
                                            cursor: "pointer"
                                        }}
                                            onClick={() => setColorShow(true)}
                                        />
                                    </div>
                                    {colorShow ?
                                        <>
                                            <div className="swatch-cover"
                                                onClick={() => setColorShow(false)}
                                            />
                                            <SketchPicker
                                                color={state.brandColor} onChange={(e) => onInputColor(e)}
                                            />
                                        </>
                                        : null}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Font Name</label>
                                    <Dropdown
                                        value={state.fontName}
                                        onChange={handleChange}
                                        options={fonts}
                                        placeholder="Select Font"
                                        className="input"
                                        name="fontName"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Card Size  </label>
                                    <Dropdown
                                        value={state.cardSize}
                                        onChange={handleChange}
                                        options={sizes}
                                        optionLabel="label"
                                        placeholder="Select Font"
                                        className="input"
                                        name="cardSize"
                                    />
                                </div>
                            </div>
                            {agent.agentType === "outbound" ?
                                <div className="col-lg-6">
                                    <div className="input-wrap">
                                        <label className="label" htmlFor="">Button Name </label>
                                        <input
                                            className="input"
                                            type="text"
                                            name="buttonName"
                                            value={state.buttonName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                </div> : null}
                            <div className="col-lg-6">
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Autoresponder  </label>
                                    <Dropdown
                                        value={state.autoresponder}
                                        onChange={handleChange}
                                        options={autoresponder}
                                        optionLabel="name"
                                        placeholder="Select Autoresponder"
                                        className="input"
                                        name="autoresponder"
                                    />
                                </div>
                            </div>
                            {/* <div className="col-lg-6">
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Allow widget to float</label>
                                    <div className="inp-group">
                                        <span className="custom-switch">
                                            <input
                                                type="checkbox"
                                                checked={state.isFloat}
                                                name="isFloat"
                                                onChange={handleChange}
                                            />
                                            <span className="checkMark"></span>
                                            <span>Yes</span>
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-6">
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Show name when collapsed</label>
                                    <div className="inp-group">
                                        <span className="custom-switch">
                                            <input
                                                type="checkbox"
                                                checked={state.isCollapse}
                                                name="isCollapse"
                                                onChange={handleChange}
                                            />
                                            <span className="checkMark"></span>
                                            <span>Yes</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-4">
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Expand on page load</label>
                                    <div className="inp-group">
                                        <input
                                            type="radio"
                                            className="checkbox"
                                            name="widgetWindowType"
                                            value={"expend"}
                                            checked={state.widgetWindowType === "expend"}
                                            onChange={handleChange}

                                        />
                                       
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="btn-group mt-4">
                            <button type="button" className="theme-btn" onClick={onSubmitWidget}>
                                <span>
                                    {loader.widget ? <>Saving <FaSpinner className="spin" /></> : "Save"}
                                </span>
                            </button>
                        </div>
                    </div>

                </div>
                <WidgetBox
                    state={state}
                    image={image}
                />


            </div>
        </>
    )
}

export default AgentWidget;