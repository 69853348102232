import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../Common/Footer";

import { IoEyeOffOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../Redux/Actions/AuthActions";
import TitleBar from "../Common/TitleBar";
import { FaSpider, FaSpinner } from "react-icons/fa";
import { appName } from "../../Global/Global";

import AuthLogoHeader from "./AuthLogoHeader";

const Login = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth)
  const [view, setView] = useState(false)
  const [loader, setLoader] = useState(false)
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: ""
  })

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/dashboard')
    }
  }, [auth])


  const handleChange = (e) => {
    const { name, value } = e.target
    setLoginInfo({
      ...loginInfo,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoader(true)
    dispatch(loginUser(loginInfo, setLoader))
  }

  return (
    <>
      <TitleBar title="Login" />
      <AuthLogoHeader />

      <div className="login-wrap">
        <div className="login-left">
          <div className="login-left-in">
            <h2 className="pb-3">Welcome To {appName}</h2>
            <p>Transform your business with AI-driven callers that enhance your productivity and sales. Dive into the future of customer interactions with {appName} - where technology meets seamless communication</p>
          </div>
        </div>
        <form className="login-right" onSubmit={handleSubmit}>
          <div className="login-right-main">
            <h3>Sign in to {appName}</h3>
            <div className="login-input-wrap">
              <label htmlFor="">Email Address</label>
              <input
                className="login-input"
                type="email"
                placeholder="Email Address"
                value={loginInfo.email}
                onChange={handleChange}
                name="email"
                required
              />
            </div>
            <div className="login-input-wrap">
              <label htmlFor="">Password</label>
              <div className="input-logo">
                <input
                  className="login-input"
                  type={view ? "text" : "password"}
                  placeholder="Password"
                  required
                  onChange={handleChange}
                  value={loginInfo.password}
                  name="password"
                />
                <span
                  onMouseUp={() => setView(false)}
                  onMouseLeave={() => setView(false)}
                  onMouseDown={() => setView(true)}
                  className="inp-icon"
                >
                  <IoEyeOffOutline />
                </span>
              </div>
            </div>
            <div className="login-input-wrap">
              <button type="submit" className="login-btn ">Sign In {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
            </div>
            <div className="login-input-wrap text-center">
              <p>Forgot your account details? <Link to="/forgot-password">Click here</Link></p>
            </div>
          </div>
        </form>
      </div>

      <Footer />
    </>
  )
}

export default Login;