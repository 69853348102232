import React, { useEffect, useRef, useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onCreateWorkflow, onUpdateWorkflow } from '../../../../Redux/Actions/AgentActions'
import intlTelInput from 'intl-tel-input'
let iti = null
const WorkflowSMS = ({ handleChange, index, curElem, agentId }) => {

    const dispatch = useDispatch()
    const inputRef = useRef(null);
    const [loader, setLoader] = useState({
        create: false
    })


    const handleSubmit = (e) => {
        e.preventDefault()
        if (iti) {
            setLoader({
                ...loader,
                create: true
            })
            if (curElem.id) {
                let obj = { ...curElem }
                obj = JSON.parse(JSON.stringify(obj))
                obj.data[0] = {
                    ...obj.data[0],
                    transferTo: iti.getNumber()
                }
                dispatch(onUpdateWorkflow(obj, loader, setLoader))
            } else {
                let obj = { ...curElem, agentId }
                obj = JSON.parse(JSON.stringify(obj))
                obj.data[0] = {
                    ...obj.data[0],
                    transferTo: iti.getNumber()
                }
                dispatch(onCreateWorkflow(obj, index, loader, setLoader))
            }
        }
    }


    const handleKeyPress = (e) => {
        console.log(e, "....fsfds")
        const charCode = e.which ? e.which : e.keyCode;
        console.log(charCode)
        if ((charCode < 48 || charCode > 57) && charCode !== 8) {
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (inputRef.current) {
            iti = intlTelInput(inputRef.current, {
                initialCountry: "us",
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.1/js/utils.js",
            });

            return () => {
                if (iti) {
                    iti.destroy();
                }
            }
        }
    }, [inputRef]);
    return (
        <form onSubmit={handleSubmit}>
            <div className="input-wrap">
                <label className="label" htmlFor="">Function Name</label>
                <input
                    className="input"
                    type="text"
                    name="functionName"
                    placeholder='Enter Function Name Here'
                    value={curElem.data[0]?.functionName}
                    onChange={(e) => handleChange(e, index)}
                    required

                />
            </div>
            <div className="input-wrap">
                <label className="label" htmlFor="">Description</label>
                <input
                    className="input"
                    type="text"
                    name="description"
                    placeholder="End the call if the user uses goodbye phrases such as 'bye,' 'goodbye,' or 'have a nice day"
                    value={curElem.data[0]?.description}
                    onChange={(e) => handleChange(e, index)}
                    required
                />
            </div>
            <div className="input-wrap">
                <label className="label" htmlFor="">Phone Number</label>
                <input
                    ref={inputRef}
                    className="input"
                    type="text"
                    name="phone"

                    value={curElem.data[0]?.phone || ''}
                    pattern="\d{1,15}"
                    maxLength={15}
                    onChange={(e) => handleChange(e, index)}
                    onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, '').slice(0, 15);
                        handleChange(e, index);
                    }}
                    onKeyDown={handleKeyPress}
                    required
                />

            </div>
            <div className="btn-group mt-4">
                <button className="theme-btn" type="submit"><span>
                    {loader.create ? <>{curElem.id ? "Updating" : "Creating"} <FaSpinner className="spin" /></> : curElem.id ? "Update" : "Create"}
                </span></button>
            </div>
        </form>
    )
}

export default WorkflowSMS