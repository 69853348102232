import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { FiPhone } from 'react-icons/fi'
import { IoFilter, IoSearchOutline } from 'react-icons/io5'

const Conversations = ({ conv, loader, activeConv, setActiveConv, setMessages }) => {

    const [filterObj, setFilterObj] = useState({
        q: ""
    })
    const handleActiveConv = (data) => {
        setActiveConv(data)
    }

    useEffect(() => {
        if (activeConv) {
            setMessages(activeConv.messages)
        }
    }, [activeConv])

    return (
        <ScrollPanel className="chat-name">
            <div className="chat-name-search">

                <div className="profile-menu">

                    <Dropdown>
                        <Dropdown.Toggle variant="successN" className="drop-btn">
                            <span className="theme-icon"><IoFilter /></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <li className="drop-list">
                                <span className="custom-check">
                                    <input
                                        type="checkbox"
                                        name="first"
                                    // checked={col.first}
                                    // onChange={(e) => handleFilter(e, "checked")}
                                    />
                                    <span className="checkMark"></span>
                                </span>
                                Filter by Date
                            </li>
                            <li className="drop-list">
                                <span className="custom-check">
                                    <input
                                        type="checkbox"
                                        name="second"
                                    // checked={col.second}
                                    // onChange={(e) => handleFilter(e, "checked")}
                                    />
                                    <span className="checkMark"></span>
                                </span>
                                Call Completion
                            </li>
                            <li className="drop-list">
                                <span className="custom-check">
                                    <input
                                        type="checkbox"
                                        name="third"
                                    // checked={col.third}
                                    // onChange={(e) => handleFilter(e, "checked")}
                                    />
                                    <span className="checkMark"></span>
                                </span>
                                Task Completion
                            </li>
                            <li className="drop-list">
                                <span className="custom-check">
                                    <input
                                        type="checkbox"
                                        name="forth"
                                    // checked={col.forth}
                                    // onChange={(e) => handleFilter(e, "checked")}
                                    />
                                    <span className="checkMark"></span>
                                </span>
                                Disconnect Reason
                            </li>
                            <li className="drop-list">
                                <span className="custom-check">
                                    <input
                                        type="checkbox"
                                        name="forth"
                                    // checked={col.forth}
                                    // onChange={(e) => handleFilter(e, "checked")}
                                    />
                                    <span className="checkMark"></span>
                                </span>
                                Filter by Campaign
                            </li>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="header-search">
                    <input
                        type="text"
                        placeholder="Search"
                        value={filterObj.q}
                        onChange={(e) => setFilterObj({
                            ...filterObj,
                            q: e.target.value
                        })}
                        readOnly={conv.length === 0}
                    />
                    <span className="left-icon"><IoSearchOutline /></span>
                </div>
            </div>
            <div className="agentList">
                {conv.length > 0 ?
                    conv.filter((curElem) => {
                        if (curElem.agent && curElem.agent.length > 0) {
                            return curElem.agent[0].name.toLowerCase().includes(filterObj.q.toLowerCase())
                        } else {
                            return curElem
                        }
                    }).map((curElem, index) => {
                        return (
                            <div
                                className={`agentList-single ${activeConv.id === curElem.id ? "active" : ""}`}
                                key={index}
                                title={curElem.id}
                                onClick={() => handleActiveConv(curElem)}
                            >
                                <div className="agentList-icon"><FiPhone /></div>
                                <div className="agentList-txt">
                                    <p>{curElem.agent[0]?.name}</p>
                                    <p><small>{curElem.created}</small></p>
                                </div>
                            </div>
                        )
                    })
                    :

                    <div className='text-center my-4'>
                        No conversation available yet!
                    </div>

                }

                {/* <div className="agentList-single active">
                    <div className="agentList-icon"><FiPhone /></div>
                    <div className="agentList-txt">
                        <p>Test Agent, James Davis</p>
                        <p><small>6/1/2024  06:30</small></p>
                    </div>
                </div> */}

            </div>
        </ScrollPanel>
    )
}

export default Conversations