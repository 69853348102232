import React, { useEffect, useState } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import { IoSearchOutline } from "react-icons/io5";
import { fetchData, fetchDataPrimereact } from "../../../Redux/Actions/CommonActions";
import AgentRows from "./AgentRows";
import { FaSpinner } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../Common/Pagination";
import AgentBlank from "./AgentBlank";
import CreateAgentModal from "./CreateAgentModal";
import { Dropdown } from "react-bootstrap";
import { useImmer } from "use-immer";
import TitleBar from "../../Common/TitleBar";
import MainMenu from "../../Common/MainMenu";

const Agent = () => {
   const dispatch = useDispatch()
   const auth = useSelector(state => state.auth)
   const [agents, setAgents] = useState([])
   const [isCheckedAll, setIsCheckedAll] = useState(false)
   const [checkedArr, setCheckedArr] = useState([])
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const [col, setCol] = useState({
      first: true,
      second: true,
      third: true,
      forth: true,
   })
   const [activeCol, setActiveCol] = useState(7)
   const [loader, setLoader] = useState({
      fetch: true
   })

   const [agentShow, setAgentShow] = useState([])
   const [category, setCategory] = useState([])
   const [filterObj, setFilterObj] = useImmer({
      searchKey: "",
      agentType: [],
      agentCategory: []
   })

   const [pagination, setPagination] = useState({
      currentPage: 1,
      totalItemOnPage: 15
   });

   const [sortHeader, setSortHeader] = useState({
      created: ""
   })

   const handleCheckAll = (e) => {
      if (agents.length > 0) {
         let arr = []
         if (e.target.checked) {
            agents.forEach((curElem) => {
               arr.push(curElem.id)
            })
            setIsCheckedAll(true)
         } else {
            setIsCheckedAll(false)
            arr = []
         }
         setCheckedArr(arr)
      }
   }

   const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
   const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
   const currentTodo = filterObj.searchKey !== "" ? agentShow : agentShow.slice(indexofFirstTodo, indexofLastTodo);


   const handleSort = () => {
      const data = [...agents]
      if (sortHeader.created) {
         data.sort((a, b) => {
            if (a.created.toLowerCase() > b.created.toLowerCase()) {
               return 1
            } else {
               return -1
            }
         })
         setSortHeader({
            ...sortHeader,
            created: false,
         });
      } else {
         data.sort((a, b) => {
            if (a.created.toLowerCase() < b.created.toLowerCase()) {
               return 1
            } else {
               return -1
            }
         })
         setSortHeader({
            ...sortHeader,
            created: true,
         });
      }
      setAgents(data)
   }

   const handleFilter = (e, checkType) => {
      let { name, value, checked } = e.target
      if (checkType) {
         value = checked
      }
      setCol({
         ...col,
         [name]: value
      })
   }

   const handleRowFilter = (e) => {
      const { name, value, checked } = e.target
      if (name === "agentType") {
         if (checked) {
            setFilterObj((draft) => {
               draft.agentType.push(value)
            })
         } else {
            let index = filterObj.agentType.findIndex((curElem) => curElem === value)
            setFilterObj((draft) => {
               draft.agentType.splice(index, 1)
            })
         }
      } else if (name === "agentCategory") {
         if (checked) {
            setFilterObj((draft) => {
               draft.agentCategory.push(value)
            })
         } else {
            let index = filterObj.agentCategory.findIndex((curElem) => curElem === value)
            if (index !== -1) {
               setFilterObj((draft) => {
                  draft.agentCategory.splice(index, 1)
               })
            }
         }
      }
   }

   const fetchAgents = () => {
      let data = {}
      dispatch(fetchData("list-agent", data, setAgents, loader, setLoader))
   }

   const fetchCategory = () => {
      dispatch(fetchDataPrimereact("fetch-agent-category", {}, setCategory, false, false, "name", true, "name", "name"))
   }


   useEffect(() => {
      if (checkedArr.length > 0) {
         if (checkedArr.length === agents.length) {
            setIsCheckedAll(true)
         } else {
            setIsCheckedAll(false)
         }
      } else {
         setIsCheckedAll(false)
      }
   }, [checkedArr])

   useEffect(() => {
      if (filterObj.searchKey === "" && filterObj.agentType.length === 0 && filterObj.agentCategory.length === 0) {
         setAgentShow(agents)
      }
      else {
         let dataArr = agents.filter((item1) => {
            return item1.name.toLowerCase().includes(filterObj.searchKey.toLowerCase()) || item1.code.includes(filterObj.searchKey)
         }).filter((curElem) => {
            if (filterObj.agentType.length === 0) {
               return curElem
            } else {
               return filterObj.agentType.includes(curElem.agentType.toLowerCase(), 0)
            }
         }).filter((curElem) => {
            if (filterObj.agentCategory.length === 0 || filterObj.agentCategory.includes("all")) {
               return curElem
            } else {
               return filterObj.agentCategory.includes(curElem.category.toLowerCase(), 0)
            }
         })

         setPagination({
            ...pagination,
            currentPage: 1,
            totalItemOnPage: 15
         })
         setAgentShow(dataArr)
      }

   }, [filterObj, agents])


   useEffect(() => {
      let arr = Object.keys(col)
      let count = 0
      arr.forEach((curElem) => {
         if (col[curElem] === true) {
            count++
         }
      })
      setActiveCol(count + 3)
   }, [col])

   useEffect(() => {
      fetchAgents()
      fetchCategory()
   }, [])

   return (

      <>
         <TitleBar title="Agents" />
         <MainMenu>
            <ScrollPanel className="custombar2 content-area">
               {agents.length === 0 ?
                  loader.fetch ?
                     <div className="text-center mt-4">
                        <FaSpinner className="spin" style={{ fontSize: 20, color: "#000" }} />
                     </div>
                     :
                     < AgentBlank
                        handleShow={handleShow}
                     />
                  :
                  <div className="content-area-in">

                     <>
                        <div className="page-title d-flex justify-content-between align-items-center">
                           <div>Agents</div>
                           {+auth.user.isClientAccount === 1 ? "" :
                              <button className="theme-btn" onClick={handleShow}>+ Add Agent</button>
                           }
                        </div>

                        <div className="table-wrap mt-3">

                           <div className="table-bar">
                              <div className="table-bar-left">
                                 <div className="table-bar-icon">
                                    <div className="table-nav">
                                       <div className="profile-menu">
                                          <Dropdown>
                                             <Dropdown.Toggle variant="successN" className="drop-btn">
                                                <img src={require("../../../images/icon/plus.svg").default} alt="" />
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                <li className="drop-list">
                                                   <span className="custom-check">
                                                      <input
                                                         type="checkbox"
                                                         name="first"
                                                         checked={col.first}
                                                         onChange={(e) => handleFilter(e, "checked")}
                                                      />
                                                      <span className="checkMark"></span>
                                                   </span>
                                                   Agent Name

                                                </li>

                                                <li className="drop-list">
                                                   <span className="custom-check">
                                                      <input
                                                         type="checkbox"
                                                         name="second"
                                                         checked={col.second}
                                                         onChange={(e) => handleFilter(e, "checked")}
                                                      />
                                                      <span className="checkMark"></span>
                                                   </span>
                                                   Agent Type

                                                </li>
                                                <li className="drop-list">
                                                   <span className="custom-check">
                                                      <input
                                                         type="checkbox"
                                                         name="third"
                                                         checked={col.third}
                                                         onChange={(e) => handleFilter(e, "checked")}
                                                      />
                                                      <span className="checkMark"></span>
                                                   </span>
                                                   Agent Category
                                                </li>
                                                <li className="drop-list">
                                                   <span className="custom-check">
                                                      <input
                                                         type="checkbox"
                                                         name="forth"
                                                         checked={col.forth}
                                                         onChange={(e) => handleFilter(e, "checked")}
                                                      />
                                                      <span className="checkMark"></span>
                                                   </span>
                                                   Date Joined
                                                </li>
                                             </Dropdown.Menu>
                                          </Dropdown>
                                       </div>
                                       <div className="profile-menu">
                                          <Dropdown>
                                             <Dropdown.Toggle variant="successN" className="drop-btn">
                                                <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu>
                                                <p style={{ fontSize: 15, fontWeight: 500 }}>Agent Type</p>
                                                <li className="drop-list">
                                                   <span className="custom-check">
                                                      <input
                                                         type="checkbox"
                                                         checked={filterObj.agentType.includes("inbound", 0)}
                                                         name="agentType"
                                                         value={"inbound"}
                                                         onChange={handleRowFilter}
                                                      />
                                                      <span className="checkMark"></span>
                                                   </span>
                                                   Inbound
                                                </li>
                                                <li className="drop-list">
                                                   <span className="custom-check">
                                                      <input
                                                         type="checkbox"
                                                         checked={filterObj.agentType.includes("outbound", 0)}
                                                         name="agentType"
                                                         value={"outbound"}
                                                         onChange={handleRowFilter}
                                                      />
                                                      <span className="checkMark"></span>
                                                   </span>
                                                   Outbound
                                                </li>

                                                <p style={{ fontSize: 15, fontWeight: 500 }} className="mt-2">Agent Category</p>
                                                {category.length > 0 ?
                                                   category.map((curElem) => {
                                                      return (
                                                         <li className="drop-list">
                                                            <span className="custom-check">
                                                               <input
                                                                  type="checkbox"
                                                                  checked={filterObj.agentCategory.includes(curElem.toLowerCase(), 0)}
                                                                  name="agentCategory"
                                                                  value={curElem.toLowerCase()}
                                                                  onChange={handleRowFilter}
                                                               />
                                                               <span className="checkMark"></span>
                                                            </span>
                                                            {curElem}
                                                         </li>
                                                      )
                                                   })
                                                   : ""}

                                                {/* <li className="drop-list">
                                                   <span className="custom-check">
                                                      <input
                                                         type="checkbox"
                                                         checked={filterObj.agentCategory.includes("support agent", 0)}
                                                         name="agentCategory"
                                                         value={"support agent"}
                                                         onChange={handleRowFilter}
                                                      />
                                                      <span className="checkMark"></span>
                                                   </span>
                                                   Support agent
                                                </li>
                                                <li className="drop-list">
                                                   <span className="custom-check">
                                                      <input
                                                         type="checkbox"
                                                         checked={filterObj.agentCategory.includes("lead engagement", 0)}
                                                         name="agentCategory"
                                                         value={"lead engagement"}
                                                         onChange={handleRowFilter}
                                                      />
                                                      <span className="checkMark"></span>
                                                   </span>
                                                   Lead engagement
                                                </li> */}
                                             </Dropdown.Menu>
                                          </Dropdown>
                                       </div>
                                    </div>


                                    <ul>
                                       <li onClick={handleSort} title="Sort by Date">
                                          <img src={require("../../../images/icon/sort.svg").default} alt="" />
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="table-bar-right">
                                 <div className="header-search">
                                    <input
                                       type="text"
                                       placeholder="Search"
                                       value={filterObj.searchKey}
                                       onChange={(e) => setFilterObj({ ...filterObj, searchKey: e.target.value })}
                                    />
                                    <span className="left-icon"><IoSearchOutline /></span>
                                 </div>
                              </div>
                           </div>

                           <div className="table-responsive mt-3">
                              <table className="table theme-table">
                                 <thead>
                                    <tr>
                                       <th>
                                          <span className="custom-check">
                                             <input
                                                checked={isCheckedAll}
                                                onChange={handleCheckAll}
                                                type="checkbox"
                                             />
                                             <span className="checkMark"></span>
                                          </span>
                                       </th>
                                       <th>Agent ID</th>
                                       {col.first ?

                                          <th>Agent Name</th>

                                          : null}
                                       {col.second ?

                                          <th>Agent Type</th>

                                          : null}
                                       {col.third ?

                                          <th>Agent Category</th>

                                          : null}
                                       {col.forth ?

                                          <th>Date Joined</th>

                                          : null}
                                       <th></th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {currentTodo.length > 0 ?
                                       currentTodo.map((curElem, index) => {
                                          return (
                                             <React.Fragment key={index}>
                                                <AgentRows
                                                   curElem={curElem}
                                                   agents={agents}
                                                   fetchAgents={fetchAgents}
                                                   setAgents={setAgents}
                                                   col={col}
                                                   checkedArr={checkedArr}
                                                   setCheckedArr={setCheckedArr}
                                                   auth={auth}
                                                />
                                             </React.Fragment>
                                          )
                                       })
                                       :
                                       ""
                                    }

                                 </tbody>
                              </table>
                           </div>
                           <Pagination
                              listData={agentShow}
                              pagination={pagination}
                              setPagination={setPagination}
                              currentTodo={currentTodo}
                              listArr={agents}
                              loader={loader.fetch}
                           />
                        </div>
                     </>

                  </div>
               }

            </ScrollPanel>
         </MainMenu>

         <CreateAgentModal
            show={show}
            handleClose={handleClose}
         />
      </>
   )
}

export default Agent;