import React from 'react'
import SidePanel from './SidePanel'
import DashboardHeader from './DashboardHeader'
import DashboardFooter from './DashboardFooter'
import { useSelector } from 'react-redux'

const MainMenu = ({ children }) => {
  const extra = useSelector(state => state.extra.data)
  return (
    <div className={`dashboard  ${extra.toggleMenu ? "toggleClass" : ""}`}>
      <SidePanel />
      <div className="dashboard-in">
        <DashboardHeader />
        {children}
        <DashboardFooter />
      </div>
    </div>
  )
}

export default MainMenu