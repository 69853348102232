import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onCreateManual } from '../../Redux/Actions/AgentActions'
import { TbEdit } from "react-icons/tb";
import { BsInfoCircle } from 'react-icons/bs'

const ManualModal = (props) => {
    const dispatch = useDispatch()
    const [text, setText] = useState("")
    const [loader, setLoader] = useState({
        save: false
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({ ...loader, save: true })
        let obj = {
            text: text,
            agentId: props.id
        }
        dispatch(onCreateManual(obj, props.fetchData, props.closeMain, props.handleClose, loader, setLoader))
    }

    useEffect(() => {
        if (props.show) {
            setText("")
        }
    }, [props.show])

    return (
        <Modal className="theme-modal" show={props.show} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={props.handleClose}><AiOutlineClose /></span>

                <div className="icon-title">
                    <span className="title-icon"><TbEdit /></span> 
                    <h6>Add Manual Data</h6>
                </div>

                <div className="alert-box">
                    <div className="alert-icon"><BsInfoCircle /></div>
                    <div className="alert-txt">
                        <p>Only public available knowledgebases will be imported. If your knowledgebase is not publicly available, please import as documents instead.</p>
                    </div>
                </div>

                <form className="modal-content" onSubmit={handleSubmit}>
                    <div className='simple-input'>
                        <textarea
                            value={text}
                            className='input'
                            style={{ height: 150 }}
                            onChange={(e) => setText(e.target.value)}
                            placeholder='Enter Your Data'
                            required
                        />
                    </div>
                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type='button' onClick={props.handleClose}><span>Cancel</span></button>
                        <button className="theme-btn" type='submit'><span>{loader.save ? <>Creating <FaSpinner className='spin' /></> : "Create"}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default ManualModal