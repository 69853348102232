import React, { useEffect, useState } from "react";
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { FaSpinner } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import WorkflowAutomationCalendarBookingTab from "./WorkflowAutomationCalendarBookingTab";
import WorkflowAutomationCustomFunction from "./WorkflowAutomationCustomFunction";
import AgentHeader from "../AgentDesign/AgentHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkflowAll, onAddWorkflow, onChangeAgentWorkflow, onDeleteWorkflow, onRemoveWorkflow, onSaveAgent } from "../../../../Redux/Actions/AgentActions";
import WorkflowCallTransfer from "./WorkflowCallTransfer";
import CommonAlert from "../../../Common/CommonAlert";
import WorkflowCallEnd from "./WorkflowCallEnd";
import { useLocation } from "react-router-dom";
import queryString from "query-string"
import { MdOutlineCancel } from "react-icons/md";
import WorkflowSMS from "./WorkflowSMS";
import WorkflowEmail from "./WorkflowEmail";
import { Tooltip } from "react-tooltip";

const Workflow = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { id } = queryString.parse(location.search)
    const agent = useSelector(state => state.agent.data)
    const extra = useSelector(state => state.extra.data)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false

    })
    const [loader, setLoader] = useState({
        fetch: true
    })

    const automationOptions = [
        "Call Transfer",
        "SMS",
        "Email",
        "Check Calendar Availability",
        "Book Calendar",
        "Custom Function"
    ]


    const onDelete = (wfId, index) => {
        setSweet({
            ...sweet,
            enable: true,
            id: { wfId, index }
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        setSweet({
            ...sweet,
            loader: true
        })
        dispatch(onDeleteWorkflow(sweet, setSweet))
    }




    const handleChange = (e, index) => {
        let { name, value, checked } = e.target
        if (name === "speakDuringExecution") {
            value = checked
        }
        if (name === "transferTo") {
            if (value.length < 15) {
                dispatch(onChangeAgentWorkflow(name, value, index))
            }
        } else {
            dispatch(onChangeAgentWorkflow(name, value, index))
        }
    }

    const handleCancelBox = (index) => {
        dispatch(onRemoveWorkflow(index))
    }

    const handleAdd = () => {
        dispatch(onAddWorkflow())
    }

    const fetchWorkflow = () => {
        let data = { agentId: id }
        dispatch(fetchWorkflowAll(data, loader, setLoader))
    }

    useEffect(() => {
        if (id) {
            fetchWorkflow()
        }
    }, [id])

    return (
        <>
            <AgentHeader />
            {extra.isKnowledgeData ? null :
                <div className="alert alert-warning" role="alert">
                    Please add some data in knowledgebase to have conversation with the agent.
                </div>
            }
            <div>
                {loader.fetch ?

                    <div className="text-center">
                        <FaSpinner className="spin" style={{ fontSize: 24 }} />
                    </div>
                    :
                    agent.workflow ?
                        agent.workflow.length > 0 ?
                            agent.workflow.map((curElem, index) => {
                                return (
                                    <React.Fragment>
                                        <div className="account-block mt-4">
                                            <div className="title-flex">
                                                <h6>Choose Automation Type ( Optional ) </h6>
                                                {index === 0 ?
                                                    <div className="profile-menu">
                                                        <button className="simple-btn" type="button" onClick={handleAdd}>
                                                            + Add More
                                                        </button>
                                                    </div> : null}
                                            </div>
                                            <div className="input-wrap">
                                                <div className="input-head">
                                                    <label className="label" htmlFor=""
                                                        data-tooltip-id="my-tooltip"
                                                        data-tooltip-content="Select the type of automation you want to enable for your AI agent. This allows the agent to perform tasks like appointment scheduling, call transfer,sending sms etc. without manual intervention"
                                                    >Automation Options</label>

                                                    {curElem.id ?
                                                        <span className="theme-icon"><FaRegTrashCan onClick={() => onDelete(curElem.id, index)} /></span> :
                                                        agent.workflow.length > 1 ?
                                                            < span className="theme-icon"><MdOutlineCancel onClick={() => handleCancelBox(index)} /></span> : null
                                                    }
                                                    <Tooltip id="my-tooltip" place="top-start" clickable />

                                                </div>
                                                {curElem.id ?
                                                    <input
                                                        className="input"
                                                        type="text"
                                                        name="functionName"
                                                        placeholder='Enter Function Name Here'
                                                        value={curElem.type}
                                                        readOnly
                                                        required
                                                    />
                                                    :
                                                    <PrimeDropdown
                                                        value={curElem.type}
                                                        onChange={(e) => handleChange(e, index)}
                                                        options={automationOptions}
                                                        placeholder="Select Type"
                                                        className="input"
                                                        name="type"
                                                        required
                                                    />
                                                }
                                            </div>
                                            {curElem.type === "Call Transfer" ?
                                                <WorkflowCallTransfer
                                                    handleChange={handleChange}
                                                    curElem={curElem}
                                                    index={index}
                                                    agentId={id}
                                                />
                                                :
                                                curElem.type === "SMS" ?
                                                    <WorkflowSMS
                                                        handleChange={handleChange}
                                                        curElem={curElem}
                                                        index={index}
                                                        agentId={id}
                                                    />
                                                    :
                                                    curElem.type === "Email" ?
                                                        <WorkflowEmail
                                                            handleChange={handleChange}
                                                            curElem={curElem}
                                                            index={index}
                                                            agentId={id}
                                                        />
                                                        :
                                                        curElem.type === "Book Calendar" || curElem.type === "Check Calendar Availability" ?
                                                            <WorkflowAutomationCalendarBookingTab
                                                                handleChange={handleChange}
                                                                curElem={curElem}
                                                                index={index}
                                                                agentId={id}
                                                            />
                                                            : curElem.type === "Custom Function" ?
                                                                <WorkflowAutomationCustomFunction
                                                                    handleChange={handleChange}
                                                                    curElem={curElem}
                                                                    index={index}
                                                                    agentId={id}
                                                                />
                                                                : null
                                            }
                                        </div >
                                    </React.Fragment>
                                )
                            })
                            : "" : ""
                }
            </div >

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this automation type?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </>
    )
}

export default Workflow