import React, { useEffect, useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';

import { FiUploadCloud } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { changeProfile, onUpdatePassword, onUpdateUser } from "../../Redux/Actions/AuthActions";
import Swal from "sweetalert2";
import { FaSpinner } from "react-icons/fa";
import TitleBar from "../Common/TitleBar";
import MainMenu from "../Common/MainMenu";

const MyAccount = () => {

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [loader, setLoader] = useState({
        user: false,
        password: false,
        image: false
    })
    const [membershipName, setMembershipName] = useState("")
    const [user, setUser] = useState({
        name: "",
        email: "",
    })

    const [password, setPassword] = useState({
        password: '',
        new_password: '',
        confirm_password: '',
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setUser({
            ...user,
            [name]: value
        })
    }

    const handlePassword = (e) => {
        const { name, value } = e.target
        setPassword({
            ...password,
            [name]: value
        })
    }


    const onImageChange = (e) => {
        if (e.target.files[0].type === 'image/png' || e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg') {
            if (e.target.files[0].size < 5000000) {
                const formData = new FormData()
                formData.append('user_id', auth.user.id);
                formData.append('file', e.target.files[0]);
                setLoader({
                    ...loader,
                    image: true
                })
                dispatch(changeProfile(formData, loader, setLoader))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Max allowed size is 5MB!',
                    confirmButtonColor: "#000"
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have selected invalid file type!',
                confirmButtonColor: "#000"
            })
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            user: true
        })
        delete user.email
        dispatch(onUpdateUser(user, loader, setLoader))
    }
    const updatePassword = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (password.new_password) {
            if (pattern.test(password.new_password)) {
                if (password.new_password === password.confirm_password) {
                    setLoader({
                        ...loader,
                        password: true
                    })
                    dispatch(onUpdatePassword(password, loader, setLoader))
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }

            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    useEffect(() => {
        if (auth.user) {
            setUser({
                ...user,
                name: auth.user.name,
                email: auth.user.email,
            })
            if (auth.user.membership) {
                let strMem = auth.user.membership.split("__")[auth.user.membership.split("__").length - 1]
                setMembershipName(strMem)
            }
        }
    }, [auth])

    return (
        <>
            <TitleBar title="My Account" />
            {/* <div className="dashboard">
                <SidePanel />
                <div className="dashboard-in">
                    <DashboardHeader /> */}
            <MainMenu>
                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">
                        <div className="page-title">My Account</div>
                        <div className="account-areaN">

                            <div className="account-block text-center mt-4">
                                <div className="account-pic">
                                    {loader.image ?
                                        <div className="d-flex align-items-center justify-content-center" style={{ height: "100%", fontSize: 20 }}>
                                            <FaSpinner className="spin" />
                                        </div>
                                        :
                                        <>
                                            <img src={auth.user.profile} alt="" />
                                            <div className="account-pic-upload">
                                                <FiUploadCloud />
                                                <input type="file" onChange={onImageChange} />
                                            </div>
                                        </>}
                                </div>
                                <h6 className="pb-3 pt-3">{user.name}</h6>
                                <button className="theme-btn"><span>{membershipName}</span></button>
                            </div>

                            <form className="account-block mt-4" onSubmit={handleSubmit}>
                                <h6>Personal Info</h6>
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Name</label>
                                    <input
                                        className="input"
                                        type="text"
                                        name="name"
                                        value={user.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Email</label>
                                    <input
                                        className="input"
                                        type="email"
                                        name='email'
                                        value={user.email}
                                        readOnly
                                    />
                                </div>
                                <div className="btn-group">
                                    <button className="theme-btn"><span>{loader.user ? <>Updating <FaSpinner className="spin" size={14} /> </> : "Update"}</span></button>
                                </div>
                            </form>

                            <form className="account-block mt-4" onSubmit={updatePassword}>
                                <h6>Change Password</h6>
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Current Password</label>
                                    <input
                                        className="input"
                                        type="password"
                                        name='password'
                                        placeholder='Enter your current password'
                                        onChange={handlePassword}
                                        required
                                    />
                                </div>
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">New Password</label>
                                    <input
                                        className="input"
                                        type="password"
                                        name='new_password'
                                        placeholder='Enter your new password'
                                        onChange={handlePassword}
                                        required
                                    />
                                </div>
                                <div className="input-wrap">
                                    <label className="label" htmlFor="">Confirm Password</label>
                                    <input
                                        className="input"
                                        type="password"
                                        placeholder='Confirm password'
                                        name='confirm_password'
                                        onChange={handlePassword}
                                        required
                                    />

                                </div>
                                {passwordMsg.validation ?
                                    <p style={{ fontSize: 13, marginLeft: 5, color: "#DC143C" }}>{passwordMsg.msg}</p> : ""}
                                <div class="alert alert-primary mt-3" role="alert">Updating your password will log you out for re-login.</div>

                                <div className="btn-group">
                                    <button type="submit" className="theme-btn"><span>{loader.password ? <>Updating <FaSpinner className="spin" size={14} /> </> : "Update"}</span></button>
                                </div>
                            </form>

                        </div>
                    </div>
                </ScrollPanel>
            </MainMenu>
            {/* <DashboardFooter />
                </div>
            </div> */}
        </>
    )
}

export default MyAccount;