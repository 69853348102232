import React, { useEffect, useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";

import { ScrollPanel } from 'primereact/scrollpanel';

import Modal from 'react-bootstrap/Modal';
import { AiOutlineClose } from "react-icons/ai";
import TitleBar from "../Common/TitleBar";
import CreateHookModal from "./CreateHookModal";
import { fetchData } from "../../Redux/Actions/CommonActions";
import { useDispatch } from "react-redux";
import WebhookBlank from "./WebhookBlank";
import WebHooksRows from "./WebHooksRows";
import { FaSpinner } from "react-icons/fa";
import MainMenu from "../Common/MainMenu";


const Webhook = () => {
  const dispatch = useDispatch()
  const [hooks, setHooks] = useState([])
  const [loader, setLoader] = useState({
    fetch: true
  })
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const fetchHookData = () => {
    let data = {}
    dispatch(fetchData("list-webhook", data, setHooks, loader, setLoader))
  }

  useEffect(() => {
    fetchHookData()
  }, [])

  return (
    <>
      {/* <TitleBar title="Webhook" />
      <MainMenu> */}

      {/* <ScrollPanel className="custombar2 content-area"> */}
      <div className="content-area-in">
        {hooks.length === 0 ?
          loader.fetch ?
            <div className="text-center mt-4">
              <FaSpinner className="spin" style={{ fontSize: 20, color: "#000" }} />
            </div>
            :
            <WebhookBlank
              handleShow={handleShow}
            />

          :

          <>

            <div className="page-title d-flex justify-content-between align-items-center">
              <div>Webhook</div>
              <button className="theme-btn" onClick={handleShow}>+ Create Webhook</button>
            </div>

            <div className="table-wrap mt-3">
              <div className="table-responsive">
                <table className="table theme-table">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>URL</th>
                      <th>Created</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {hooks.length > 0 ?
                      hooks.map((curElem, index) => {
                        return (
                          <React.Fragment key={index}>
                            <WebHooksRows
                              curElem={curElem}
                              hooks={hooks}
                              fetchHookData={fetchHookData}
                              setHooks={setHooks}
                            />
                          </React.Fragment>
                        )
                      })
                      :
                      ""
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </>
        }
      </div>
      {/* </ScrollPanel> */}
      {/* </MainMenu> */}

      <CreateHookModal
        show={show}
        handleClose={handleClose}
        fetchHookData={fetchHookData}
      />
    </>
  )
}

export default Webhook;