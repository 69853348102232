import React, { useEffect, useRef, useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onCreateWorkflow, onUpdateWorkflow } from '../../../../Redux/Actions/AgentActions'
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { Tooltip } from 'react-tooltip';
let iti = null
const WorkflowCallTransfer = ({ handleChange, index, curElem, agentId }) => {
    const dispatch = useDispatch()
    const inputRef = useRef(null);
    const [loader, setLoader] = useState({
        create: false
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        if (iti) {
            setLoader({
                ...loader,
                create: true
            })
            if (curElem.id) {
                let obj = { ...curElem }
                obj = JSON.parse(JSON.stringify(obj))
                obj.data[0] = {
                    ...obj.data[0],
                    transferTo: iti.getNumber()
                }
                dispatch(onUpdateWorkflow(obj, loader, setLoader))
            } else {
                let obj = { ...curElem, agentId }
                obj = JSON.parse(JSON.stringify(obj))
                obj.data[0] = {
                    ...obj.data[0],
                    transferTo: iti.getNumber()
                }
                dispatch(onCreateWorkflow(obj, index, loader, setLoader))
            }
        }
    }

    const handleKeyPress = (e) => {

        const charCode = e.which ? e.which : e.keyCode;
        if ((charCode < 48 || charCode > 57) && charCode !== 8) {
            e.preventDefault();
        }
    };


    useEffect(() => {
        if (inputRef && inputRef.current) {
            iti = intlTelInput(inputRef.current, {
                initialCountry: "us",
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.1/js/utils.js",
            });
            return () => {
                if (iti) {
                    iti.destroy();
                }
            }
        }
    }, [inputRef]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="input-wrap">
                <label className="label" htmlFor=""
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Enter a unique name for this call transfer function. This name helps you identify and manage different call transfer rules in your automation settings"
                >Function Name</label>
                <input
                    className="input"
                    type="text"
                    name="functionName"
                    placeholder='Enter Function Name Here'
                    value={curElem.data[0]?.functionName}
                    onChange={(e) => handleChange(e, index)}
                    required

                />
            </div>
            <div className="input-wrap">
                <label className="label" htmlFor=""
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Provide a brief description of this call transfer function. Include details about when and why the transfer occurs to ensure clarity in your automation process."
                >Description</label>
                <input
                    className="input"
                    type="text"
                    name="description"
                    placeholder='When user is angry or requests a human agent, transfer the call to a human.'
                    value={curElem.data[0]?.description}
                    onChange={(e) => handleChange(e, index)}
                    required
                />
            </div>
            <div className="input-wrap">
                <label className="label" htmlFor=""
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Specify the destination number for the call transfer. This ensures calls are routed to the correct recipient during the transfer process."

                >Transfer to</label>
                <div>
                    <input
                        ref={inputRef}
                        className={`input num-list-${index}`}
                        type="tel"
                        name="transferTo"
                        maxLength={15}
                        value={curElem.data[0]?.transferTo}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={handleKeyPress}
                        required
                    />
                </div>
            </div>
            <div className="btn-group mt-4">
                <button className="theme-btn" type="submit"><span>
                    {loader.create ? <>{curElem.id ? "Updating" : "Creating"} <FaSpinner className="spin" /></> : curElem.id ? "Update" : "Create"}
                </span>
                </button>
            </div>
            <Tooltip id="my-tooltip" place="top-start" clickable />

        </form>
    )
}

export default WorkflowCallTransfer