import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Dropdown } from 'primereact/dropdown';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { AiOutlineClose, AiFillCheckCircle } from "react-icons/ai";
import { FaSpinner } from 'react-icons/fa';
import { onCreateAgent } from '../../../Redux/Actions/AgentActions';
import { useDispatch } from 'react-redux';
import { FiUpload } from 'react-icons/fi';
import { fetchDataPrimereact, onUploadMedia } from '../../../Redux/Actions/CommonActions';
import Swal from 'sweetalert2';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { useNavigate } from 'react-router-dom';

const CreateAgentModal = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [percent, setPercent] = useState(0)
    const [category, setCategory] = useState([])
    const [lang, setLang] = useState([])
    const [tone, setTone] = useState([])

    const [image, setImage] = useState("")
    const [loader, setLoader] = useState({
        create: false,
        upload: false
    })

    const [data, setData] = useState({
        name: "",
        category: null,
        agentType: "inbound",
        description: "",
        gender: "",
        tone: "",
        language: "",
        accent: "",
        langCode: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "language") {
            setData({
                ...data,
                language: value,
                accent: ""
            })
        } else {
            setData({
                ...data,
                [name]: value
            })
        }

    }

    const onCompeleteUpload = (url) => {
        setImage(url)
    }

    const uploadFile = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 50000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = { ...data }
        if (!obj.category) {
            dispatch(setAlert("Please select category to continue.", "danger"))
        } else if (!obj.gender) {
            dispatch(setAlert("Please select gender to continue.", "danger"))
        } else if (!obj.tone) {
            dispatch(setAlert("Please select tone to continue.", "danger"))
        } else if (!obj.language) {
            dispatch(setAlert("Please select language to continue.", "danger"))
        } else {
            let isSpaneinName = true
            for (let i = 0; i < obj.name.length; i++) {
                if (obj.name[i] !== " ") {
                    isSpaneinName = false
                    break;
                }
            }
            if (isSpaneinName) {
                dispatch(setAlert("Please provide a valid name for an agent.", "danger"))
            } else {
                setLoader({
                    ...loader,
                    create: true
                })
                obj.image = image
                obj.langCode = obj.language.code
                obj.accent  = obj.language.name.split("(")[1].split(")")[0]
                obj.language = obj.language.name.split(" ")[0]
                dispatch(onCreateAgent(obj, navigate, loader, setLoader))
            }
        }
    }

    const fetchCategory = () => {
        dispatch(fetchDataPrimereact("fetch-agent-category", {}, setCategory, false, false, "name", true, "name", "name"))
    }

    const fetchLanguage = () => {
        let data = {}
        dispatch(fetchDataPrimereact("get-languages", data, setLang, false, false, "name", false, "name"))
    }

    const fetchTone = () => {
        let data = {}
        dispatch(fetchDataPrimereact("fetch-tone", data, setTone, false, false, "name", true, "name"))
    }


    useEffect(() => {
        fetchCategory()
        fetchLanguage()
        fetchTone()
    }, [])

    useEffect(() => {
        if (props.show) {
            setImage("")
            setData({
                name: "",
                category: null,
                agentType: "inbound",
                description: "",
                gender: "",
                tone: "",
                language: "",
                accent: "",
                langCode: ""
            })
        }
    }, [props.show])


    return (
        <Modal className="theme-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={props.handleClose}><AiOutlineClose /></span>
                <form className="modal-content" onSubmit={handleSubmit}>
                    <h3 className="text-center">Creating your own AI agent is just one step away </h3>
                    <p className="text-center pt-2">Don't worry, you can obviously always later change the information given</p>
                    <div className="modal-card mt-4">
                        <h6>Agent Information</h6>
                        <div className="input-wrap">
                            <div className="uploader left">
                                <div className="uploader-icon">
                                    {loader.upload ?
                                        <FaSpinner className='spin' /> :
                                        image ?
                                            <img src={image} alt='' style={{ height: "100%", width: "100%", borderRadius: "50%" }} />
                                            :
                                            <FiUpload />
                                    }
                                </div>
                                <div className="uploader-txt">
                                    <h6>Upload Image</h6>
                                    <p>Click here to upload image for the agent</p>
                                </div>
                                <input type="file" onChange={uploadFile} required
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="Upload an image to represent the AI agent visually. This could be a company logo, a custom avatar, or a profile image."
                                />
                            </div>
                        </div>

                        <div className="input-wrap">
                            <label className="label" htmlFor="" data-tooltip-id="my-tooltip"
                                data-tooltip-content="Enter a unique name for your AI agent. This name will be used to identify the agent in campaigns and reports">Agent Name</label>
                            <input
                                className="input"
                                type="text"
                                name="name"
                                placeholder="Enter Agent Name"
                                value={data.name}
                                maxLength={64}
                                onChange={handleChange}
                                required
                            />
                        </div>


                        <div className="input-wrap">
                            <label className="label" htmlFor="" data-tooltip-id="my-tooltip"
                                data-tooltip-content="Provide a brief description of your AI agent’s purpose and key functionalities">Agent Description</label>
                            <textarea
                                className="input"
                                type="text"
                                name="description"
                                placeholder="Enter Agent Description"
                                value={data.description}
                                onChange={handleChange}
                                rows={5}
                                required
                            />

                        </div>

                        <div className="input-wrap">
                            <label className="label" htmlFor="" data-tooltip-id="my-tooltip"
                                data-tooltip-content="Select the category that best describes your AI agent’s primary role, such as Sales, Customer Support, or Lead generation etc">Agent Category</label>
                            <Dropdown
                                value={data.category}
                                onChange={(e) => handleChange(e)}
                                options={category}
                                placeholder="Select Type"
                                className="input"
                                name="category"
                            />

                        </div>


                        <div className='row'>
                            <div className='col-6'>
                                <div className="input-wrap">
                                    <label className="label" htmlFor=""
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Select the gender that the AI agent will be perceived as when interacting with callers. This will help set the tone and style of communication"
                                    >Agent Gender</label>
                                    <Dropdown
                                        value={data.gender}
                                        onChange={(e) => handleChange(e)}
                                        options={["Male", "Female"]}
                                        placeholder="Select Type"
                                        className="input"
                                        name="gender"
                                    />

                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="input-wrap">
                                    <label className="label" htmlFor=""
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Choose the tone of voice for the AI agent. Options range from formal and professional to casual and friendly, depending on how you want the agent to interact with callers."
                                    >Agent Tone</label>
                                    <Dropdown
                                        value={data.tone}
                                        onChange={(e) => handleChange(e)}
                                        options={tone}
                                        placeholder="Select Tone"
                                        className="input"
                                        name="tone"
                                    />
                                </div>

                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-12'>
                                <div className="input-wrap">
                                    <label className="label" htmlFor=""
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Select the primary language that the AI agent will use to communicate with callers. Ensure that the language matches your target audience"
                                    >Agent Language</label>
                                    <Dropdown
                                        value={data.language}
                                        onChange={(e) => handleChange(e)}
                                        options={lang}
                                        optionLabel='name'
                                        placeholder="Select Language"
                                        className="input"
                                        name="language"

                                    />
                                </div>
                            </div>
                            {/* <div className='col-6'>
                                <div className="input-wrap">
                                    <label className="label" htmlFor=""
                                        data-tooltip-id="my-tooltip"
                                        data-tooltip-content="Choose the accent that the AI agent will have during calls, allowing for a more localized or personalized experience for your audience"
                                    >Agent Accent</label>
                                    <Dropdown
                                        value={data.accent}
                                        onChange={(e) => handleChange(e)}
                                        options={accents}
                                        placeholder="Select Accent"
                                        className="input"
                                        name="accent"
                                    />
                                </div>
                            </div> */}
                        </div>

                        <div className="select-group">
                            <ul>
                                <li>
                                    <div className="select-group-single">
                                        <span className="select-check"><AiFillCheckCircle /></span>
                                        <h6>Inbound Agent</h6>
                                        <p>An inbound AI calling agent will answer a call that is typically initiated by a customer to a call center or contact center i.e. the AI agent itself. The AI calling agent will then handle the call, talk to the caller and take action based on how it's setup.</p>
                                        <input
                                            type="radio"
                                            name="agentType"
                                            checked={data.agentType === "inbound" ? true : false}
                                            onChange={handleChange}
                                            value={"inbound"}
                                            required
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="select-group-single">
                                        <span className="select-check"><AiFillCheckCircle /></span>
                                        <h6>Outbound Agent</h6>
                                        <p>An outbound AI calling agent is one that makes an outgoing call to a prospect to generate their interest in a company's product or service — or close a sale. The AI agent who makes the outbound calls are usually sales or customer service representatives type and will then handle the call, talk to the caller and take action based on how it's setup.</p>
                                        <input
                                            type="radio"
                                            name="agentType"
                                            checked={data.agentType === "outbound" ? true : false}
                                            onChange={handleChange}
                                            value={"outbound"}
                                            required
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type='button' onClick={props.handleClose}><span>Cancel</span></button>
                        <button className="theme-btn" type='submit'><span>{loader.create ? <>Creating <FaSpinner className='spin' /> </> : "Create"}</span></button>
                    </div>
                    <Tooltip id="my-tooltip" place="top-start" clickable />
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateAgentModal