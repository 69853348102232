import React, { useEffect, useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";

import { ScrollPanel } from 'primereact/scrollpanel';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import { SlCloudUpload } from "react-icons/sl";
import Rebranding from "./Rebranding";
import FbTemplates from "./FbTemplates";
import ClientContract from "./ClientContract";
import ResellerLicense from "./ResellerLicense";
import DfyLeadMagnets from "./DfyLeadMagnets";
import AgencyWebsite from "./AgencyWebsite";
import VideoFlix from "./VideoFlix";
import DfyCourse from "./DfyCourse";
import WebHosting from "./WebHosting";
import DfyArticle from "./DfyArticle";
import TitleBar from "../Common/TitleBar";
import MainMenu from "../Common/MainMenu";
import { useSelector } from "react-redux";

const Upgrade = () => {
    const auth = useSelector(state => state.auth)
    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])
    return (
        <>
            <TitleBar title="Upgrade" />
            <MainMenu>
                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">
                        <div className="page-title">Upgrade</div>
                        <div className="containerN">

                            <Tab.Container id="left-tabs-example" defaultActiveKey="zero">

                                <div className="upgrade-tab">

                                    <div className="upgrade-tab-left">
                                        <Nav variant="pillsn" className="">
                                            {memberShip.includes("enterprise") || memberShip.includes("bundle") || memberShip.includes("fastpass") ?
                                                <>
                                                    <Nav.Item><Nav.Link eventKey="tab-1">Rebranding</Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="tab-11">Business Finder</Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="tab-4">Reseller License</Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="tab-3">Client Contract</Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="tab-6">Agency Website</Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="tab-9">Web Hosting</Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="tab-5">DFY Lead Magnets</Nav.Link></Nav.Item>
                                                    <Nav.Item><Nav.Link eventKey="tab-2">FB Templates</Nav.Link></Nav.Item>


                                                </> : null}
                                            {/* <Nav.Item><Nav.Link eventKey="tab-7">Video Flix</Nav.Link></Nav.Item> */}
                                            {/* <Nav.Item><Nav.Link eventKey="tab-8">DFY Course</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="tab-10">DFY Articles</Nav.Link></Nav.Item> */}
                                        </Nav>
                                    </div>

                                    <div className="upgrade-tab-right">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="zero">
                                                <div className="upgrade-tab-in first">
                                                    <img src={require("../../images/agent.svg").default} alt="" />
                                                    <h5 className="pt-3">Welcome!</h5>
                                                    <p>Choose any option to upgrade</p>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey={"tab-11"}>
                                                <div className="upgrade-tab-in">
                                                    <div className="upgrade-tab-block">
                                                        <h6 >Business Finder</h6>
                                                        <iframe
                                                            src="https://resources.vega6.info/business-finder?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2MDYxMjUyMjcsImp0aSI6IldZY3gwQW42cnFHcHdhRUluYlNWTWwiLCJpc3MiOiJodHRwczpcL1wvcmVzb3VyY2VzLnZlZ2E2LmluZm9cLyIsIm5iZiI6MTYwNjEyNTIzNywiZGF0YSI6eyJ1c2VyX2lkIjoiMSIsImFwcF91cmwiOiJodHRwczpcL1wvdjJzZXJ2ZXIudmlkZW9yZWVsLmlvXC8ifX0.fN5JpYG7hFfXgprbTLyGbtbBE-T4-8SG25xtn4ckOkGmwevJEANb2UjngHIaUiAVNNmzVIeLotsVRZb9aB9g_Q"
                                                            style={{ width: "100%", height: "100vh" }}
                                                            title="business-finder"
                                                            className="mt-2"
                                                        />
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tab-1">
                                                <Rebranding />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab-2">
                                                <FbTemplates />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab-3">
                                                <ClientContract />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab-4">
                                                <ResellerLicense />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab-5">
                                                <DfyLeadMagnets />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab-6">
                                                <AgencyWebsite />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab-7">
                                                <VideoFlix />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab-8">
                                                <DfyCourse />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab-9">
                                                <WebHosting />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab-10">
                                                <DfyArticle />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </ScrollPanel>
            </MainMenu>
        </>
    )
}

export default Upgrade;