import React from 'react'
import logoD from "../../images/logo-dark.svg"
import logoL from "../../images/logo-light.png"
const AuthLogoHeader = () => {
    return (
        <div className="login-head-wrap">
            <div className="login-head">
                <img src={logoD} alt="" style={{ width: 260 }} />
            </div>
        </div>
    )
}

export default AuthLogoHeader