import React, { useState } from 'react'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { BsThreeDots } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';
import { onAddRephrase } from '../../../../Redux/Actions/ExtraActions';
const MessagesSection = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [state, setState] = useState({
        show: false,
        index: -1
    })

    const addRephrase = (curElem) => {
        let data = {
            ...curElem
        }
        setState({
            show: false,
            index: -1
        })
        dispatch(onAddRephrase(data))
    }

    return (
        <div className="chat-area-in">
            {props.messages.length > 0 ?
                props.messages.map((curElem, index) => {
                    let unAnswered = false
                    if (curElem.role === "user") {
                        if (index + 1 < props.messages.length) {
                            unAnswered = props.messages[index + 1].role === "user" ? true : false
                        }
                        if (props.messages.length - 1 === index && curElem.role === "user") {
                            unAnswered = true
                        }
                    }
                    return (
                        <React.Fragment key={index}>
                            {curElem.role === "user" ?
                                <div className={`chat-block ${unAnswered ? "ai" : ""}`}>
                                    <div className="chat-block-single">
                                        <div className="chat-bubble">
                                            <p>{curElem.message}</p>
                                        </div>
                                        <div className="timeStamp">
                                            {unAnswered ?
                                                <span className="info-ai"><AiOutlineExclamationCircle /> AI Agent failed to answer this question! </span> : null
                                            }
                                            <span>{curElem.created.slice(11, 19)}</span>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="chat-block left">
                                        <div className="chat-block-single">
                                            <div className="chat-bubble" style={{ position: "relative" }}>
                                                {+auth.user.isClientAccount === 1 ? "" :
                                                    <BsThreeDots style={{ position: "absolute", top: 0, right: 10, fontSize: 16, cursor: "pointer" }} onClick={() => setState({
                                                        ...state,
                                                        show: !state.show,
                                                        index: index
                                                    })} />
                                                }
                                                {state.show && index === state.index ?
                                                    <>
                                                        <div className='option-box-calling'>
                                                            <p onClick={() => addRephrase(curElem)}>Rephrase</p>
                                                        </div>
                                                    </>
                                                    : null}

                                                <p dangerouslySetInnerHTML={{ __html: curElem?.message?.replace(/\n/g, '<br>') }} />

                                            </div>
                                            <div className="timeStamp">
                                                <span>{curElem.created.slice(11, 19)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {curElem.rePhrased ?
                                        <div className="chat-block mt-1 left">
                                            <div className="chat-block-single">
                                                <div className="chat-bubble" style={{ background: "#39c175" }}>
                                                    <p>{curElem.rePhrased}</p>
                                                </div>
                                                {/* <div className="timeStamp">
                                                    <span>{curElem.created.slice(11, 19)}</span>
                                                </div> */}
                                            </div>
                                        </div> : null}
                                </>
                            }
                        </React.Fragment>
                    )
                })

                :
                <div className='text-center'>
                    No chat available in this conversation.
                </div>
            }




            {/* <div className="chat-block">
                <div className="chat-block-single">
                    <div className="chat-bubble">
                        <p>hi ByeWind, I saw your work on Dribbble and it's awesome. I would like to know more about it. Could you send me your website?</p>
                    </div>
                    <div className="timeStamp">
                        <span>00:10</span>
                    </div>
                </div>
            </div>
            <div className="chat-block ai">
                <div className="chat-block-single">
                    <div className="chat-bubble">
                        <p>hi ByeWind, I saw your work on Dribbble and it's awesome. I would like to know more about it. Could you send me your website?</p>
                    </div>
                    <div className="timeStamp">
                        <span className="info-ai"><AiOutlineExclamationCircle /> AI Agent failed to answer this question! </span>
                        <span>00:10</span>
                    </div>
                </div>
            </div>


            <div className="chat-block left">
                <div className="chat-block-single">
                    <div className="chat-bubble">
                        <p>Thank you. Of course. Just a moment, please.</p>
                    </div>
                    <div className="timeStamp">
                        <span>00:10</span>
                    </div>
                </div>
            </div>

            <div className="chat-block">
                <div className="chat-block-single">
                    <div className="chat-bubble">
                        <p>Got it, thank you.</p>
                    </div>
                    <div className="timeStamp">
                        <span>00:10</span>
                    </div>
                </div>
            </div>

            <div className="chat-block left">
                <div className="chat-block-single">
                    <div className="chat-bubble">
                        <p>Got it, thank you.</p>
                    </div>
                    <div className="timeStamp">
                        <span>00:10</span>
                    </div>
                </div>
            </div> */}

        </div>
    )
}

export default MessagesSection