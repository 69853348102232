import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaCode } from 'react-icons/fa'
import ConnectionRows from '../ConnectionRows'
import ConnectModal from '../ConnectModal'

const AutoResponder = () => {
    const autoresponder = useSelector(state => state.social.autoresponder)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    return (
        <div className="col-lg-12">
            <div className="integration-box">
                <div className="integration-box-icon"><FaCode size={25} /></div>
                <div className="integration-box-txt">
                    <h6>Autoresponder</h6>
                    <p>Automate tasks and workflows across multiple apps with Autoresponder.</p>
                </div>
                <div className="integration-btn">
                    <button className="theme-btn light" onClick={handleShow}>Connect</button>
                </div>

            </div>
            <div className='row'>
                {autoresponder.length > 0 ?
                    autoresponder.map((curElem, index) => {
                        return (
                            <React.Fragment key={index}>
                                <ConnectionRows
                                    curElem={curElem}
                                />
                            </React.Fragment>
                        )
                    })
                    : ""}
            </div>

            <ConnectModal
                show={show}
                handleClose={handleClose}
                type="Autoresponder"
                url="create-autoresponder"
            />
        </div>
    )
}

export default AutoResponder