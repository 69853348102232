import React, { useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";

import { FiCheckSquare, FiPhone } from "react-icons/fi";
import { FaRegUser } from "react-icons/fa";
import { BsClockHistory } from "react-icons/bs";
import { FaClockRotateLeft } from "react-icons/fa6";
import { RxCounterClockwiseClock } from "react-icons/rx";

import ActiveConversation from "../Agent/UpdateAgent/UnansweredQuestion/ActiveConversation";


const CallAnalysisTab = ({activeConv}) => {
    return (
        <>
            <div className="chat-window-main">
                <ActiveConversation
                    activeConv={activeConv}
                />

                <div className="chat-area call-analysis">
                    <div className="chat-area-in">

                        <div className="call-stats">
                            <h5>Call Analysis</h5>
                            <div className="call-stat-single">
                                <div className="call-stat-left">
                                    <div className="call-stat-icon"><FiCheckSquare /></div>
                                    <div className="call-stat-info">
                                        <p>Call Completion</p>
                                        <h6>Complete</h6>
                                    </div>
                                </div>
                                <div className="call-stat-right">
                                    <p>(The call ended normally with the agent deciding to call back later.)</p>
                                </div>
                            </div>
                            <div className="call-stat-single incomplete">
                                <div className="call-stat-left">
                                    <div className="call-stat-icon"><FiCheckSquare /></div>
                                    <div className="call-stat-info">
                                        <p>Task Completion</p>
                                        <h6>Incomplete</h6>
                                    </div>
                                </div>
                                <div className="call-stat-right">
                                    <p>(The agent did not complete the task of speaking with Cindy or relaying any information.)</p>
                                </div>
                            </div>
                            <div className="call-stat-single incomplete">
                                <div className="call-stat-left">
                                    <div className="call-stat-icon"><FiCheckSquare /></div>
                                    <div className="call-stat-info">
                                        <p>User Sentiment</p>
                                        <h6>Negative</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="call-stat-single justify-content-between hang">
                                <div className="call-stat-left">
                                    <div className="call-stat-icon"><FiCheckSquare /></div>
                                    <div className="call-stat-info">
                                        <p>Disconnection Reason</p>
                                        <h6>user_hangup</h6>
                                    </div>
                                </div>
                                <div className="call-stat-right">
                                    <div className="callCoast">
                                        <h4><span>Call Cost:</span>$0.008</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </>
    )
}
export default CallAnalysisTab;