import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteSocialAccounts } from '../../Redux/Actions/SocialActions'
import CommonAlert from '../Common/CommonAlert'

const ConnectionRows = ({ curElem }) => {
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Disconnect",
        loader: false
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Disconnect",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Disconnecting",
            loader: true
        })
        dispatch(deleteSocialAccounts("delete-twilio", data, setSweet))
    }


    return (
        <div className='col-12 mb-2 px-5 py-2'>
            <div className='d-flex align-items-center justify-content-between'>
                <div>
                    <h6><span className='text-capitalize'>{curElem.type}</span> - {curElem.name}</h6>
                    <p>Added on - {curElem.created}</p>
                </div>
                <button className='theme-btn' onClick={onDelete}>Disconnect</button>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to disconnect this connection?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </div>
    )
}

export default ConnectionRows