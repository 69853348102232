import React, { useState } from 'react';
import Tab from 'react-bootstrap/Tab';

import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryString from "query-string"
import { useEffect } from 'react';
import TrainingNav from './TrainingNav';
import TitleBar from '../Common/TitleBar';
import SidePanel from '../Common/SidePanel';
import DashboardHeader from '../Common/DashboardHeader';
import { ScrollPanel } from 'primereact/scrollpanel';
import { fetchArticles, fetchVideos } from '../../Redux/Actions/HelpActions';
import { FaSpinner } from 'react-icons/fa';
import MainMenu from '../Common/MainMenu';

const SupportArticle = () => {
    let location = useLocation()
    const dispatch = useDispatch()
    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;
    const articleData = useSelector(state => state.help.article);

    const [filterArticle, setFilterArticle] = useState(false)
    const [loader, setLoader] = useState({
        article: false,
        video: false
    })

    useEffect(() => {
        if (articleData.length > 0) {
            setFilterArticle(articleData.filter(article => article.id === id)[0])
        }
    }, [articleData])

    const fetchHelpData = () => {
        setLoader({
            ...loader,
            video: true,
            article: true
        })
        dispatch(fetchArticles(loader, setLoader))
        dispatch(fetchVideos(loader, setLoader))
    }

    useEffect(() => {
        fetchHelpData()
    }, [])

    return (
        <>
            <TitleBar title="Help" />

            <MainMenu>
                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">
                        <div className="page-title">Upgrade</div>
                        <div className="containerN">
                            <Tab.Container id="left-tabs-example" >
                                <div className="upgrade-tab large">
                                    <div className="upgrade-tab-left">
                                        <TrainingNav
                                            article={articleData}
                                            articleId={id}
                                        />
                                    </div>
                                    <div className='upgrade-tab-right'>
                                        <Tab.Content>
                                            {filterArticle ?
                                                filterArticle.items.map((item, index) => {
                                                    return (
                                                        item.id === +art_id ?
                                                            <React.Fragment key={index}>
                                                                <div className="upgrade-tab-in" key={index}>
                                                                    <div className="upgrade-tab-block">
                                                                        <div className="text-dark"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: item.content
                                                                            }}>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                            : ''
                                                    )
                                                })
                                                :
                                                <div className="spinner d-flex justify-content-cneter" style={{ width: '100%', height: "900px" }}>
                                                    <div className="spinner_tag">
                                                        <FaSpinner className='spin' style={{ color: "#000", fontSize: "30px" }} />
                                                    </div>
                                                </div>
                                            }
                                        </Tab.Content>
                                    </div>
                                </div>

                            </Tab.Container>
                        </div>
                    </div>
                </ScrollPanel>
            </MainMenu>

        </>
    )

}

export default SupportArticle;