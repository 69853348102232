import React, { useEffect, useState } from "react";
import SidePanel from "../../Common/SidePanel";
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FiPhone } from "react-icons/fi";
import LaunchCampaignTab from "./LaunchCampaign/LaunchCampaignTab";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onFetchAgent, onUpdateAgentNumber, onUpdateNumber, unmountAgent } from "../../../Redux/Actions/AgentActions";
import AgentDesign from "./AgentDesign/AgentDesign";
import KnowledgeBase from "./KnowledgeBase/KnowledgeBase";
import Workflow from "./WorkflowAutomation/Workflow";
import TitleBar from "../../Common/TitleBar";
import UnansweredQuestionsTab from "./UnansweredQuestion/UnansweredQuestionsTab";
import AgentSettingsTab from "./Settings/AgentSettingsTab";
import { fetchData } from "../../../Redux/Actions/CommonActions";
import { IoMdCheckmark } from "react-icons/io";
import AgentWidget from "./Widget/AgentWidget";
import MainMenu from "../../Common/MainMenu";
import { FaSpinner } from "react-icons/fa";

const UpdateAgent = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { id } = queryString.parse(location.search)
    const agent = useSelector(state => state.agent.data)

    const [numberList, setNumberList] = useState([])
    const [show, setShow] = useState(false)
    const [numLoader, setNumLoader] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(false)

    const [loader, setLoader] = useState({
        fetch: true
    })
    const fetchAgent = () => {
        let data = { agentId: id }
        dispatch(onFetchAgent(data, navigate, loader, setLoader))
    }

    const handleChooseNumber = (num, index) => {
        if (num !== agent.phoneNumber) {
            setSelectedIndex(index)
            setNumLoader(true)
            let data = {
                id: id,
                phoneNumber: num,
                socialAccountId: agent.socialAccountId
            }
            dispatch(onUpdateNumber(data, navigate, setNumLoader))
        }
    }

    const fetchNumbers = () => {
        let data = {
            id: agent.socialAccountId
        }
        dispatch(fetchData("fetch-phone-numbers", data, setNumberList))
    }

    useEffect(() => {
        if (agent.socialAccountId) {
            fetchNumbers()
        }
    }, [agent.socialAccountId])


    useEffect(() => {
        fetchAgent()

        return () => {
            dispatch(unmountAgent())
        }
    }, [])

    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader" />
            </div>
            :
            <>
                <TitleBar title="Customize Agent" />
                <MainMenu>
                    <ScrollPanel className="custombar2 content-area">
                        <div className="content-area-in tab-style">

                            <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">

                                <div className="tab-bar">
                                    <Nav variant="pillsb">
                                        <Nav.Item><Nav.Link eventKey="tab-1">Agent Design</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-2">Knowledgebase</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-3">Workflow Automation</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-4">Unanswered Questions</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-5">Settings</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-6">Campaigns</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="tab-7">Widget</Nav.Link></Nav.Item>
                                    </Nav>
                                    <div className="tab-bar-right">
                                        <ul>
                                            <li style={{ position: "relative" }}>
                                                {+agent.socialAccountId ?
                                                    show ?
                                                        <>
                                                            <div style={{ position: "fixed", height: "100%", width: "100%", background: "transparent", top: 0, left: 0 }}
                                                                onClick={() => setShow(false)}
                                                            />
                                                            <ul className="number_dropdown">
                                                                {numberList.length > 0 ?
                                                                    numberList.map((curElem, index) => {
                                                                        return (
                                                                            !curElem.agent || curElem.agent.id === id ?
                                                                                <li
                                                                                    key={index}
                                                                                    className="cursor-pointer d-flex align-items-center justify-content-between"
                                                                                    onClick={() => handleChooseNumber(curElem.phone, index)}>
                                                                                    <span>
                                                                                        {curElem.phone}
                                                                                    </span>
                                                                                    <span>
                                                                                        {
                                                                                            numLoader && (selectedIndex === index) ?
                                                                                                <FaSpinner size={18} className="spin" /> :
                                                                                                agent.phoneNumber === curElem.phone ?
                                                                                                    <IoMdCheckmark size={18} /> : null}
                                                                                    </span>
                                                                                </li> : null
                                                                        )
                                                                    })
                                                                    : ""
                                                                }
                                                            </ul>
                                                        </>
                                                        :
                                                        <a onClick={() => setShow(true)} style={{ cursor: "pointer" }}><FiPhone /> {agent.phoneNumber}</a>
                                                    :
                                                    <Link to={'/phone-number'}><FiPhone /> Connect Number</Link>
                                                }
                                            </li>
                                            <li>
                                                {/* <span className="profile-menu">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="" id="dropdown-basic"><BiDotsHorizontalRounded /></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Link>Action</Link>
                                                                <Link>Another action</Link>
                                                                <Link>Something else</Link>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </span> */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <Tab.Content>

                                    <Tab.Pane eventKey="tab-1">
                                        <AgentDesign />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tab-2">
                                        <KnowledgeBase />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tab-3">
                                        <Workflow />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tab-4">
                                        <UnansweredQuestionsTab />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tab-5">
                                        <AgentSettingsTab />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tab-6">
                                        <LaunchCampaignTab />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="tab-7">
                                        <AgentWidget />
                                    </Tab.Pane>

                                </Tab.Content>
                            </Tab.Container>

                        </div>
                    </ScrollPanel>

                </MainMenu>
            </>
    )
}

export default UpdateAgent;