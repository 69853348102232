import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import CommonAlert from '../Common/CommonAlert'
import { onDeleteCampaign } from '../../Redux/Actions/CommonActions'
import { useDispatch } from 'react-redux'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import CreateHookModal from './CreateHookModal'

const WebHooksRows = ({ curElem, hooks, setHooks, fetchHookData }) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)


    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false

    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-webhook", data, hooks, setHooks, setSweet))
    }



    return (
        <tr>
            <td>{curElem.webHookAction}</td>
            <td>{curElem.webHookUrl}</td>
            <td>
                {curElem.created}
            </td>
            <td className="text-end">
                <span className="profile-menu">
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic"><BiDotsHorizontalRounded /></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <a onClick={handleShow} className='cursor-pointer'><FaRegEdit />Edit</a>
                            <Link onClick={onDelete}><FaRegTrashAlt />Delete</Link>
                        </Dropdown.Menu>
                    </Dropdown>
                </span>
            </td>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this webhook?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />

            <CreateHookModal
                show={show}
                fetchHookData={fetchHookData}
                handleClose={handleClose}
                curElem={curElem}
            />

        </tr>
    )
}

export default WebHooksRows