import React, { useState } from "react";
import SidePanel from "../Common/SidePanel";
import DashboardHeader from "../Common/DashboardHeader";
import DashboardFooter from "../Common/DashboardFooter";

import { ScrollPanel } from 'primereact/scrollpanel';
import TitleBar from "../Common/TitleBar";
import GoogleCalender from "./Google/GoogleCalendar";
import ElevenlabsConnectModal from "./ConnectModal";
import ElevenLabs from "./ElevenLabs/ElevenLabs";
import AutoResponder from "./AutoResponder/AutoResponder";

const Integrations = () => {

    return (
        <>
            <TitleBar title="Integrations" />
            <div className="dashboard">
                <SidePanel />
                <div className="dashboard-in">
                    <DashboardHeader />

                    <ScrollPanel className="custombar2 content-area">
                        <div className="content-area-in">
                            <div className="account-block">
                                <h6>Connect Your Accounts</h6>
                                <div className="alert-box align-items-center dark">
                                    <div className="alert-icon"><img src={require("../../images/icon/shield-check.svg").default} alt="" /></div>
                                    <div className="alert-txt">
                                        <p>Take your journey to the next level with add-ons and integrations. <a href="">Learn More</a></p>
                                    </div>
                                </div>

                                <div className="Integration-wrap">
                                    <div className="row">
                                        {/* <div className="col-lg-6">
                                            <div className="integration-box">
                                                <div className="integration-box-icon"><img src={require("../../images/icon/zapier.svg").default} alt="" /></div>
                                                <div className="integration-box-txt">
                                                    <h6>Zapier</h6>
                                                    <p>Automate tasks and workflows across multiple apps with Zapier</p>
                                                </div>
                                                <div className="integration-btn">
                                                    <span className="custom-switch">
                                                        <input type="checkbox" />
                                                        <span className="checkMark"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="integration-box">
                                                <div className="integration-box-icon"><img src={require("../../images/icon/n8n.svg").default} alt="" /></div>
                                                <div className="integration-box-txt">
                                                    <h6>n8n</h6>
                                                    <p>The world's most popular workflow automation platform for technical teams</p>
                                                </div>
                                                <div className="integration-btn">
                                                    <span className="custom-switch">
                                                        <input type="checkbox" />
                                                        <span className="checkMark"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="integration-box">
                                                <div className="integration-box-icon"><img src={require("../../images/icon/make.svg").default} alt="" /></div>
                                                <div className="integration-box-txt">
                                                    <h6>Make</h6>
                                                    <p>Powerful visual platform for building and automating workflows</p>
                                                </div>
                                                <div className="integration-btn">
                                                    <span className="custom-switch">
                                                        <input type="checkbox" />
                                                        <span className="checkMark"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div> */}
                                        <ElevenLabs />
                                    </div>
                                </div>
                            </div>


                            <div className="account-block mt-4">
                                <h6>Connect Your Calendar</h6>
                                <div className="alert-box align-items-center dark">
                                    <div className="alert-icon"><img src={require("../../images/icon/shield-check.svg").default} alt="" /></div>
                                    <div className="alert-txt">
                                        <p>Take your journey to the next level with add-ons and integrations. <a href="">Learn More</a></p>
                                    </div>
                                </div>

                                <div className="Integration-wrap">
                                    <div className="row">
                                        <GoogleCalender />
                                        {/* <div className="col-lg-6">
                                            <div className="integration-box">
                                                <div className="integration-box-icon"><img src={require("../../images/icon/outlook.svg").default} alt="" /></div>
                                                <div className="integration-box-txt">
                                                    <h6>Microsoft Outlook</h6>
                                                    <p>The world's most popular workflow automation platform for technical teams</p>
                                                </div>
                                                <div className="integration-btn">
                                                    <span className="custom-switch">
                                                        <input type="checkbox" />
                                                        <span className="checkMark"></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="account-block mt-4">
                                <h6>Connect Your Autoresponder</h6>
                                <div className="alert-box align-items-center dark">
                                    <div className="alert-icon"><img src={require("../../images/icon/shield-check.svg").default} alt="" /></div>
                                    <div className="alert-txt">
                                        <p>Take your journey to the next level with add-ons and integrations. <a href="">Learn More</a></p>
                                    </div>
                                </div>

                                <div className="Integration-wrap">
                                    <div className="row">
                                        <AutoResponder />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </ScrollPanel>

                    <DashboardFooter />
                </div>
            </div>


        </>
    )
}

export default Integrations;