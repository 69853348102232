import React, { useState } from 'react'
import { onCallBackRules } from '../../../Redux/Actions/CampaignActions'
import { Calendar } from 'primereact/calendar'
import { FiCalendar } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { FaSpinner, FaTrash } from 'react-icons/fa'
import CommonAlert from '../../Common/CommonAlert'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'

const RulesRows = ({ curElem, handleChnage, fetchRules, index, action, rules, setRules, auth }) => {
let date = new Date()
   
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        submit: false,
    })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false

    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-rules", data, rules, setRules, setSweet))
    }



    const handleSubmit = (e) => {
        e.preventDefault()
        let url = "call-back-rules"
        if (curElem.id) {
            url = "update-rules"
        }
        setLoader({
            ...loader,
            submit: true
        })
        dispatch(onCallBackRules(url, curElem, fetchRules, loader, setLoader))
    }


    return (
        <>
            <form className="account-block mb-2" onSubmit={handleSubmit}>
                {curElem.id ?
                    <div className='text-end'>
                        <FaTrash size={18} className='cursor-pointer' onClick={onDelete} />
                    </div> : ""}
                <div className="input-wrap">
                    <label className="label" htmlFor="">Allow Callback</label>
                    {curElem.id ?
                        <input
                            type='text'
                            value={curElem.allowCallBack}
                            className='input'
                            readOnly
                        />
                        :
                        <select
                            className='input'
                            name="allowCallBack"
                            onChange={(e) => handleChnage(e, index)}
                            value={curElem.allowCallBack}
                            required
                        >
                            <option value={""}>Select Action</option>
                            {action.length > 0 ?
                                action.map((cur) => {
                                    return (
                                        <option value={cur.name}>{cur.name}</option>
                                    )
                                })
                                : ""}
                        </select>}
                </div>

                <div className="input-wrap">
                    <label className="label" htmlFor="">Allow Followup</label>
                    <input
                        className="input"
                        type="text"
                        name="allowFollowUp"
                        onChange={(e) => handleChnage(e, index)}
                        value={curElem.allowFollowUp}
                        required
                    />
                </div>

                <div className="input-wrap">
                    <label className="label" htmlFor="">Maximum Attempts</label>
                    <input
                        className="input"
                        type="number"
                        min={1}
                        name="maxAttempts"
                        onChange={(e) => handleChnage(e, index)}
                        value={curElem.maxAttempts}
                        required
                    />
                </div>

                <div className="input-wrap">
                    <label className="label" htmlFor="">Preferred Time</label>
                    <div className="dateInp" >
                        <span className="calendar-icon"><FiCalendar /></span>
                        <Calendar
                            className="input"
                            onChange={(e) => handleChnage(e, index)}
                            showTime
                            hourFormat="12"
                            value={curElem.preferredTime}
                            name='preferredTime'
                            minDate={date}
                            required
                        />
                    </div>
                </div>

                <div className="btn-group mt-4">
                    {+auth.user.isClientAccount === 1 ? "" :
                        <button className="theme-btn" type='submit'><span>
                            {curElem.id ?
                                loader.submit ? <>Updating <FaSpinner className="spin" /></> : "Update" :
                                loader.submit ? <>Saving <FaSpinner className="spin" /></> : "Save"

                            }
                        </span></button>
                    }
                </div>
            </form>
            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this callback action?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </>
    )
}

export default RulesRows