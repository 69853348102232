import React, { useEffect, useState } from "react";
import { IoSearchOutline, } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import OverviewRows from "./OverviewRows";
import { FaSpinner } from "react-icons/fa";
import { onFetchOverView } from "../../../Redux/Actions/CampaignActions";
import { useImmer } from "use-immer";

const CampaignOverview = ({ id }) => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.campaign)
    const agent = useSelector(state => state.agent.data)
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [overViewData, setOverViewData] = useState([])
    const [overViewDataShow, setOverViewDataShow] = useState([])

    const [filterObj, setFilterObj] = useImmer({
        searchKey: "",
    })

    const [sortHeader, setSortHeader] = useState({
        created: ""
    })



    const handleSort = () => {
        const data = [...overViewData]
        if (sortHeader.created) {
            data.sort((a, b) => {
                if (a.created.toLowerCase() > b.created.toLowerCase()) {
                    return 1
                } else {
                    return -1
                }
            })
            setSortHeader({
                ...sortHeader,
                created: false,
            });
        } else {
            data.sort((a, b) => {
                if (a.created.toLowerCase() < b.created.toLowerCase()) {
                    return 1
                } else {
                    return -1
                }
            })
            setSortHeader({
                ...sortHeader,
                created: true,
            });
        }
        setOverViewDataShow(data)
    }

    const fetchOverView = () => {
        let data = { campaignId: id }
        dispatch(onFetchOverView(data, setOverViewData, loader, setLoader))
    }


    useEffect(() => {
        if (filterObj.searchKey) {
            setOverViewDataShow(overViewData.filter((item) => {
                return item.name.toLowerCase().includes(filterObj.searchKey.toLowerCase())
            }))
        } else {
            setOverViewDataShow(overViewData)
        }
    }, [filterObj, overViewData])

    useEffect(() => {
        if (id) {
            fetchOverView()
        }
    }, [id])

    return (
        <>

            <div className="account-block">
                <div className="d-flex align-items-center">
                    <h6>CRM Sales Campaign </h6>
                </div>
                <div className="campaign-info">
                    <ul>
                        <li>
                            <div className="campaign-info-single">
                                <p>Agent Name</p>
                                <h6>{agent.name}</h6>
                            </div>
                        </li>
                        <li>
                            <div className="campaign-info-single">
                                <p>Total Dials</p>
                                <h6>{campaign.overviewData.dials}</h6>
                            </div>
                        </li>
                        <li>
                            <div className="campaign-info-single">
                                <p>Pick ups</p>
                                <h6>{campaign.overviewData.pickups}</h6>
                            </div>
                        </li>
                        <li>
                            <div className="campaign-info-single">
                                <p>Hangup</p>
                                <h6>{campaign.overviewData.hungUp}</h6>
                            </div>
                        </li>
                        <li>
                            <div className="campaign-info-single">
                                <p>No Response</p>
                                <h6>{campaign.overviewData.noResponse}</h6>
                            </div>
                        </li>
                        <li>
                            <div className="campaign-info-single">
                                <p>Total Talk Time</p>
                                <h6>{campaign.overviewData.talkTime}</h6>
                            </div>
                        </li>
                        <li>
                            <div className="campaign-info-single">
                                <p>Campaign Start</p>
                                <h6>{campaign.data.startDate}</h6>
                            </div>
                        </li>
                        <li>
                            <div className="campaign-info-single">
                                <p>Campaign End</p>
                                <h6>{campaign.data.endDate}</h6>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="table-bar mt-4">
                <div className="table-bar-left">
                    <div className="table-bar-icon">
                        <ul>
                            {/* <li><img src={require("../../../images/icon/plus.svg").default} alt="" /></li>
                            <li><img src={require("../../../images/icon/filter.svg").default} alt="" /></li> */}
                            <li onClick={handleSort}><img src={require("../../../images/icon/sort.svg").default} alt="" /></li>
                        </ul>
                    </div>
                </div>
                <div className="table-bar-right">
                    <div className="header-search">
                        <input
                            type="text"
                            placeholder="Search"
                            onChange={(e) => setFilterObj({
                                ...filterObj,
                                searchKey: e.target.value
                            })}
                        />
                        <span className="left-icon"><IoSearchOutline /></span>
                    </div>
                </div>
            </div>

            <div className="table-responsive mt-3">
                <table className="table theme-table">
                    <thead>
                        <tr>
                            <th>
                                <span className="custom-check">
                                    <input type="checkbox" />
                                    <span className="checkMark"></span>
                                </span>
                            </th>
                            <th>Call ID</th>
                            <th>Customer Name</th>
                            <th>Contact Number</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Duration</th>
                            <th>Recording</th>
                            <th>Transcript</th>
                        </tr>
                    </thead>
                    <tbody>
                        {overViewDataShow.length > 0 ?
                            overViewDataShow.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <OverviewRows curElem={curElem} />
                                    </React.Fragment>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={9} className="text-center">
                                    {loader.fetch ?
                                        <FaSpinner className="spin" size={25} />
                                        :
                                        "No data available."
                                    }
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            {/* <div className="table-paging">
                <ul>
                    <li><FiChevronLeft /></li>
                    <li className="active">1</li>
                    <li>2</li>
                    <li>3</li>
                    <li>4</li>
                    <li>5</li>
                    <li><FiChevronRight /></li>
                </ul>
            </div> */}

        </>
    )
}
export default CampaignOverview;