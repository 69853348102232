import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { onCheckStatus } from '../../../../Redux/Actions/AgentActions'
import { Dropdown } from 'react-bootstrap'
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import UpdateScript from './UpdateScript'
import CommonAlert from '../../../Common/CommonAlert'
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions'
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
const DataRows = ({ curElem, index, fetchData, data, setData }) => {
    const dispatch = useDispatch()
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false

    })
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const checkStatus = (interval) => {
        let data = {
            id: curElem.id
        }
        dispatch(onCheckStatus(data, fetchData, interval))
    }

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let obj = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-knowledge-data", obj, data, setData, setSweet))
    }

    const handleView = () => {
        window.open(curElem.url, "_blank")
    }

    useEffect(() => {
        if (+curElem.status === 1) {
            const interval = setInterval(() => {
                checkStatus(interval)
            }, 5000)

            return () => {
                clearInterval(interval)
            }
        }
    }, [curElem])


    return (
        <tr>
            <td>{index + 1}</td>
            <td>{curElem.type}</td>
            <td>{curElem.processType}</td>
            <td>{curElem.sourceType}</td>

            <td>
                {curElem.url ?
                    <span style={{ cursor: "pointer" }} onClick={handleView}><FaArrowUpRightFromSquare size={16} /></span> : null}
            </td>
            <td>
                <span className={`${+curElem.status === 1 ? "progress" : +curElem.status === 2 ? "complete" : "paused"} tStatus`}>
                    {+curElem.status === 1 ? "In Progress" : +curElem.status === 2 ? "Completed" : "Failed"}
                </span>
            </td>
            <td>{curElem.created}</td>
            <td className="text-end">
                <span className="profile-menu">
                    <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic"><BiDotsHorizontalRounded /></Dropdown.Toggle>
                        <Dropdown.Menu>
                            {+curElem.status === 2 ?
                                <a className='cursor-pointer' onClick={handleShow}><FaRegEdit />Edit</a> : ""}
                            <a className='cursor-pointer' onClick={onDelete}><FaRegTrashAlt />Delete</a>
                        </Dropdown.Menu>
                    </Dropdown>
                </span>
            </td>

            <UpdateScript
                show={show}
                handleClose={handleClose}
                curElem={curElem}
                fetchData={fetchData}
            />

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this data?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
            />
        </tr >
    )
}

export default DataRows