import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { onSaveScript } from '../../../../Redux/Actions/AgentActions'

const UpdateScript = ({ show, handleClose, curElem, fetchData, status }) => {
    const dispatch = useDispatch()
    const [text, setText] = useState("")
    const [loader, setLoader] = useState({
        save: false
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({ ...loader, save: true })
        let obj = {
            text: text,
            id: curElem.id
        }
        dispatch(onSaveScript(obj, fetchData, handleClose, loader, setLoader))
    }

    useEffect(() => {
        if (show) {
            setText(curElem.data.text)
        }
    }, [show])
    return (
        <Modal className="theme-modal" show={show} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={handleClose}><AiOutlineClose /></span>
                <div className="icon-title">
                    <h6>Update Script</h6>
                </div>
                <form className="modal-content" onSubmit={handleSubmit}>
                    <div className='input-wrap'>
                        <textarea
                            value={text}
                            className='input'
                            style={{ height: 500, border: "1px solid #000" }}
                            onChange={(e) => setText(e.target.value)}
                            required
                        />
                    </div>
                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type='button' onClick={handleClose}><span>Cancel</span></button>
                        <button className="theme-btn" type='submit'><span>{loader.save ? <>Saving <FaSpinner className='spin' /></> : "Save Changes"}</span></button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateScript