import React, { useEffect, useState } from 'react'
import { FaMicrophone, FaRegCircleStop } from 'react-icons/fa6'
import { MdOutlinePlayCircle, MdOutlineStopCircle, MdOutlineFileUpload } from "react-icons/md";
import useRecorder from '../../../Common/MyRecording';
import { themeColor } from '../../../../Global/Global';
import { FaSpinner } from 'react-icons/fa';

let newIntervalId, music = false
const Recording = ({ onInputFile, loaderRecord }) => {
    let [audioURL, status, recorder, startRecording, stopRecording, pauseRecording, resumeRecording, setAudioURL] = useRecorder();

    const [play, setPlay] = useState(false)
    const [second, setSecond] = useState(0)
    const [t, setT] = useState(false)
    const [audioDuration, setAudioDuration] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
        fetch: false,
        playRecording: false
    })

    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    useEffect(() => {
        if (recorder !== null) {
            setT(true)
        }
    }, [recorder])

    const handleClick = (type) => {
        if (type === "start") {
            startRecording()
        }
        if (type === "pause") {
            pauseRecording()
            setT(false)
            clearInterval(newIntervalId);
            return;
        }
        if (type === "resume") {
            resumeRecording()
            setT(true)

        }
        if (type === "stop") {
            stopRecording()
            setT(false)
            clearInterval(newIntervalId);
            let totalTime = timer.h * 3600 + timer.m * 60 + second
            setAudioDuration(totalTime)
            setSecond(0)
            setTimer({
                ...timer,
                h: 0,
                m: 0
            })
            return;
        }

    };
    useEffect(() => {
        if (t) {
            newIntervalId = setInterval(() => {
                setSecond(prevSec => prevSec + 1)
            }, 1000);
        }
    }, [t])

    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])

    // let clearPause
    const handlePlay = () => {
        if (audioURL) {
            setLoader({
                ...loader,
                playRecording: true
            })
            const file = URL.createObjectURL(audioURL[0].blob)
            music = new Audio(file)
            music.play()
                .then(r => {
                    setLoader({
                        ...loader,
                        playRecording: false
                    })
                    setPlay(true)
                }
                )
                .catch(err => console.log(err));

            music.onended = () => {
                setPlay(false)
            }
        }
    }

    useEffect(() => {
        if (play) {
            setTimeout(() => {
                setPlay(false)
            }, (audioDuration + 1) * 1000)
        }
    }, [play])

    const handlePause = () => {
        music.pause()
        setPlay(false)
    }

    const handleUpload = () => {
        const fileName = new File([audioURL[0].blob], "Recording", { type: "audio/mpeg" });
        let obj = {
            target: {
                files: [fileName]
            }
        }
        onInputFile(obj, "recording")
    }


    return (
        <>
            <div className="uploader left d-flex align-items-center justify-content-center">
                {
                    status.start ?
                        <div className='d-flex align-items-center' style={{ cursor: "pointer" }} >
                            <div className='d-flex align-items-center' onClick={() => handleClick("stop")}>
                                <FaRegCircleStop style={{ fontSize: "25px" }} />
                                <span className='ms-3' style={{ fontWeight: "600" }}>Stop Recording</span>
                            </div>
                            <div style={{ display: "flex" }}>
                                <div className='mx-2'>|</div>
                                <div style={{ fontWeight: "bold" }}>
                                    {
                                        timer.h < 10 ? '0' + timer.h : timer.h
                                    }
                                    :
                                    {
                                        timer.m < 10 ? '0' + timer.m : timer.m
                                    }
                                    :
                                    {
                                        second < 10 ? '0' + second : second
                                    }
                                </div>
                            </div>
                        </div>

                        :
                        <div className='upload_content_wrap' style={{ cursor: "pointer" }} onClick={() => handleClick("start")}>
                            <div className='d-flex align-items-center'>
                                <FaMicrophone style={{ color: '#000', fontSize: "25px" }} />
                                <span className='ms-3' style={{ fontWeight: "600" }}>Start Recording</span>
                            </div>
                        </div>
                }
            </div>
            <div className='d-block my-4 px-2' style={{ fontSize: 16 }}>
                {audioURL.length > 0 ?
                    audioURL.map((curElem, index) => {
                        return (
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <span>{index + 1}</span>
                                    <span className='ms-3'>{curElem.name}</span>
                                </div>
                                <span className='d-flex align-items-center'>
                                    {play ?
                                        <MdOutlineStopCircle style={{ color: themeColor, fontSize: "23px", cursor: "pointer" }} title='Stop' onClick={handlePause} /> :
                                        <MdOutlinePlayCircle style={{ color: themeColor, fontSize: "23px", cursor: "pointer" }} title='Play' onClick={handlePlay} />
                                    }
                                </span>
                            </div>
                        )
                    })
                    : ""}
            </div>
            <div className='text-end'>
                <button
                    className='theme-btn light mt-0'
                    onClick={handleUpload}
                    style={audioURL.length > 0 ? {} : { cursor: "not-allowed" }}
                    disabled={audioURL.length > 0 ? false : true}
                >
                    <span>{loaderRecord.record ? <>Creating <FaSpinner className='spin' /></> : "Create Voice"}</span>
                </button>
            </div>
        </>
    )
}

export default Recording