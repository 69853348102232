import React from "react";

import { ScrollPanel } from 'primereact/scrollpanel';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import TitleBar from "../Common/TitleBar";
import MainMenu from "../Common/MainMenu";
import ApiKeys from "../Api-Keys/ApiKeys";
import Webhook from "../Web-Hook/Webhook";
import Minutes from "../Minutes/Minutes";

const Settings = () => {
    return (
        <>
            <TitleBar title="Settings" />

            <MainMenu>
                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">
                        <div className="page-title">Settings</div>
                        <div className="containerN">

                            <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">

                                <div className="upgrade-tab">
                                    <div className="upgrade-tab-left">
                                        <Nav variant="pillsn" className="">
                                            <Nav.Item><Nav.Link eventKey="tab-1">API Keys</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="tab-2">Webhook</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="tab-3">Minutes</Nav.Link></Nav.Item>

                                        </Nav>
                                    </div>
                                    <div className="upgrade-tab-right">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="tab-1">
                                                <ApiKeys />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab-2">
                                                <Webhook />
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="tab-3">
                                                <Minutes />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </ScrollPanel>
            </MainMenu>

        </>
    )
}

export default Settings;