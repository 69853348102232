import React, { useEffect, useState } from "react";

import Modal from 'react-bootstrap/Modal';
import { ScrollPanel } from 'primereact/scrollpanel';

import { AiOutlineClose, AiFillCheckCircle } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import TitleBar from "../Common/TitleBar";
import { appName } from "../../Global/Global";
import { useSelector } from "react-redux";
import MainMenu from "../Common/MainMenu";

import { FiArrowRightCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import CreateAgentModal from "../Agent/CreateAgent/CreateAgentModal";
import FbGroupModal from "./FbGroupModal";

const Dashboard = () => {
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand.data)

    const [show, setShow] = useState(false);

    const [showPopup, setShowPopup] = useState(false);
    const [showClose, setShowClose] = useState(false)


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);


    const handleMsgClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }
    const handelPopupClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    const handelClose2 = () => {
        setShowPopup(false);
    }


    useEffect(() => {
        const popupCookie = document.cookie
        if (popupCookie.includes(`popupClicked${auth.user.id}=true`)) {
            setShowPopup(false)
        } else {
            setShowPopup(true)
        }
    }, []);



    return (
        <>
            <TitleBar title="Dashboard" />
            <FbGroupModal
                showPopup={showPopup}
                showClose={showClose}
                setShowClose={setShowClose}
                handelClose2={handelClose2}
                handelPopupClick={handelPopupClick}
                handleMsgClick={handleMsgClick}
            />
            <MainMenu >
                <ScrollPanel className="custombar2 content-area">
                    <div className="content-area-in">
                        <div className="hero-video">
                            <div className="video-wrap-outer">
                                <div className="video-wrap">
                                    <iframe src="https://player.vimeo.com/video/992044970?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" style={{ height: "100%", width: "100%" }} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="AICallings BG Video"></iframe>
                                </div>
                                <div className="welcome-txt">
                                    <div className="container">
                                        <div className="row align-items-center">
                                            <div className="col-sm-6">
                                                <div>
                                                    {/* <h2>WELCOME TO <br /> <span>{rebrand ? rebrand.name : appName}</span></h2> */}
                                                    <h2>WELCOME TO</h2>
                                                    <div className="dash-logo"><img src={require("../../images/logo-white.png")} alt="" /></div>
                                                    <p className="pt-3">Step into the future of business communication with advanced AI callers. Create and customize your AI callers to handle every call with precision and personality. Upload your business materials, craft unique scripts, and watch as your AI callers boost your sales and streamline operations effortlessly. Start your journey to smarter communication now!</p>
                                                    <a className="theme-btn purple large mt-4" onClick={handleShow}><span>Watch Demo Video</span></a>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="linkSec">
                                                    {+auth.user.isClientAccount === 1 ? "" :
                                                        <a className="linkSec-single cursor-pointer" onClick={handleShow2}>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span className="linkSec-icon"><img src={require("../../images/icon/agents-icon-2.svg").default} alt="" /></span>
                                                                <span>Create Agents</span>
                                                            </div>
                                                            <span><FiArrowRightCircle /></span>
                                                        </a>}
                                                    <Link to="/agent" className="linkSec-single" href="">
                                                        <div className="d-flex align-items-center gap-2">
                                                            <span className="linkSec-icon"><IoEyeOutline /></span>
                                                            <span>View Agents</span>
                                                        </div>
                                                        <span><FiArrowRightCircle /></span>
                                                    </Link>
                                                    {+auth.user.isClientAccount === 1 ? "" :
                                                        <Link to="/integrations" className="linkSec-single" href="">
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span className="linkSec-icon"><img src={require("../../images/icon/webhooks-icon.svg").default} alt="" /></span>
                                                                <span>Integrations</span>
                                                            </div>
                                                            <span><FiArrowRightCircle /></span>
                                                        </Link>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="pick-opt">
                            <div className="container">
                                <h2 className="text-center">Create Agent - Pick An Option</h2>
                                <div className="row pt-4">
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="add-info-block cursor-pointer">
                                            <img src={require("../../images/icon/book.svg").default} alt="" />
                                            <h6 className="pt-2">Connect Webpage</h6>
                                            <p>We will automatically extract  the information from one or more urls.</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="add-info-block cursor-pointer">
                                            <img src={require("../../images/icon/bulb.svg").default} alt="" />
                                            <h6 className="pt-2">Upload File</h6>
                                            <p>We will automatically extract info from a PDF, DOC,TXT or Video file.</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4">
                                        <div className="add-info-block cursor-pointer">
                                            <img src={require("../../images/icon/edit.svg").default} alt="" />
                                            <h6 className="pt-2">Type Manually</h6>
                                            <p>You can manually type in the information.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="text-block">

                            <div className="text-block-single">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <div className="text-block-left">
                                                <h2>AI-Driven Call Management:</h2>
                                                <p>{appName} revolutionizes your call management by using sophisticated AI technology to handle both outbound and inbound calls. Whether it's scheduling appointments, providing product information, or pitching services, {appName} ensures every interaction is seamless and efficient, saving you time and effort.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="text-block-right">
                                                <div className="text-block-img"><img src={require("../../images/graphic-1.png")} alt="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-block-single">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <div className="text-block-left">
                                                <h2>Customizable AI Callers:</h2>
                                                <p>Create AI callers that reflect your brand's voice and personality. With {appName}, you can customize your callers accents, tones, and speaking styles, and define their personality traits and knowledge base. This ensures that every customer interaction is consistent, personalized, and aligned with your brand identity.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="text-block-right">
                                                <div className="text-block-img"><img src={require("../../images/graphic-2.png")} alt="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-block-single">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <div className="text-block-left">
                                                <h2>Workflow Automation:</h2>
                                                <p>Streamline your operations with {appName} powerful workflow automation. Automate repetitive tasks, such as call routing, appointment bookings, and follow-ups, to boost productivity and reduce manual effort. This allows your team to focus on more strategic activities while your AI callers handle routine interactions efficiently.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="text-block-right">
                                                <div className="text-block-img"><img src={require("../../images/graphic-3.png")} alt="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="text-block-single">
                                <div className="container">
                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <div className="text-block-left">
                                                <h2>Detailed Analytics and Insights:</h2>
                                                <p>Gain valuable insights into your call performance with {appName} detailed analytics. Track metrics like call completion rates, user sentiment, and conversion rates to understand customer needs better and refine your strategies. Use this data to continuously improve your AI caller’s performance and enhance overall customer satisfaction.</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="text-block-right">
                                                <div className="text-block-img"><img src={require("../../images/graphic-4.png")} alt="" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="lessons">
                            <div className="container">
                                <div className="lessons-in">
                                    <div className="row align-items-center">
                                        <div className="col-sm-6">
                                            <div className="lessons-img"><img src={require("../../images/abhi.png")} alt="" /></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="lessons-txt">
                                                <h5>{rebrand ? rebrand.name : appName} AI LESSON #1</h5>
                                                <h6>Join Us for live webinar</h6>
                                                <p>Join us for a private training that will walk you through how to use {rebrand ? rebrand.name : appName} to help you create and sell videos for maximum engagement, views and profits. This training or upgrade will not be made available afterwards. Sign up and make sure to show up on this live training with all your questions.</p>
                                                <a className="theme-btn mt-4" target="_blank" href="https://www.abhineos.com/training"><span>Join Now</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </ScrollPanel>
            </MainMenu>

            <CreateAgentModal
                show={show2}
                handleClose={handleClose2}
            />

            <Modal className="theme-modal video-modal" show={show} onHide={handleClose} backdrop="static" centered>
                <Modal.Body>
                    <span className="modal-close" onClick={handleClose}><AiOutlineClose /></span>
                    <div className="modal-content">
                        <div className="video-wrap">
                            {/* <iframe src="https://player.vimeo.com/video/999046686?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="app intro video"></iframe> */}
                            <iframe src="https://player.vimeo.com/video/999046686" allow="autoplay; fullscreen; picture-in-picture" frameborder="0" style={{ width: "100%" }} height="360" title="app intro video"></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default Dashboard;