import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { onCreateWebHook, onGenrateBearer } from '../../Redux/Actions/AgentActions'
import { FaSpinner } from 'react-icons/fa'
import { fetchData } from '../../Redux/Actions/CommonActions'

const CreateHookModal = ({ show, handleClose, fetchHookData, curElem }) => {
    const dispatch = useDispatch()
    const [action, setAction] = useState([])
    const [loader, setLoader] = useState({
        save: false,
        generate: false
    })
    const [data, setData] = useState({
        webHookAction: "",
        webHookUrl: "",
        bearerToken: ""
    })

    const handleGenerate = () => {
        let obj = {}
        setLoader({
            ...loader,
            generate: true
        })
        dispatch(onGenrateBearer(obj, data, setData, loader, setLoader))
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = { ...data }
        setLoader({
            ...loader,
            save: true
        })
        let url = "create-webhook"
        if (obj.id) {
            url = "update-webhook"
        }
        dispatch(onCreateWebHook(url, obj, fetchHookData, handleClose, setData, loader, setLoader))
    }
    const fetchActionsData = () => {
        dispatch(fetchData("fetch-webhook-action", {}, setAction))
    }

    useEffect(() => {
        fetchActionsData()
    }, [])


    useEffect(() => {
        if (curElem) {
            setData({
                ...data,
                ...curElem
            })
        }
    }, [curElem])


    return (
        <Modal className="theme-modal" show={show} onHide={handleClose} backdrop="static" centered>
            <Modal.Body>
                <span className="modal-close" onClick={handleClose}><AiOutlineClose /></span>
                <form className="modal-content" onSubmit={handleSubmit}>
                    <h3 className="text-center">{data.id ? "Updating" : "Creating"} you webhook is just one step away </h3>
                    <p className="text-center pt-2">You can use webhooks to seamlessly connect with your application</p>
                    <div className="modal-card mt-4">
                        <h6>Webhook Details</h6>
                        <div className="input-wrap">
                            <label className="label" htmlFor="">Webhook Actions</label>
                            <select
                                className='input'
                                onChange={handleChange}
                                name='webHookAction'
                                value={data.webHookAction}
                                required
                            >
                                <option value={""}>Select Action</option>
                                {action.length > 0 ?
                                    action.map((curElem) => {
                                        return (
                                            <option value={curElem.name}>{curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>
                        </div>
                        <div className="input-wrap">
                            <label className="label" htmlFor="">Webhook Url</label>
                            <input
                                className="input"
                                type="url"
                                name="webHookUrl"
                                placeholder='https://chatgpt.com/c/9fb7a342-597f-434b-aff4-ef8444017ca9'
                                value={data.webHookUrl}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="input-wrap">
                            <div className='d-flex align-items-center justify-content-between mb-3'>
                                <label className="label p-0" htmlFor="">Bearer Token</label>
                                <button type='button' className="theme-btn" onClick={handleGenerate}>{loader.generate ? <>Generating <FaSpinner className='spin' size={14} /></> : "Generate"}</button>
                            </div>
                            <input
                                className="input"
                                type="text"
                                name="bearerToken"
                                placeholder="adADHSL213231134@_-_13jqwee1edVSVSDFSFD13asdasdasda"
                                value={data.bearerToken}
                                required
                            />
                        </div>
                    </div>
                    <div className="btn-group mt-4">
                        <button className="theme-btn light" type='button' onClick={handleClose}><span>Cancel</span></button>
                        {data.id ?
                            <button className="theme-btn" type='submit'><span>{loader.save ? <>Updating <FaSpinner className='spin' /></> : "Update"}</span></button> :
                            <button className="theme-btn" type='submit'><span>{loader.save ? <>Saving <FaSpinner className='spin' /></> : "Save Changes"}</span></button>}
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateHookModal