import React, { useState } from 'react'
import { FaCross, FaSpinner } from 'react-icons/fa'
import Select from 'react-select'
import { MdOutlineCancel } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onUnlinkNumber, onUpdateNumber } from '../../Redux/Actions/AgentActions';
import { setAlert } from '../../Redux/Actions/AlertActions';

const NumberListRows = ({ curElem, agents, scId, fetchPhoneList }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectShow, setSelectShow] = useState(false)
    const [selectedAgent, setSelectedAgent] = useState(false)

    const [loader, setLoader] = useState(false)
    const [loaderUnlink, setLoaderUnlink] = useState(false)

    const handleHide = () => {
        setSelectedAgent(false)
        setSelectShow(false)
    }

    const handleAdd = () => {
        if (selectedAgent) {
            setLoader(true)
            let data = {
                id: selectedAgent.id,
                phoneNumber: curElem.phone,
                socialAccountId: scId
            }
            dispatch(onUpdateNumber(data, navigate, setLoader))
        } else {
            dispatch(setAlert("Please Assign an agent for this number to link.", "danger"))
        }
    }

    const handleRemove = () => {
        let data = {
            id: curElem.agent.id
        }
        setLoaderUnlink(true)
        dispatch(onUnlinkNumber(data, fetchPhoneList, setLoaderUnlink))
    }

    return (
        <tr>
            <td>{curElem.name}</td>
            <td>{curElem.phone}</td>
            <td>Active</td>
            <td>

                {curElem.agent ?
                    <>
                        <span className="userImg">
                            <img src={curElem.agent.image} alt="" />
                        </span>
                        {curElem.agent.name}
                        <span className='theme-btn ms-2' onClick={handleRemove}>{loaderUnlink ? <>Unlinking<FaSpinner size={14} className='spin' /></> : "Unlink"}</span>

                    </>
                    :
                    selectShow ?
                        <span className='d-flex  align-items-center' style={{ width: "100%" }}>
                            <Select
                                options={agents}
                                onChange={(e) => setSelectedAgent(e)}
                                value={selectedAgent}
                                style={{width: 150}}
                            />
                            <span className='theme-btn ms-2' onClick={handleAdd}>{loader ? <>Linking<FaSpinner size={14} className='spin' /></> : "Link"}</span>
                            <span className='theme-btn ms-2' onClick={handleHide}>
                                <MdOutlineCancel size={20} className='ms-1 cursor-pointer' />
                            </span>
                        </span>
                        :
                        <span className={`theme-btn `} onClick={() => setSelectShow(true)} >
                            Link Agent
                        </span>
                }

            </td>
        </tr>
    )
}

export default NumberListRows