import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { TbLayoutSidebarRight } from "react-icons/tb";
import { PiUserCircleDuotone } from "react-icons/pi";
import { Link, useLocation } from "react-router-dom";
import { FiUser, FiLock, FiUserPlus, FiStar, FiHelpCircle, FiLogOut, FiSettings } from "react-icons/fi";
import { logoutUser } from "../../Redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { onToggleMenu } from "../../Redux/Actions/ExtraActions";
import logoD from "../../images/logo-dark.svg"

const DashboardHeader = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    const auth = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const extra = useSelector(state => state.extra.data)

    const [memberShip, setMemberShip] = useState([])



    const handleOut = () => {
        dispatch(logoutUser())
    }

    const handleSideBar = () => {
        dispatch(onToggleMenu(!extra.toggleMenu))
    }

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    return (
        <>
            <div className="dashboard-header">
                <div className="dashboardLogo"><Link to={'/dashboard'}><img src={rebrand ? rebrand.logo : logoD} alt="" /></Link></div>
                <div className="dashboard-header-left">
                    <TbLayoutSidebarRight size={20} style={{ cursor: "pointer" }} onClick={handleSideBar} />
                </div>
                <div className="dashboard-header-right">
                    <div className="dashboard-icons">
                        <ul>
                            <li><img src={require("../../images/icon/notification.svg").default} alt="" /></li>
                            <li><Link to={'/help'}><FiHelpCircle size={18} color="#000" title="Help" /></Link></li>
                            <div className="profile-menu">
                                <Dropdown>
                                    <Dropdown.Toggle variant="successN" className="drop-btn">
                                        <PiUserCircleDuotone />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Link to="/my-account"><FiUser /> My Account</Link>

                                        {+auth.user.isClientAccount === 1 || +auth.user.isVirtualAccount === 1? "" :
                                            <Link to="/privacy"><FiLock /> Privacy</Link>
                                        }

                                        {memberShip.includes("enterprise") || memberShip.includes("bundle") || memberShip.includes("fastpass") ?
                                            <>
                                                {+auth.user.isClientAccount === 1 ? "" :
                                                    <>
                                                        {+auth.user.isVirtualAccount === 1 ? "" :
                                                            <Link to="/account-management"><FiUserPlus /> Account Management</Link>
                                                        }

                                                        <Link to="/upgrade"><FiStar /> Upgrade</Link>
                                                    </>}

                                            </> : null}

                                        {+auth.user.isClientAccount === 1 || +auth.user.isVirtualAccount === 1 ? "" :
                                            <Link to={"/settings"}><FiSettings /> Settings</Link>
                                        }

                                        <Link to="/help"><FiHelpCircle /> Help</Link>
                                        <a className="cursor-pointer" onClick={handleOut}><FiLogOut /> Logout</a>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardHeader;